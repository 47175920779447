import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { authHeader } from '../../_helpers';
import { API_STAGING } from '../../_settings'
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import swal from 'sweetalert';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';

function ImportResi() {
    const [appState, setAppState]  = useState({
        expedition:'Pilihan Kurir',
        data:null,
        disabled: false,
    });
    const handleClass = expedition => appState.expedition === expedition ? "tool-select-item active" : "tool-select-item";
    const fileHandler = (event) => {
        let fileObj = event.target.files[0];
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
            if(err){
            console.log(err);            
            }
            else{
                setAppState({data: resp.rows.slice(1),expedition:appState.expedition})
                let newRows = [];
               
            }
        }); 
    }
    const SendImporResi = ()=>{
        setAppState({disabled: true, expedition:appState.expedition});
        appState.data.map((row, index) => {
            if (row && row !== "undefined") {
                sendApi({
                    key: index,
                    id: row[0],
                    courier: row[1],
                    service: row[2],
                    resi: row[3]
                })
                console.log(row)
            }
        });
       
    }
    const sendApi= (data)=> {
        if(!data.courier){
            axios.post(`${API_STAGING}import-resi/?id=${data.id}&resi=${data.resi}&courier=${appState.expedition}&service=${data.service}`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.message;
                if(repos.data.status == true) {
                    swal({
                        title: "Done!",
                        text: data,
                        icon: "success",
                        timer: 3000,
                        button: false
                    })
                    setTimeout(function () {
                        window.location.reload();
                    }, 3000);
                } else {
                    swal({
                        title: "Error!",
                        text: data,
                        icon: "error",
                        timer: 3000,
                        button: false
                    })
                    setTimeout(function () {
                        window.location.reload();
                    }, 3000);
                }
                setAppState({disabled: false, expedition:appState.expedition})
            });
        } else {
            axios.post(`${API_STAGING}import-resi/?id=${data.id}&resi=${data.resi}&courier=${data.courier}&service=${data.service}`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.message;
                if(repos.data.status == true) {
                    swal({
                        title: "Done!",
                        text: data,
                        icon: "success",
                        timer: 3000,
                        button: false
                    })
                    setTimeout(function () {
                        window.location.reload();
                    }, 3000);
                } else {
                    swal({
                        title: "Error!",
                        text: data,
                        icon: "error",
                        timer: 3000,
                        button: false
                    })
                    setTimeout(function () {
                        window.location.reload();
                    }, 3000);
                }
                setAppState({disabled: false, expedition:appState.expedition})
            });
        }
    }
    return ( 
        <section id="dashboard-wrapper">
            <div className="container-wrapper">
            <Sidebar />
            <section id="main-dashboard">
                <Header />
                <div className="content-wrapper">
                    <div className="main-dash">
                    {(() => {
                        if (appState.data) {
                            return <div className="result">Total <strong>{appState.data.length} Data</strong> Pesanan</div>;
                        } else {
                            return <div className="result">Total <strong>0 Data</strong> Pesanan</div>;
                        }
                    })()}
                        <div className="table-data">
                        <div className="table-head">
                            <div className="th-resi-01">No</div>
                            <div className="th-resi-02">No. Pesanan</div>
                            <div className="th-resi-03">Expedisi</div>
                            <div className="th-resi-03">Service</div>
                            <div className="th-resi-04">Nomor Resi</div>
                        </div>
                        {(() => {
                        if (appState.data) {
                            return <div>
                                    {appState.data.map((repo,index) => {
                                        return (
                                        <div className="table-body">
                                            <div className="table-body-btm">
                                                <div className="th-resi-01">{index + 1}</div>
                                                <div className="th-resi-02">{repo[0]}</div>
                                                <div className="th-resi-03">{repo[1]}</div>
                                                <div className="th-resi-03">{repo[2]}</div>
                                                <div className="th-resi-04">{repo[3]}</div>
                                            </div>{/* end of table-body-btm */}
                                        </div>
                                        );
                                    })}
                                    </div>;
                        } else {
                            return <div className="data-empty">
                                        <img src="/img/avo-empty-resi.svg" alt="Empty Resi" />
                                        <div className="label-desc green">Belum ada data resi yang diimpor</div>
                                    </div>
                        }
                        })()}
                        <div className="box-drag-wrapper">
                            <div className="box-drag">
                            <div className="box-drag-right">
                                <div className="bx-dr-01">
                                <div className="label-desc green">Import Resi dari Kurir</div>
                                </div>
                                <div className="bx-dr-02">
                                <div className="toolicon-wrapper kurir">
                                    <button id="show" className="toolicon kurir"><span>{appState.expedition}</span></button>
                                    <div className="tool-inner">
                                        <div className="tool-wrapper">
                                            <div className="tool-heading">Filter Expedisi</div>
                                            <div className="tool-scroll">
                                                <ul className="tool-select">
                                                    <li name='expedition' value='jnt' onClick={() => setAppState({ expedition: 'jnt'})} className={handleClass('jnt')}><img src="/img/ico-ex-jnt.jpg" alt="J&T" />J&amp;T Express</li>
                                                    <li name='expedition' value='jne' onClick={() => setAppState({ expedition: 'jne'})} className={handleClass('jne')}><img src="/img/ico-ex-jne.jpg" alt="JNE" />JNE Express</li>
                                                    <li name='expedition' value='sicepat' onClick={() => setAppState({ expedition: 'sicepat'})}  className={handleClass('sicepat')}><img src="/img/ico-ex-sicepat.jpg" alt="Sicepat" />Sicepat Express</li>
                                                    <li name='expedition' value='rpx' onClick={() => setAppState({ expedition: 'rpx'})}  className={handleClass('rpx')}><img src="/img/rpx.png" alt="RPX" className="img-courir" />RPX One Stop Logistics</li>
                                                    <li name='expedition' value='pos' onClick={() => setAppState({ expedition: 'pos'})}  className={handleClass('pos')}><img src="/img/pos.png" alt="POS" className="img-courir" />POS Indonesia</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="bx-dr-03">
                                {(() => {
                                    if (appState.expedition != 'Pilihan Kurir') {
                                        return <div className="custom-file-upload">
                                                    <label className="btn btn-tersier" htmlFor="customFile">Pilih File</label>
                                                    <input type="file" onChange={fileHandler.bind(this)} id="customFile" />
                                                </div>;
                                    } else {
                                        return  <div className="custom-file-upload alert-impor">
                                                        <label className="btn btn-tersier" htmlFor="customFile">Pilih File</label>
                                                        <span>Pilih dulu kurir</span>
                                                </div>;
                                    }
                                })()}
                                </div>
                            </div>
                            <div className="box-drag-left">
                                <button className="btn btn-primary" onClick={SendImporResi} disabled={appState.disabled}>{appState.disabled ? "Loading..." : "Impor Resi"}</button>
                            </div>
                            </div>{/* end of box-drag */}
                            <div className="box-drag align-items-center">
                                <div className="box-drag-right">
                                    <div className="box-drag-function">
                                    <img src="/img/avo-drag.svg" alt="Drag Area" />
                                    <span className="label-desc green ml-3"><em>Ambil file dan tarik ke sini</em></span>
                                    </div>
                                </div>
                                <div className="box-drag-left">
                                    <a href="/file/sample-import-resi.xlsx" download><button className="btn btn-plain" style={{width: '120px'}}><img src="/img/ico-download.svg" alt="download" /> Unduh File</button></a>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            {(() => {
                            if (appState.data) {
                                return <div className="result">Total <strong>{appState.data.length} Data</strong> Pesanan</div>;
                                } else {
                                    return <div className="result">Total <strong>0 Data</strong> Pesanan</div>;
                                }
                            })()}
                        </div>
                        </div>
                    </div>{/* end of main-dash*/}
                </div>
            </section>
            </div>
        </section>
    );
}

export default ImportResi;