import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { authHeader } from '../../_helpers';
import { API_STAGING } from '../../_settings'
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';

const getServicePerformance = async (startDate, endDate) => {
    const response = await axios.post(`${API_STAGING}service-performance`, null, {
        params: {
            start_date: startDate,
            end_date: endDate,
        },
        headers: authHeader(),
    });

    return response.data
}

function ServicePerformance() {
    const location = useLocation();
    const history = useHistory();

    const dateRangePickerRef = useRef(null);

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);

    const {startDate, endDate} = useMemo(() => {
        const query = new URLSearchParams(location.search);
        const sd = query.get('sd');
        const ed = query.get('ed');
        if (sd && ed) {
            return {
                startDate: new Date(sd),
                endDate: new Date(ed),
            }
        }
        return {
            startDate: new Date(),
            endDate: new Date(),
        }
    }, [location.search])

    useEffect(() => {
        setLoading(true);
        setData(undefined);
        getServicePerformance(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
            .then((response) => {
                setData(response.data);
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            });
    }, [startDate, endDate])
    
    return ( 
        <section id="dashboard-wrapper">
            <div className="container-wrapper">
                <Sidebar />
                <section id="main-dashboard">
                    <Header />
                    <div className="content-wrapper">
                        <div className="main-dash">
                            <div className="position-relative">
                                <div className="tool-daterange-dark" style={{width: 240}}>
                                    <div className="tool-daterange">
                                        <DateRangePicker
                                            ref={dateRangePickerRef}
                                            initialSettings={{
                                                maxDate: new Date(),
                                                opens: 'center',
                                                locale: {
                                                    format: 'YYYY-MM-DD',
                                                    cancelLabel: 'Clear',
                                                },
                                                startDate,
                                                endDate,
                                            }}
                                            onCallback={(start, end) => {
                                                history.replace({
                                                    search: `?sd=${start.format('YYYY-MM-DD')}&ed=${end.format('YYYY-MM-DD')}`
                                                });
                                            }}
                                            onCancel={() => {
                                                history.replace({
                                                    search: undefined
                                                });
                                                if (dateRangePickerRef.current) {
                                                    const today = new Date();
                                                    dateRangePickerRef.current.setStartDate(today);
                                                    dateRangePickerRef.current.setEndDate(today);
                                                }
                                            }}
                                            startDate={startDate}
                                            endDate={endDate}
                                        >
                                            <button className="daterange" style={{maxWidth: 'unset'}}>
                                                {`${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}`}
                                            </button>
                                        </DateRangePicker>
                                    </div>
                                </div>
                                <div className="tool-setting-target floating">
                                    <Link
                                        to={{
                                            pathname: '/service-performance/setting-target',
                                            search: location.search,
                                        }}
                                        className="btn btn-secondary"
                                    >
                                        Setting Target
                                    </Link>
                                </div>
                            </div>
                            <div className="table-data">
                            <div className="table-head table-sp">
                                <div className="th-sp">Statistik</div>
                                <div className="th-sp">Toko Saya</div>
                                <div className="th-sp">Target</div>
                                <div className="th-sp">Periode Sebelumnya</div>
                                <div className="th-sp">Penalti</div>
                            </div>
                            <div className="table-body table-sp">
                                <div className="tb-sp">
                                <div className="heading-sp">
                                    <div className="ico-sp">
                                    <img src="img/ico-sp-01.svg" alt="Icon 01" />
                                    </div>
                                    <div className="title-sp">
                                    <Link
                                        to={{
                                            pathname: '/service-performance/tidak-terselesaikan',
                                            search: location.search,
                                        }}
                                    >
                                        Tingkat pesanan tidak terselesaikan
                                    </Link>
                                    <span className="tool-tip">
                                        <a href="#" className="tool-tip-icon">?</a>
                                        <div className="tool-tip-content">
                                        Jumlah pesanan secara akumulatif yang terlambat untuk diproses dalam satu bulan untuk dilakukan pemenuhan (Fulfillment) hingga batas waktu 2 hari (exclude) hari Sabtu, Minggu, hari libur Nasional dan disajikan dalam bentuk persentase. (dihitung setiap akhir bulan).
                                        </div>
                                    </span>
                                    </div>
                                </div>
                                </div>
                                <div className="tb-sp">{data?.not_fulfilled_percentage?.current ? `${data.not_fulfilled_percentage.current}%` : loading ? '...' : '0%'}</div>
                                <div className="tb-sp">&lt; {data?.not_fulfilled_percentage?.target || '...'}%</div>
                                <div className="tb-sp">{data?.not_fulfilled_percentage?.previous ? `${data.not_fulfilled_percentage.previous}%` : loading ? '...' : '0%'}</div>
                                <div className="tb-sp">{data?.not_fulfilled_percentage?.penalty_point ? `${data.not_fulfilled_percentage.penalty_point} Poin Penalti` : loading ? '...' : '0 Poin Penalti'}</div>
                                <div className="tb-sp">
                                <div className="order-detail">
                                    <Link
                                        to={{
                                            pathname: '/service-performance/tidak-terselesaikan',
                                            search: location.search,
                                        }}
                                    >
                                        Selengkapnya
                                    </Link>
                                </div>
                                </div>
                            </div>{/* end of table-body */}
                            <div className="table-body table-sp">
                                <div className="tb-sp">
                                <div className="heading-sp">
                                    <div className="ico-sp">
                                    <img src="img/ico-sp-02.svg" alt="Icon 01" />
                                    </div>
                                    <div className="title-sp">
                                    <Link
                                        to={{
                                            pathname: '/service-performance/keterlambatan',
                                            search: location.search,
                                        }}
                                    >
                                        Tingkat keterlambatan pengiriman pesanan
                                    </Link>
                                    <span className="tool-tip">
                                        <a href="#" className="tool-tip-icon">?</a>
                                        <div className="tool-tip-content">
                                            Jumlah pesanan secara akumulatif yang dikirim melebihi batas waktu yang telah ditentukan yaitu 2 hari (exclude) hari Sabtu, Minggu dan hari libur Nasional dalam kurun waktu satu bulan dan disajikan dalam bentuk persentase. (dihitung setiap akhir bulan)
                                        </div>
                                    </span>
                                    </div>
                                </div>
                                </div>
                                <div className="tb-sp">{data?.late_percentage?.current ? `${data.late_percentage.current}%` : loading ? '...' : '0%'}</div>
                                <div className="tb-sp">&lt; {data?.late_percentage?.target || '...'}%</div>
                                <div className="tb-sp">{data?.late_percentage?.previous ? `${data.late_percentage.previous}%` : loading ? '...' : '0%'}</div>
                                <div className="tb-sp">{data?.late_percentage?.penalty_point ? `${data.late_percentage.penalty_point} Poin Penalti` : loading ? '...' : '0 Poin Penalti'}</div>
                                <div className="tb-sp">
                                <div className="order-detail">
                                    <Link
                                        to={{
                                            pathname: '/service-performance/keterlambatan',
                                            search: location.search,
                                        }}
                                    >
                                        Selengkapnya
                                    </Link>
                                </div>
                                </div>
                            </div>{/* end of table-body */}
                            <div className="table-body table-sp">
                                <div className="tb-sp">
                                <div className="heading-sp">
                                    <div className="ico-sp">
                                    <img src="img/ico-sp-03.svg" alt="Icon 01" />
                                    </div>
                                    <div className="title-sp">
                                    <Link
                                        to={{
                                            pathname: '/service-performance/pengemasan',
                                            search: location.search,
                                        }}
                                    >
                                        Masa pengemasan
                                    </Link>
                                    <span className="tool-tip">
                                        <a href="#" className="tool-tip-icon">?</a>
                                        <div className="tool-tip-content">
                                            Rata-rata jumlah waktu yang dibutuhkan untuk mengemas pesanan
                                        </div>
                                    </span>
                                    </div>
                                </div>
                                </div>
                                <div className="tb-sp">{data?.avg_packing_time?.current ? `${data.avg_packing_time.current} Hari` : loading ? '...' : '0 Hari'}</div>
                                <div className="tb-sp">&lt; {data?.avg_packing_time?.target || '...'} Hari</div>
                                <div className="tb-sp">{data?.avg_packing_time?.previous ? `${data.avg_packing_time.previous} Hari` : loading ? '...' : '0 Hari'}</div>
                                <div className="tb-sp">{data?.avg_packing_time?.penalty_point ? `${data.avg_packing_time.penalty_point} Poin Penalti` : loading ? '...' : '0 Poin Penalti'}</div>
                                <div className="tb-sp">
                                <div className="order-detail">
                                    <Link
                                        to={{
                                            pathname: '/service-performance/pengemasan',
                                            search: location.search,
                                        }}
                                    >
                                        Selengkapnya
                                    </Link>
                                </div>
                                </div>
                            </div>{/* end of table-body */}
                            </div>
                        </div>{/* end of main-dash*/}
                        </div>
                </section>
            </div>
        </section>
    );
}

export default ServicePerformance;
