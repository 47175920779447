import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import DatePicker  from 'react-datepicker';
import { Modal } from 'react-bootstrap';
import { authHeader } from '../../_helpers';
import { API_STAGING } from '../../_settings'
import Header from '../../components/Header';
import List from './List';
import Loading from '../../components/Loading';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { enGB, id } from 'date-fns/locale';
import { addDays, subDays } from 'date-fns';
registerLocale('id', id)

function Notification() {
    const ListLoading               = Loading(List);
    const [selectedPage, setPage]   = useState('1');
    const [show, setShow] = useState(false);
    const [error, setError]       = useState('');
    const [appState, setAppState]   = useState({
        loading: false,
        data: null,
        total_not_read: null,
        total: null,
        to:null,
        current_page: null,
        disabled: false,
        showLoading: false,
        message: null
    });

    function set_page(e){
        const selectedPage = e
        setPage(selectedPage);
        setAppState({ loading: true });
        axios.post(`${API_STAGING}notification?page=${selectedPage}`, {
            //...data
            },  {
            headers: authHeader()
        })
        .then((repos) => {
            const data = repos.data.data;
            setAppState({ loading: false, data: data.data, total_not_read: data.total_not_read, total: data.total, current_page: data.current_page, last_page: data.last_page});
        });
    }
    useEffect(() => {
        setAppState({ loading: true, showLoading:true});
        const fetchData = async () => {
          const respGlobal = await axios.post(
            `${API_STAGING}notification?page=${selectedPage}`, {
                //...data
            },  {
                headers: authHeader()
            })
            setAppState({ showLoading: false, data: respGlobal.data.data.data, total_not_read: respGlobal.data.data.total_not_read, total: respGlobal.data.data.total, current_page: respGlobal.data.data.current_page, last_page: respGlobal.data.data.last_page });
        };
        fetchData();
      }, []);
    function save(e){
        setAppState({dat: e, data: appState.data, total: appState.total, current_page: appState.current_page, to: appState.to })
    }
    return ( 
        <section id="dashboard-wrapper">
            <Header />
            <div className="content-wrapper d-flex p-40">
                <div className="box-setting-right-full">
                    <div className="white-box p-20">
                        <h3 className="heading-box lg txt-center">Semua List Notifikasi</h3>
                        <ListLoading isLoading={appState.loading} data={appState.data} total={appState.total} last_page={appState.last_page} current_page={appState.current_page} total_not_read={appState.total_not_read} save={(e)=>save(e)} set_page={(e)=>set_page(e)} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Notification;
