import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import DatePicker  from 'react-datepicker';
import { Modal } from 'react-bootstrap';
import { authHeader } from '../../_helpers';
import { API_STAGING } from '../../_settings'
import Header from '../../components/Header';
import List from './List';
import Loading from '../../components/Loading';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { enGB, id } from 'date-fns/locale';
import { addDays, subDays } from 'date-fns';
registerLocale('id', id)

function Settings({ match }) {
    const ListLoading               = Loading(List);
    const [startDate, setStartDate] = useState(new Date());
    const [max_days, setDays]       = useState(localStorage.getItem('max_days'));
    const [max_hours, setHours]     = useState(localStorage.getItem('max_hours'));
    const [max_minutes, setMinutes] = useState(localStorage.getItem('max_minutes'));
    const [note, setNote]           = useState('');
    const [sorting, setSorting]     = useState('');
    const [year, setYear]           = useState('');
    const [selectedPage, setPage]   = useState('1');
    const [show, setShow] = useState(false);
    const [error, setError]       = useState('');
    const months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember"
      ];
      function generateArrayOfYears() {
        var max = new Date().getFullYear()
        var min = max - 9
        var years = []
      
        for (var i = max; i >= min; i--) {
          years.push(i)
        }
        return years
      }
    const years = generateArrayOfYears();
    const [appState, setAppState]   = useState({
        loading: false,
        data: null,
        dat: null,
        repos: null,
        detail: null,
        result: null,
        total: null,
        to:null,
        current_page: null,
        disabled: false,
        showLoading: false,
        message: null
    });

    const onChangeSort = event =>  {
        const sorting = event.target.value
        setSorting(sorting);
        setAppState({ loading: true });
        axios.post(`${API_STAGING}setting-date/listing?sorting=${sorting}&year=${year}&page=${selectedPage}`, {
            //...data
            },  {
            headers: authHeader()
        })
        .then((repos) => {
            const data = repos.data.data;
            setAppState({ loading: false, data: data.data, total: data.total, current_page: data.current_page, to: data.to });
        });
        
    }
    
    const onChangeYear = event =>  {
        const year = event.target.value
        setYear(year);
        setAppState({ loading: true });
        axios.post(`${API_STAGING}setting-date/listing?year=${year}&sorting=${sorting}&page=${selectedPage}`, {
            //...data
            },  {
            headers: authHeader()
        })
        .then((repos) => {
            const data = repos.data.data;
            setAppState({ loading: false, data: data.data, total: data.total, current_page: data.current_page, to: data.to });
        });
        
    }
    const onChangeDate = (event) => {
        if (event) {
            const startDate = event;
            setStartDate(startDate);
            console.log(event)
        }
        setAppState({ showLoading: true, data:appState.data, status: appState.status, total: appState.total, current_page: appState.current_page, to: appState.to });
        if (event) {
            axios.post(`${API_STAGING}setting-date/listing?date=${((event.getMonth() > 8) ? (event.getMonth() + 1) : ('0' + (event.getMonth() + 1)))+'/'+((event.getDate() > 9) ? event.getDate() : ('0' + event.getDate()))+'/'+event.getFullYear()}`, {
                //...data
                },  {
                headers: authHeader()
            })
            .then((repos) => {
                const detail = repos.data.data;
                setAppState({showLoading: false, detail: detail.data, data:appState.data, status: appState.status, total: appState.total, current_page: appState.current_page, to: appState.to });
                if (repos.data.data.data) {
                    const note = repos.data.data.data[0].notes;
                    setNote(note);
                }
            });
        } else {
            axios.post(`${API_STAGING}setting-date/listing?date=${((new Date().getMonth() > 8) ? (new Date().getMonth() + 1) : ('0' + (new Date().getMonth() + 1)))+'/'+((new Date().getDate() > 9) ? new Date().getDate() : ('0' + new Date().getDate()))+'/'+new Date().getFullYear()}`, {
                //...data
                },  {
                headers: authHeader()
            })
            .then((repos) => {
                const detail = repos.data.data;
                setAppState({showLoading: false, detail: detail.data, data:appState.data, status: appState.status, total: appState.total, current_page: appState.current_page, to: appState.to });
                if (repos.data.data.data) {
                    const note = repos.data.data.data[0].notes;
                    setNote(note);
                }
            });
        }
    }
    const onChangeDays = (e) => {
        let { value, min, max }  = e.target
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        setDays(value)
        setError('')
    }
    const onChangeHours = (e) => {
        let { value, min, max } = e.target
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        setHours(value)
        setError('')
    }
    const onChangeMinutes = (e) => {
        let { value, min, max } = e.target
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        setMinutes(value)
        setError('')
    }
    const onChangeNote = (e) => {
        const value = e.target.value
        setNote(value)
        setError('')
    }
    const updatePengemasan = () => {
        setAppState({disabled: true, detail: appState.data, data:appState.data, total: appState.total, current_page: appState.current_page, to: appState.to});
        axios.post(`${API_STAGING}setting-pengemasan/update/?max_days=${max_days}&max_hours=${max_hours}&max_minutes=${max_minutes}`, {
            //...data
        },  {
            headers: authHeader()
        })
        .then(repos => {
            const data = repos.data.message;
            if(repos.data.status == true) {
                localStorage.setItem('max_days', repos.data.data.day)
                localStorage.setItem('max_hours', repos.data.data.hour)
                localStorage.setItem('max_minutes', repos.data.data.minute)
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                window.location.reload()
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
                window.location.reload()
            }
        })
    }
    function set_page(e){
        const selectedPage = e
        setPage(selectedPage);
        setAppState({ loading: true });
        axios.post(`${API_STAGING}setting-date/listing?sorting=${sorting}&year=${year}&page=${selectedPage}`, {
            //...data
            },  {
            headers: authHeader()
        })
        .then((repos) => {
            const data = repos.data.data;
            setAppState({ loading: false, data: data.data, total: data.total, current_page: data.current_page, to: data.to});
        });
    }
    useEffect(() => {
        setAppState({ loading: true, showLoading:true});
        const fetchData = async () => {
          const respGlobal = await axios.post(
            `${API_STAGING}setting-date/listing?sorting=${sorting}&year=${year}&page=${selectedPage}`, {
                //...data
            },  {
                headers: authHeader()
            })
          const respDetail = await axios.post(
            `${API_STAGING}setting-date/listing?date=${((new Date().getMonth() > 8) ? (new Date().getMonth() + 1) : ('0' + (new Date().getMonth() + 1)))+'/'+((new Date().getDate() > 9) ? new Date().getDate() : ('0' + new Date().getDate()))+'/'+new Date().getFullYear()}`, {
                //...data
            },  {
                headers: authHeader()
            })
            if (respDetail.data.data.data) {
                const note = respDetail.data.data.data[0].notes;
                setNote(note);
            }
            setAppState({ showLoading: false, data: respGlobal.data.data.data, status: respGlobal.data.status, total: respGlobal.data.data.total, current_page: respGlobal.data.data.current_page, to: respGlobal.data.data.to, detail: respDetail.data.data.data });
        };
        fetchData();
      }, []);
    function save(e){
        setAppState({dat: e, data: appState.data, total: appState.total, current_page: appState.current_page, to: appState.to })
    }
    function openAdd() {
        setShow(true);
        setNote('');
    }
    function closeAdd() {
        setShow(false);
        setNote('');
    }
    function AddSettingDate() {
        setShow(false);
        setAppState({loading: true});
        axios.post(`${API_STAGING}setting-date/add?date=${((startDate.getMonth() > 8) ? (startDate.getMonth() + 1) : ('0' + (startDate.getMonth() + 1)))+'/'+((startDate.getDate() > 9) ? startDate.getDate() : ('0' + startDate.getDate()))+'/'+startDate.getFullYear()}&note=${note}`, {
            //...data
        },  {
            headers: authHeader()
        })
        .then((repos) => {
            const data = repos.data.message;
            if(repos.data.status == true) {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                window.location.reload()
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
                window.location.reload()
            }
          });
    }
    function clearFilter () {
        setAppState({loading: true });
        const sorting = ''
        setSorting(sorting);
        const year = ''
        setYear(year);
        const note = '';
        setNote(note);
        axios.post(`${API_STAGING}setting-date/listing?sorting=${sorting}&year=${year}&page=${selectedPage}`, {
            //...data
            },  {
            headers: authHeader()
        })
        .then((repos) => {
            const data = repos.data.data;
            setAppState({ loading: false, data: data.data, detail: appState.detail,total: data.total, current_page: data.current_page, to: data.to});
        });
    }
    return ( 
        <section id="dashboard-wrapper">
            <Header />
            <div className="content-wrapper d-flex p-40">
                <div className="box-setting-left">
                    <div className="white-box p-20">
                        <h3 className="heading-box lg">Maksimal pemerosesan pesanan</h3>
                        <form className="form-setting">
                            <div className="row align-items-end">
                                <div className="col-8">
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label htmlFor="inputDays">Days</label>
                                            <input type="number" className="form-control" value={max_days} min="0" max="7" onChange={onChangeDays.bind(max_days)} id="inputDays" />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor="inputHours">Hours</label>
                                            <input type="number" className="form-control" value={max_hours} min="0" max="23" onChange={onChangeHours.bind(max_hours)} id="inputHours" />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor="inputMinutes">Minutes</label>
                                            <input type="number" className="form-control" value={max_minutes} min="0" max="59" onChange={onChangeMinutes.bind(max_minutes)} id="inputMinutes" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                    {(() => {
                                        if (max_days<0 || max_hours<0 || max_minutes<0){
                                            return <button type="submit" className="btn btn-primary btn-block btn-update-pengemasan" disabled="true">Update</button>;    
                                        } else {
                                            return <button className="btn btn-primary" onClick={updatePengemasan} disabled={appState.disabled}>{appState.disabled ? "Loading..." : "Update"}</button>;
                                        }
                                    })()}
                                        
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="tool-tip-box">
                            <span className="tool-tip">
                                <button className="tool-tip-icon">?</button>
                                <div className="tool-tip-content right">
                                    Waktu maksimal pemerosesan pesanan yang harus di lakukan. waktu akan terus berjalan kecuali di hari sabtu minggu dan juga hari yang di pilih untuk libur pemerosesan pesanan.
                                </div>
                            </span>
                        </div>
                    </div>{/* end of white-box*/}
                    <div className="white-box p-20">
                        {(() => {
                            if (appState.showLoading == false && appState.status == true){ 
                                let highlight =[];
                                for (let index = 0; index < appState.data.length; index++) {
                                    highlight.push(subDays(new Date(`${appState.data[index].date}`), 0));
                                }
                                return <DatePicker
                                    calendarClassName="calender-setting"
                                    locale="id"
                                    renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                        decreaseMonth,
                                        increaseMonth,
                                        prevMonthButtonDisabled,
                                        nextMonthButtonDisabled
                                    }) => (
                                        <div
                                        style={{
                                            marginBottom: "20px",
                                            display: "flex",
                                            justifyContent: "center"
                                        }}
                                        >
                                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="btn-left">
                                            <img src="/img/ico-chevron-right.svg"/>
                                        </button>
                                        <select
                                            value={months[date.getMonth()]}
                                            onChange={({ target: { value } }) =>
                                            changeMonth(months.indexOf(value))
                                            }
                                            className="mr-2"
                                        >
                                            {months.map(option => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                            ))}
                                        </select>
                                        <select
                                            value={date.getFullYear()}
                                            onChange={({ target: { value } }) => changeYear(value)}
                                        >
                                            {years.map(option => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                            ))}
                                        </select>
                                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}  className="btn-right">
                                            <img src="/img/ico-chevron-right.svg"/>
                                        </button>
                                        </div>
                                    )}
                                    selected={startDate}
                                    dateFormat="MM/dd/yyyy"
                                    onChange={onChangeDate.bind(startDate)}
                                    highlightDates={highlight}
                                    inline
                                    />
                            }  else if (appState.showLoading == true || appState.status == undefined) {
                                return  <div>
                                        <div className="credential-50">
                                            <div className="credential-box-noborder-nowidth">
                                            <div className="load-2">
                                                <p>Loading... Please wait</p>
                                                <div class="line"></div>
                                                <div class="line"></div>
                                                <div class="line"></div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>;
                            } else if (appState.showLoading == false && appState.status == false) {
                                return <DatePicker
                                    calendarClassName="calender-setting"
                                    locale="id"
                                    renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                        decreaseMonth,
                                        increaseMonth,
                                        prevMonthButtonDisabled,
                                        nextMonthButtonDisabled
                                    }) => (
                                        <div
                                        style={{
                                            marginBottom: "20px",
                                            display: "flex",
                                            justifyContent: "center"
                                        }}
                                        >
                                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="btn-left">
                                            <img src="/img/ico-chevron-right.svg"/>
                                        </button>
                                        <select
                                            value={months[date.getMonth()]}
                                            onChange={({ target: { value } }) =>
                                            changeMonth(months.indexOf(value))
                                            }
                                            className="mr-2"
                                        >
                                            {months.map(option => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                            ))}
                                        </select>
                                        <select
                                            value={date.getFullYear()}
                                            onChange={({ target: { value } }) => changeYear(value)}
                                        >
                                            {years.map(option => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                            ))}
                                        </select>
                                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}  className="btn-right">
                                            <img src="/img/ico-chevron-right.svg"/>
                                        </button>
                                        </div>
                                    )}
                                    selected={startDate}
                                    dateFormat="MM/dd/yyyy"
                                    onChange={onChangeDate.bind(startDate)}
                                    inline
                                    />
                            }
                        })()}
                    </div>
                    <div className="white-box p-20">
                        <div className="date-setting">{new Date(startDate).getDate()+ " " + months[new Date(startDate).getMonth()]+ " "+ new Date(startDate).getFullYear()}</div>
                        <div className="note-text">Note: </div>
                        {(() => {
                            if (appState.showLoading == true){
                            return  <div>
                                        <div className="credential-50">
                                            <div className="credential-box-noborder-nowidth">
                                            <div className="load-2">
                                                <p>Loading... Please wait</p>
                                                <div class="line"></div>
                                                <div class="line"></div>
                                                <div class="line"></div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>;
                            }
                        })()}
                        <div>
                            {(() => {
                                if (appState.detail == undefined && appState.showLoading == false){
                                    return <p>Belum ada catatan di tanggal ini</p>;
                                }else if (appState.detail == undefined && appState.showLoading == true){
                                    return '';
                                } else if (appState.detail != undefined && appState.showLoading == false){
                                    return <p>{note}</p>;
                                }
                            })()}
                        </div>
                        {appState.detail && appState.detail.length> 0 ? appState.detail.map((repo,index) => {
                            return (
                            <div className="add-setting">
                                <button className="btn-link" disabled="true"><img src="/img/add-setting.png" alt="Add" /></button>
                            </div>
                            );
                        }): <div className="add-setting">
                                <button className="btn-link" onClick={openAdd}><img src="/img/add-setting.png" alt="Add" /></button>
                                <div id="popup-add" className="overlay">
                                    <div className="popup">
                                    <Modal show={show} >
                                        <button className="close" onClick={closeAdd}><img src="img/ico-close.svg" alt="close" /></button>
                                        <div className="content">
                                            <h3 className="heading-box lg mb-4">Add Stop Order Processing</h3>
                                            <div className="form-group">
                                                <textarea className="form-control" id="inputText" rows={10} value={note} onChange={onChangeNote} />
                                            </div>
                                            <div className="text-right">
                                            {(() => {
                                                if (note == ''){
                                                    return <button className="btn btn-primary" disabled="true">Submit</button>;
                                                    
                                                } else {
                                                    return <button className="btn btn-primary" onClick={AddSettingDate}>Submit</button>;
                                                }
                                            })()}
                                            </div>
                                        </div>
                                    </Modal>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="box-setting-right">
                    <div className="white-box p-20">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="position-relative">
                                <h3 className="heading-box lg">Tanggal Libur Pemerosesan Pesanan</h3>
                                <div className="tool-tip-box" style={{top: '0px', right: '-30px'}}>
                                    <span className="tool-tip">
                                        <button className="tool-tip-icon">?</button>
                                        <div className="tool-tip-content">
                                            Tanggal yang akan di lewatkan untuk hitungan mundur waktu pemerosesan pesanan.
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="toolicon-wrapper">
                                    <button id="show" className="toolicon sort" />
                                    <div id="test" className="tool-inner right">
                                        <div className="tool-wrapper">
                                            <div className="tool-heading">Urutkan</div>
                                            <ul className="tool-select">
                                                <button name='sorting' value='desc' onClick={onChangeSort} className = {sorting == 'desc' ? "tool-select-item active": "tool-select-item"}>Terbaru</button>
                                                <button name='sorting' value='asc' onClick={onChangeSort} className = {sorting == 'asc' ? "tool-select-item active": "tool-select-item"}>Terlama</button>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="toolicon-wrapper">
                                    <button id="show" className="toolicon filter" />
                                    <div className="tool-inner right">
                                        <div className="tool-wrapper">
                                            <div className="tool-heading">Filter</div>
                                            <div className="text-center mb-2">Lompat Ke Tahun</div>
                                            <div className="tool-scroll">
                                                <ul className="tool-select">
                                                {years.map(option => {
                                                    return (<button name='year' value={option} onClick={onChangeYear} className = {year == `${option}` ? "tool-select-item active": "tool-select-item"}>{option}</button>);
                                                })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="toolicon-wrapper">
                                    <div className="tool-clear mr-0">
                                        <button className="btn btn-secondary" onClick={clearFilter}>Clear</button>
                                    </div>
                                </div>
                            </div>{/* end of d-flex*/}
                        </div>{/* end of d-flex*/}
                        <ListLoading isLoading={appState.loading} data={appState.data} total={appState.total} to={appState.to} current_page={appState.current_page} save={(e)=>save(e)} set_page={(e)=>set_page(e)} clearFilter={clearFilter} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Settings;
