import React from 'react';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

const List = ({data, onChangePage, loading}) => {
    return (
        <div>
            <div className="result">Total <strong>{loading ? '...' : data?.total ?? '0'} Data</strong> Pesanan</div>
            <div className="table-data" style={{minHeight: 320}}>
                <div className="table-head table-sp-tt">
                    <div className="th-sp-tt">Pesanan</div>
                    <div className="th-sp-tt">Waktu Pemesanan</div>
                    <div className="th-sp-tt">Alasan Pembatalan</div>
                    <div className="th-sp-tt">Total Pembayaran</div>
                </div>
                {loading
                    ? (
                        <div style={{
                            height: 320,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <div className="load-2">
                                <p>Loading... Please wait</p>
                                <div class="line"></div>
                                <div class="line"></div>
                                <div class="line"></div>
                            </div>
                        </div>
                    ) : data?.orders && data.orders.length > 0 ? data.orders.map(item => (
                        <div className="table-body table-sp-tt" key={item.id}>
                            <div className="tb-sp-tt d-flex">
                                <div className="label-desc green">{item.invoice_number}</div>
                                <div className="ml-3">
                                    <span><img src="/img/ico-user.svg" alt="user" className="image-order" /></span>
                                    <span>{item.customer_name}</span>
                                </div>
                            </div>
                            <div className="tb-sp-tt">
                                <div className="label-desc">{item.order_date}</div>
                            </div>
                            <div className="tb-sp-tt">
                                <div className="label-desc">{item.cancel_reason}</div>
                            </div>
                            <div className="tb-sp-tt">
                                <div className="label-desc">{item.total_price_text}</div>
                            </div>
                            <div className="tb-sp-tt">
                                <div className="order-detail">
                                    <Link to={`/order/detail/${item.id}`}>Selengkapnya</Link>
                                </div>
                            </div>
                        </div>
                    )) : (
                        <p style={{textAlign: 'center', marginTop: 120, marginBottom: 120}}>
                            No data order, sorry
                        </p>
                    )}
                <div className="pagination">
                    <ReactPaginate
                        pageCount={data?.last_page ?? 1}
                        pageRangeDisplayed={4}
                        marginPagesDisplayed={1}
                        forcePage={(data?.current_page ?? 0) - 1}
                        activeLinkClassName='active'
                        containerClassName='paging'
                        previousLabel='&laquo;'
                        nextLabel='&raquo;'
                        onPageChange={(page) => onChangePage(page.selected + 1)}
                    />
                </div>
            </div>
        </div>
    );
}
export default List;
