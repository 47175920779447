import React, { useEffect, useState, useRef } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import { Formik, Form } from 'formik';
import Barcode from 'react-barcode';
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import  '../../_styles/PrintA6.css';
import { authHeader } from '../../_helpers';
import { API_STAGING } from '../../_settings'
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import List from './List';
import Loading from '../../components/Loading';

function RequestAWB() {
    const ListLoading              = Loading(List);
    const [label, setLabel]        = useState('any');
    const [search, setSearchTerm] = useState('');
    const [limit, setLimit]           = useState('10');
    const [sorting, setSorting]       = useState('');
    const [expedition, setExpedition] = useState('');
    const [startDate, setStartDate]   = useState('');
    const [endDate, setEndDate]       = useState('');
    const dateRangePickerRef          = useRef(null);
    const [selectedPage, setPage]     = useState('');
    const [show, setShow] = useState(false);
    const componentRef = useRef();
    const [appState, setAppState]     = useState({
        loading: false,
        data: null,
        disabled: false,
        pageLabel: 'any',
        startDate : '',
        endDate: '',
        repos: null,
        dat: null,
        total: null,
        to:null,
        current_page: null,
        detail: null,
        product: null,
        formatBarcode: 'pharmacode',
    });
    const onChangeLabel = (event) => {
        const pageLabel = event.pageLabel
        setAppState({ loading: true, pageLabel: event.pageLabel, printLabel: appState.printLabel});
        if(pageLabel == 'any' || pageLabel == 'success') {
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=${pageLabel}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, printLabel: appState.printLabel, pageLabel: event.pageLabel, repos: data.data, total: data.total, current_page: data.current_page, to: data.to});
                });
            } else {
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=${pageLabel}&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, printLabel: appState.printLabel, pageLabel: event.pageLabel, repos: data.data, total: data.total, current_page: data.current_page, to: data.to});
                });
            }
        } else if (pageLabel == 'error') {
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=error&awb-error=yes&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                  },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, printLabel: appState.printLabel, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: event.pageLabel});
                });
            } else {
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=error&awb-error=yes&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                  },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, printLabel: appState.printLabel, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: event.pageLabel});
                });
            }
        }
    }
    const changeSearch = (e) => {
        const value = e.target.value
        setSearchTerm(value)
    }
    const onChangeSearch = (event) => {
        const data = {
            search: search,
        }
        setAppState({ loading: true, pageLabel: appState.pageLabel });
        if(appState.pageLabel == 'any' || appState.pageLabel == 'success') {
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=${appState.pageLabel}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel });
                });
            } else {
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=${appState.pageLabel}&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel });
                });
            }
        } else if (appState.pageLabel == 'error') {
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=error&awb-error=yes&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel});
                });
            } else {
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=error&awb-error=yes&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel});
                });
            }
        }
    }
    const onChangeLimit = (event) => {
        const limit = event.target.value
        setLimit(limit);
        setAppState({ loading: true, pageLabel: appState.pageLabel });
        if(appState.pageLabel == 'any' || appState.pageLabel == 'success') {
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=${appState.pageLabel}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel });
                });
            } else {
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=${appState.pageLabel}&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel });
                });
            }
        } else if (appState.pageLabel == 'error') {
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=error&awb-error=yes&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel});
                });
            } else {
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=error&awb-error=yes&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel});
                });
            }
        }
    }
    const onChangeSort = event =>  {
        const sorting = event.target.value
        setSorting(sorting);
        setAppState({ loading: true, pageLabel: appState.pageLabel });
        if(appState.pageLabel == 'any' || appState.pageLabel == 'success') {
            axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=${appState.pageLabel}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel });
            });
        } else if (appState.pageLabel == 'error') {
            axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=error&awb-error=yes&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                //...data
              },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel});
            });
        }
    }
    const onChangeExpedition = event =>  {
        const expedition = event.target.value
        setExpedition(expedition);
        setAppState({ loading: true, pageLabel: appState.pageLabel });
        if(appState.pageLabel == 'any' || appState.pageLabel == 'success') {
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=${appState.pageLabel}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel });
                });
            } else {
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=${appState.pageLabel}&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel });
                });
            }
        } else if (appState.pageLabel == 'error') {
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=error&awb-error=yes&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel});
                });
            } else {
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=error&awb-error=yes&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel});
                });
            }
        }
    }
    function onChangeDate (event,picker) {
        setAppState({loading: true, pageLabel: appState.pageLabel});
        const startDate = picker.startDate.format("YYYY-MM-DD")
        setStartDate(startDate);
        const endDate =  picker.endDate.format("YYYY-MM-DD")
        setEndDate(endDate);
        if(appState.pageLabel == 'any' || appState.pageLabel == 'success') {
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=${appState.pageLabel}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel });
                });
            } else {
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=${appState.pageLabel}&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel });
                });
            }
        } else if (appState.pageLabel == 'error') {
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=error&awb-error=yes&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel});
                });
            } else {
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=error&awb-error=yes&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel});
                });
            }
        }
    }
    const handleCancel = (event, picker) => {
        setAppState({loading: true, pageLabel: appState.pageLabel});
        if (dateRangePickerRef.current) {
            const today = new Date();
            dateRangePickerRef.current.setStartDate(today);
            dateRangePickerRef.current.setEndDate(today);
        }
        const startDate = '';
        setStartDate(startDate);
        const endDate =  '';
        setEndDate(endDate);
        if(appState.pageLabel == 'any' || appState.pageLabel == 'success') {
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=${appState.pageLabel}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel });
                });
            } else {
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=${appState.pageLabel}&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel });
                });
            }
        } else if (appState.pageLabel == 'error') {
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=error&awb-error=yes&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel});
                });
            } else {
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=error&awb-error=yes&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel});
                });
            }
        }
    }
    function clearFilter () {
        setAppState({loading: true, pageLabel: appState.pageLabel });
        const search = ''
        setSearchTerm(search);
        const sorting = ''
        setSorting(sorting);
        const expedition = ''
        setExpedition(expedition);
        const startDate = ''
        setStartDate(startDate);
        const endDate =  ''
        setEndDate(endDate);
        if(appState.pageLabel == 'any' || appState.pageLabel == 'success') {
            axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=${appState.pageLabel}&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel });
            });
        } else if (appState.pageLabel == 'error') {
            axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=error&awb-error=yes&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel});
            });
        }
    }
    let start = startDate
    let end = endDate
    let labelDate = start + ' - ' + end;
    if (start === end) {
        labelDate = start;
    }
    let today = new Date();
    let dateNow = today.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'numeric', year: 'numeric'
      }).replace(/[^\d,.-]/g,'-');     
    let timeNow = today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();  
    
    async function UpdateRequestAWB() {
        for (let index of appState.repos.keys()) {
            if(appState.repos[index].isChecked==true){
                setAppState({loading: true, disabled: true, pageLabel: appState.pageLabel});
                const fetchData = async () => {
                    try {
                        const respRequest = await axios.post(
                        `${API_STAGING}order/generate-awb?id=${appState.repos[index].id}`, {
                            //...data
                        },  {
                            headers: authHeader()
                        })
                        const data = respRequest.data.message;
                        if(respRequest.data.status == true) {
                            swal({
                                title: "Done!",
                                text: data,
                                icon: "success",
                                timer: 3000,
                                button: false
                            })
                        } else {
                            swal({
                                title: "Error!",
                                text: data,
                                icon: "error",
                                button: true
                            })
                        }
                        setAppState({ loading: false, total: appState.total, current_page: appState.current_page, to: appState.to, pageLabel: appState.pageLabel });
                        onChangeLabel({ pageLabel: appState.pageLabel})
                    } catch (err) {
                        console.error(err);
                    }
                }
                await fetchData();
            };
        };
    }
    function set_page(e){
        const selectedPage = e
        setPage(selectedPage);
        setAppState({ loading: true });
        if(appState.pageLabel == 'any' || appState.pageLabel == 'success') {
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=${appState.pageLabel}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=${selectedPage}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel});
                });
            } else {
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=${appState.pageLabel}&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=${selectedPage}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel});
                });
            }
        } else if (appState.pageLabel == 'error') {
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=error&awb-error=yes&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=${selectedPage}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel});
                });
            } else {
                axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=error&awb-error=yes&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=${selectedPage}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel});
                });
            }
        }
    }
    function openPrint() {
        setShow(true);
        let arr =[];
        appState.repos.forEach((result,index) =>{
            if(appState.repos[index].isChecked==true){
                setAppState({loading: true, pageLabel: appState.pageLabel});
                    axios.post(`${API_STAGING}order/listing/${appState.repos[index].id}`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const detail = repos.data;
                    arr.push(detail)
                    setAppState({ loading: false, detail: arr,repos:appState.repos, total:appState.total, label: label, pageLabel:appState.pageLabel, printLabel:appState.printLabel});
                });
            }
        })
    }
    function closePrint () {
        setShow(false);
        setAppState({ loading: true, pageLabel: appState.pageLabel });
        if (sorting == "asc" || sorting == "desc"){
            axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=success&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=1&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel });
            });
        } else {
            axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=success&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=1&export-data=yes`, {
                //...data
              },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel });
              });
        }
    }
    function printResi() {
        appState.repos.forEach((result,index) =>{
            if(result.isChecked==true){
                setAppState({loading: true, pageLabel: appState.pageLabel});
                    axios.post(`${API_STAGING}order/generate-label?id=${appState.repos[index].id}`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.message;
                    if(repos.data.status == true) {
                        swal({
                            title: "Done!",
                            text: data,
                            icon: "success",
                            timer: 3000,
                            button: false
                        })
                        setAppState({ loading: false, total: appState.total, current_page: appState.current_page, to: appState.to, pageLabel: appState.pageLabel });
                        onChangeLabel({ pageLabel: appState.pageLabel})
                    } else {
                        swal({
                            title: "Error!",
                            text: data,
                            icon: "error",
                            button: true
                        })
                        setAppState({ loading: false, total: appState.total, current_page: appState.current_page, to: appState.to, pageLabel: appState.pageLabel });
                        onChangeLabel({ pageLabel: appState.pageLabel})
                    }
                });
            }
        })
    }
    function MoveLabel() {
        appState.repos.forEach((result,index) =>{
            if(appState.repos[index].isChecked==true){
                setAppState({loading: true, pageLabel: appState.pageLabel});
                    axios.post(`${API_STAGING}order/move-to-label?id=${appState.repos[index].id}`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.message;
                    swal({
                        title: "Done!",
                        text: data,
                        icon: "success",
                        timer: 3000,
                        button: false
                      })
                    setAppState({ loading: false,total: appState.total, current_page: appState.current_page, to: appState.to, pageLabel: appState.pageLabel });
                    onChangeLabel({ pageLabel: appState.pageLabel})
                });
            }
        })
    }
    useEffect(() => {
        setAppState({ loading: true, pageLabel: appState.pageLabel });
        if(appState.pageLabel == 'any' || appState.pageLabel == 'success') {
            axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=${appState.pageLabel}&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=1&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, label: label, pageLabel: appState.pageLabel, printLabel: appState.printLabel });
            });
        } else if (appState.pageLabel == 'error') {
            axios.post(`${API_STAGING}order/listing?status=processing&awb=yes&label=error&awb-error=yes&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=${selectedPage}&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageLabel: appState.pageLabel, printLabel: appState.printLabel});
            });
        }
        }, [setAppState]);
        
    function save(e){
        setAppState({dat: e, repos: appState.repos, total: appState.total, current_page: appState.current_page, to: appState.to, pageLabel: appState.pageLabel, printLabel: appState.printLabel })
    }

    var getNumberOfDigits = function(num){
        var count = 1;
        while(Math.floor(num/10) >= 1){
            num = Math.floor(num/10);
            ++count;
        }
        return count;
    }
    class ComponentToPrint extends React.Component {
        render() {
            return (
                <div>
                    {appState.detail.map((repo,index) => {
                        return (
                            <div className={"label-list size-a6"}>
                                <div className="d-flex justify-content-between">
                                    <div className="text-center"><img src="/img/avoskin-logo-black.jpg" alt="avoskin" width={130} height={61} style={{paddingTop: '10px', marginTop: '10px', height: 'auto'}} /></div>
                                    <div className="text-center barcode-resi"><Barcode value={repo.data.resi}  width={2}/></div>
                                    <div className="text-center"><img src={repo.data.ekspedisi.image} alt={repo.data.ekspedisi.name} className="logo-ekspedisi" style={{paddingTop: '18px', marginTop: '18px', maxHeight: '61px', height: 'auto'}} /></div>
                                </div>
                                <div className="d-flex border-line no-border">
                                    <div className="col-wrap flex-50">
                                        <span className="label-title mr-3">Jenis Layanan</span>
                                        <span className="label-desc">{repo.data.ekspedisi.name}</span>
                                    </div>
                                    <div className="col-wrap flex-50">
                                        <span className="label-title mr-3">Tanggal Order</span>
                                        <span className="label-desc">{repo.data.shipping_label.date}</span>
                                    </div>
                                </div>
                                <div className="d-flex border-line">
                                    <div className="flex-50 d-flex align-items-center justify-content-center barcode-invoice">
                                        <Barcode value={repo.data.invoice_number} width={3}/>
                                        <text class="barcode-inv-text" text-anchor="middle" x="57" y="122">{repo.data.invoice_number}</text>
                                    </div>
                                    <div className="flex-50 border-line-lt no-border">
                                        <div className="col-wrap border-line no-border">
                                            <span className="label-title mw-80">Asuransi</span>
                                            <span className="label-desc">-</span>
                                        </div>
                                        <div className="col-wrap border-line no-border">
                                            <span className="label-title mw-80">Berat</span>
                                            <span className="label-desc">{repo.data.shipping_label.weight}</span>
                                        </div>
                                        <div className="col-wrap">
                                            <span className="label-title mw-80">Quantity</span>
                                            <span className="label-desc">{repo.data.shipping_label.qty}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex border-line">
                                    <div className="flex-50">
                                        <div className="col-wrap">
                                            <div className="label-title">Nama Penerima</div>
                                            <div className="label-desc">{repo.data.customer_name}</div>
                                        </div>
                                    </div>
                                    <div className="flex-50">
                                        <div className="col-wrap">
                                            <div className="label-title">Nama Pengirim</div>
                                            <div className="label-desc">{repo.data.shipping_label.shipper_name}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex border-line">
                                    <div className="flex-50">
                                        <div className="col-wrap">
                                            <div className="label-title">Alamat Penerima</div>
                                            <div className="label-desc">{repo.data.shipping_address}</div>
                                        </div>
                                    </div>
                                    <div className="flex-50">
                                        <div className="col-wrap">
                                            <div className="label-title">Alamat Pengirim</div>
                                            <div className="label-desc">{repo.data.shipping_label.shipper_address}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex border-line">
                                    <div className="flex-50">
                                        <div className="col-wrap">
                                            <div className="label-title">Nomor Telpon Penerima</div>
                                            <div className="label-desc">{repo.data.phone}</div>
                                        </div>
                                    </div>
                                    <div className="flex-50">
                                        <div className="col-wrap">
                                            <div className="label-title">Nomor Telpon Pengirim</div>
                                            <div className="label-desc">{repo.data.shipping_label.shipper_phone}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="border-line">
                                    <div className="col-wrap">
                                        <div className="label-title">Catatan</div>
                                        <div className="label-desc">{repo.data.notes_customer}</div>
                                    </div>
                                </div>
                                <div className="border-line mb-5">
                                    <div className="col-wrap">
                                        <div className="label-title">Detail Barang</div>
                                        {repo.data.product.data.map((repo) => {
                                            return (
                                                <div className="d-flex justify-content-between">
                                                    <div className="label-desc">{repo.name}</div>
                                                    <div className="label-desc">{repo.quantity}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        }
    }
    return ( 
        <section id="dashboard-wrapper">
            <div className="container-wrapper">
                <Sidebar />
                <section id="main-dashboard">
                    <Header /> 
                    <div className="content-wrapper tab-header">
                        <input defaultChecked="checked" id="tab1" type="radio" name="label" value="any" onChange={() => onChangeLabel({ pageLabel: 'any'})}/>
                        <input id="tab2" type="radio" name="label" value="success" onChange={() => onChangeLabel({ pageLabel: 'success'})}/>
                        <input id="tab3" type="radio" name="label" value="error"  onChange={() => onChangeLabel({ pageLabel: 'error'})}/>
                        <div className="tab-bar">
                            <label htmlFor="tab1" className="tab-bar-item tab1" name="label"><span className="tab-bar-name">Request AWB</span></label>
                            <label htmlFor="tab2" className="tab-bar-item tab2" name="label"><span className="tab-bar-name">Print Label</span></label>
                            <label htmlFor="tab3" className="tab-bar-item tab3" name="label"><span className="tab-bar-name">Error</span></label>
                        </div>
                        <div className="main-dash">
                            <div className="toolbar toolbar-awb">
                                <div className="toolbar-left" style={{flex: '0 0 20%'}}>
                                    <Formik
                                        initialValues={{ search: ''}}
                                        onSubmit={onChangeSearch}
                                    >
                                        {(formik) => {
                                        const {
                                        } = formik;
                                        return (
                                        <Form>
                                            <div className="form-group search-input">
                                                <label htmlFor="inputSearch" className="sr-only">Search</label>
                                                <input type="text" className="form-control" id="inputSearch" placeholder="Cari Pesanan" value={search} onChange={changeSearch} />
                                                <button className="btn btn-search" onClick={onChangeSearch}><img src="/img/ico-search.svg" alt="search" /></button>
                                            </div>
                                        </Form>
                                        );
                                        }}
                                    </Formik>
                                </div>
                                <div className="toolbar-right" style={{flex: '0 0 80%'}}>
                                    <div className="toolbar-inner-left" style={{flex: '0 0 auto'}}>
                                        <div className="toolicon-wrapper">
                                            <button id="show" className="toolicon sort" />
                                            <div id="test" className="tool-inner">
                                                <div className="tool-wrapper">
                                                    <div className="tool-heading">Urutkan</div>
                                                    <ul className="tool-select">
                                                        <button name='sorting' value='asc' onClick={onChangeSort} className = {sorting == 'asc' ? "tool-select-item active": "tool-select-item"}>Nama User A-Z</button>
                                                        <button name='sorting' value='desc' onClick={onChangeSort} className = {sorting == 'desc' ? "tool-select-item active": "tool-select-item"}>Nama User Z-A</button>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="toolicon-wrapper">
                                            <button id="show" className="toolicon filter" />
                                            <div className="tool-inner">
                                                <div className="tool-wrapper">
                                                    <div className="tool-heading">Filter Expedisi</div>
                                                    <div className="tool-scroll">
                                                        <ul className="tool-select">
                                                            <button name='expedition' value='jnt' onClick={onChangeExpedition} className = {expedition == 'jnt' ? "tool-select-item active": "tool-select-item"}><img src="/img/ico-ex-jnt.jpg" alt="J&T" />J&amp;T Express</button>
                                                            <button name='expedition' value='jne' onClick={onChangeExpedition} className = {expedition == 'jne' ? "tool-select-item active": "tool-select-item"}><img src="/img/ico-ex-jne.jpg" alt="JNE" />JNE Express</button>
                                                            <button name='expedition' value='sicepat' onClick={onChangeExpedition} className = {expedition == 'sicepat' ? "tool-select-item active": "tool-select-item"}><img src="/img/ico-ex-sicepat.jpg" alt="Sicepat" />Sicepat Express</button>
                                                            <button name='expedition' value='rpx' onClick={onChangeExpedition} className = {expedition == 'rpx' ? "tool-select-item active": "tool-select-item"}><img src="/img/rpx.png" alt="RPX" className="img-courir" />RPX One Stop Logistics</button>
                                                            <button name='expedition' value='pos' onClick={onChangeExpedition} className = {expedition == 'pos' ? "tool-select-item active": "tool-select-item"}><img src="/img/pos.png" alt="RPX" className="img-courir" />POS Indonesia</button>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tool-daterange daterange">
                                            <DateRangePicker
                                                ref={dateRangePickerRef}
                                                initialSettings={{
                                                    autoUpdateInput: false,
                                                    locale: {
                                                    cancelLabel: 'Clear',
                                                    },
                                                    maxDate : new Date().toLocaleDateString("en-US")
                                                }}
                                                autoUpdateInput={true}
                                                startDate={startDate}
                                                endDate={endDate}
                                                locale={{ format: "YYYY-MM-DD" }} 
                                                onCancel={handleCancel}
                                                onApply={onChangeDate}>
                                                <input type="text" className="form-control" value={labelDate} onChange={onChangeDate} placeholder="Tanggal Mulai - Tanggal Akhir"/>
                                            </DateRangePicker>
                                        </div>
                                        <div className="page-item">
                                            <form className="page-input">
                                                <div className="form-group mb-0">
                                                    <label htmlFor="page-count" className="sr-only">Page</label>
                                                    <input type="text" className="form-control" id="page-count" value={limit} onChange={onChangeLimit} />
                                                </div>
                                            </form>
                                            <div className="text-page">/ Page</div>
                                        </div>
                                        <div className="tool-export">
                                            <ReactHTMLTableToExcel
                                                id="test-table-xls-button"
                                                className="btn btn-secondary"
                                                table="table-to-xls"
                                                filename={"orders-"+dateNow+"-"+timeNow}
                                                sheet="Orders"
                                                buttonText="Export"/>
                                        </div>
                                        <div className="tool-clear">
                                            <button className="btn btn-secondary" onClick={clearFilter}>Clear</button>
                                        </div>
                                    </div>
                                    <div className="toolbar-inner-right justify-content-end" style={{flex: 'auto'}}>
                                    {(() => {
                                        if (appState.pageLabel == 'any' || appState.pageLabel == '') {
                                            return  <div>
                                                        <button onClick={UpdateRequestAWB} disabled={appState.disabled} className="btn btn-primary btn-icon"><i className="icon-resi" />{appState.disabled ? "Loading..." : "Request AWB"}</button>
                                                    </div>;
                                        } else if (appState.pageLabel == 'error') {
                                            return  <div div className="btn-two">
                                                        <button className="btn btn-blue btn-icon mr-2" onClick={MoveLabel}><i className="icon-print" /> Pindahkan ke Label</button>
                                                        <button onClick={UpdateRequestAWB} disabled={appState.disabled} className="btn btn-primary btn-icon"><i className="icon-resi" />{appState.disabled ? "Loading..." : "Request AWB"}</button>
                                                    </div>;
                                        } else if (appState.pageLabel == 'success') {
                                            return  <div>
                                                        <button className="btn btn-blue btn-icon" onClick={openPrint}><i className="icon-print" /> Print Label</button>
                                                    </div>
                                        }
                                    })()}
                                     <div id="popup-print" className="overlay">  
                                        {(() => {
                                        if (appState.detail) {
                                        return  <div className="popup">
                                                <Modal show={show} >
                                                    <button className="close hide-on-print" onClick={closePrint}><img src="/img/ico-close.svg" alt="close" /></button>        
                                                    <div className="content">
                                                        <div className="label-wrapper">
                                                            <ComponentToPrint ref={componentRef} />
                                                            <div className="d-flex align-items-center justify-content-between mt-4">
                                                                <button className="btn btn-blue btn-icon hide-on-print" onClick={printResi}><i className="icon-print" /> Pindah Surat Jalan</button>
                                                                <ReactToPrint
                                                                trigger={() =>  <button className="btn btn-blue btn-icon hide-on-print"><i className="icon-print" /> Print Label</button>}
                                                                content={() =>componentRef.current}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Modal>
                                                </div>;
                                        } 
                                        })()}
                                    </div> {/* end of overlay */}

                                    
                                    </div>
                                </div>
                            </div>{/* end of toolbar*/}
                            <ListLoading isLoading={appState.loading} data={appState.repos} total={appState.total} to={appState.to} current_page={appState.current_page} save={(e)=>save(e)} set_page={(e)=>set_page(e)} label={label} pageLabel={appState.pageLabel} />

                        </div>{/* end of main-dash*/}
                    </div>
                </section>
            </div>
        </section>
    );
}

export default RequestAWB;