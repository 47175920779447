import React from 'react';
import './style.css';

function Loading(Component) {
  return function WihLoadingComponent({ isLoading, ...props }) {
    if (!isLoading) return <Component {...props} />;
    return (
      <div className="credential">
        <div className="credential-box-noborder">
          <div className="load-2">
            <p>Loading... Please wait</p>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      </div>
    );
  };
}
export default Loading;