import React, { useEffect, useState, useRef } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import swal from 'sweetalert';
import { Formik, Form } from 'formik';
import axios from 'axios';
import { authHeader } from '../../_helpers';
import { API_STAGING } from '../../_settings'
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import List from './List';
import Loading from '../../components/Loading';
import {useHistory, useLocation} from 'react-router';

function OrderList() {
    const ListLoading                 = Loading(List);
    const location = useLocation()
    const history = useHistory()
    const [search, setSearchTerm] = useState('');
    const [limit, setLimit]           = useState('10');
    const [sorting, setSorting]       = useState('');
    const [expedition, setExpedition] = useState('');
    const [startDate, setStartDate]   = useState(location.state?.start_date ?? '');
    const [endDate, setEndDate]       = useState(location.state?.end_date ?? '');
    const dateRangePickerRef          = useRef(null);
    const [selectedPage, setPage]     = useState('');
    const [appState, setAppState]     = useState({
        loading: false,
        data: null,
        status: 'Pilihan Status',
        disabled: false,
        pageStatus: 'any',
        repos: null,
        dat: null,
        total: null,
        to:null,
        product: null,
        current_page: null
    });
    const cityId = location.state?.city_id ?? ''
    const handleClass = status => appState.status === status ? "tool-select-item active" : "tool-select-item";
    const onChangeStatus = (event) => {
        const pageStatus = event.pageStatus
        setAppState({ loading: true, pageStatus: event.pageStatus, status: appState.status });
        if (sorting == "asc" || sorting == "desc"){
            axios.post(`${API_STAGING}order/listing?status=${pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=1&export-data=yes`, {
                //...data
              },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ pageStatus: event.pageStatus, loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status });
               
                console.log(appState)
    
            });
        } else {
            axios.post(`${API_STAGING}order/listing?status=${pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=1&export-data=yes`, {
                //...data
              },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ pageStatus: event.pageStatus, loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status });
               
                console.log(appState)
    
            });
        }
    }
    const onChangeTime = (event) => {
        const pageStatus = event.pageStatus
        setAppState({ loading: true, pageStatus: event.pageStatus, status: appState.status });
        if (sorting == "asc" || sorting == "desc"){
            axios.post(`${API_STAGING}order/listing?processing-status=${pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: event.pageStatus });
            });
        } else {
            axios.post(`${API_STAGING}order/listing?processing-status=${pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=${selectedPage}&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: event.pageStatus });
            });
        }
    }
    const changeSearch = (e) => {
        const value = e.target.value
        setSearchTerm(value)
    }
    const onChangeSearch = (event) => {
        const data = {
            search: search,
        }
        setAppState({ loading: true, pageStatus: appState.pageStatus, status: appState.status });
        if (appState.pageStatus == "late"){
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?processing-status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus  });
                });
            } else {
                axios.post(`${API_STAGING}order/listing?processing-status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus  });
                });
            }
        } else {
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus  });
                });
            } else {
                axios.post(`${API_STAGING}order/listing?status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus  });
                });
            }
        }
    }
    const onChangeLimit = (event) => {
        const limit = event.target.value
        setLimit(limit);
        setAppState({ loading: true, pageStatus: appState.pageStatus, status: appState.status });
        if (appState.pageStatus == "late"){
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?processing-status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus  });
                });
            } else {
                axios.post(`${API_STAGING}order/listing?processing-status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus  });
                });
            }
        } else {
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus  });
                });
            } else {
                axios.post(`${API_STAGING}order/listing?status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus  });
                });
            }
        }
    }
    const onChangeSort = event =>  {
        const sorting = event.target.value
        setSorting(sorting);
        setAppState({ loading: true, pageStatus: appState.pageStatus, status: appState.status });
        if (appState.pageStatus == "late"){
            axios.post(`${API_STAGING}order/listing?processing-status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                //...data
              },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus  });
            });
        } else {
            axios.post(`${API_STAGING}order/listing?status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                //...data
              },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus  });
            });
        }
    }
    const onChangeExpedition = event =>  {
        const expedition = event.target.value
        setExpedition(expedition);
        setAppState({ loading: true, pageStatus: appState.pageStatus, status: appState.status });
        if (appState.pageStatus == "late"){
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?processing-status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus});
                });
            } else {
                axios.post(`${API_STAGING}order/listing?processing-status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus});
                });
            }
        } else {
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus});
                });
            } else {
                axios.post(`${API_STAGING}order/listing?status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus});
                });
            }
        }
    }
    function onChangeDate (event,picker) {
        setAppState({loading: true, pageStatus: appState.pageStatus, status: appState.status});
        const startDate = picker.startDate.format("YYYY-MM-DD")
        setStartDate(startDate);
        const endDate =  picker.endDate.format("YYYY-MM-DD")
        setEndDate(endDate);
        if (appState.pageStatus == "late"){
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?processing-status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus });
                });
            } else {
                axios.post(`${API_STAGING}order/listing?processing-status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus });
                });
            }
        } else {
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus });
                });
            } else {
                axios.post(`${API_STAGING}order/listing?status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus });
                });
            }
        }
    }
    const handleCancel = (event, picker) => {
        setAppState({loading: true, pageStatus: appState.pageStatus, status: appState.status });
        if (dateRangePickerRef.current) {
            const today = new Date();
            dateRangePickerRef.current.setStartDate(today);
            dateRangePickerRef.current.setEndDate(today);
        }
        const startDate = ''
        setStartDate(startDate);
        const endDate =  ''
        setEndDate(endDate);
        if (appState.pageStatus == "late"){
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?processing-status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus });
                });
            } else {
                axios.post(`${API_STAGING}order/listing?processing-status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus });
                });
            }
        } else {
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus });
                });
            } else {
                axios.post(`${API_STAGING}order/listing?status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus });
                });
            }
        }
    }
    function clearFilter () {
        setAppState({loading: true, pageStatus: appState.pageStatus, status: appState.status });
        const search = ''
        setSearchTerm(search);
        const sorting = ''
        setSorting(sorting);
        const expedition = ''
        setExpedition(expedition);
        const startDate = ''
        setStartDate(startDate);
        const endDate =  ''
        setEndDate(endDate);
        let cityId = ''
        history.replace(location.pathname, undefined)
        if (appState.pageStatus == "late"){
            axios.post(`${API_STAGING}order/listing?processing-status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                //...data
              },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus });
            });
        } else {
            axios.post(`${API_STAGING}order/listing?status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                //...data
              },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, status: appState.status, pageStatus: appState.pageStatus });
            });
        }
    }
    let start = startDate
    let end = endDate
    let labelDate = start + ' - ' + end;
    if (start === end) {
        labelDate = start;
    }
    let today = new Date();
    let dateNow = today.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'numeric', year: 'numeric'
      }).replace(/[^\d,.-]/g,'-');     
    let timeNow = today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();       
    function EditStatus() {
        appState.repos.forEach((result,index) =>{
            if(appState.repos[index].isChecked==true){
                setAppState({loading: true, status:appState.status, pageStatus:appState.pageStatus, disabled: true});
                    axios.post(`${API_STAGING}order/status?id=${appState.repos[index].id}&status=${appState.status}`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.message;
                    swal({
                        title: "Done!",
                        text: data,
                        icon: "success",
                        timer: 3000,
                        button: false
                    })
                    setAppState({ loading: false,status:appState.status, total: appState.total, current_page: appState.current_page, to: appState.to,pageStatus: appState.pageStatus });
                    onChangeStatus({ pageStatus: appState.pageStatus})
                });
            }
        })
    }
    function set_page(e){
        const selectedPage = e
        setPage(selectedPage);
        setAppState({ loading: true, status: appState.status });
        if (appState.pageStatus == "late"){
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?processing-status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=${selectedPage}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageStatus: appState.pageStatus});
                });
            } else {
                axios.post(`${API_STAGING}order/listing?processing-status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=${selectedPage}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageStatus: appState.pageStatus});
                });
            }
        } else {
            if (sorting == "asc" || sorting == "desc"){
                axios.post(`${API_STAGING}order/listing?status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=${selectedPage}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageStatus: appState.pageStatus});
                });
            } else {
                axios.post(`${API_STAGING}order/listing?status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=${selectedPage}&export-data=yes`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.data;
                    setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageStatus: appState.pageStatus});
                });
            }
        }
    }
    useEffect(() => {
        setAppState({ loading: true, status: appState.status, pageStatus: appState.pageStatus });
        axios.post(`${API_STAGING}order/listing?status=${appState.pageStatus}&search=${search}&per_page=${limit}&sorting=${sorting}&city=${cityId}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=1&export-data=yes`, {
            //...data
          },  {
            headers: authHeader()
        })
        .then((repos) => {
            const data = repos.data.data;
            setAppState({ loading: false, repos: data.data, total: data.total, status:appState.status, current_page: data.current_page, to: data.to, pageStatus: appState.pageStatus });
          });
      }, [setAppState]);

    function save(e){
        setAppState({dat: e, repos: appState.repos, total: appState.total, status:appState.status, pageStatus:appState.pageStatus})
    }
    return ( 
        <section id="dashboard-wrapper">
            <div className="container-wrapper">
                <Sidebar />
                <section id="main-dashboard">
                    <Header />
                    <div className="content-wrapper tab-header">
                        <input defaultChecked="checked" id="tab1" type="radio" name="status" value="any" onChange={() => onChangeStatus({ pageStatus: 'any'})}/>
                        <input id="tab2" type="radio" name="status" value="pending" onChange={() =>onChangeStatus({ pageStatus: 'pending'})}/>
                        <input id="tab3" type="radio" name="status" value="on-hold" onChange={() => onChangeStatus({ pageStatus: 'on-hold'})}/>
                        <input id="tab4" type="radio" name="status" value="processing" onChange={() => onChangeStatus({ pageStatus: 'processing'})}/>
                        <input id="tab5" type="radio" name="status" value="late" onChange={() =>onChangeTime({ pageStatus: 'late'})}/>
                        <input id="tab6" type="radio" name="status" value="packing" onChange={() =>onChangeStatus({ pageStatus: 'packing'})}/>
                        <input id="tab7" type="radio" name="status" value="on-delivery" onChange={() =>onChangeStatus({ pageStatus: 'on-delivery'})}/>
                        <input id="tab8" type="radio" name="status" value="completed" onChange={() =>onChangeStatus({ pageStatus: 'completed'})}/>
                        <input id="tab9" type="radio" name="status" value="cancelled" onChange={() =>onChangeStatus({ pageStatus: 'cancelled'})}/>
                        <div className="tab-bar">
                            <label htmlFor="tab1" className="tab-bar-item tab1" name="status"><span className="tab-bar-name">Semua</span></label>
                            <label htmlFor="tab2" className="tab-bar-item tab2" name="status"><span className="tab-bar-name">Pending Payment</span></label>
                            <label htmlFor="tab3" className="tab-bar-item tab3" name="status"><span className="tab-bar-name">Belum Dibayar</span></label>
                            <label htmlFor="tab4" className="tab-bar-item tab4" name="status"><span className="tab-bar-name">Perlu Diproses</span></label>
                            <label htmlFor="tab5" className="tab-bar-item tab5" name="status"><span className="tab-bar-name">Terlambat</span></label>
                            <label htmlFor="tab6" className="tab-bar-item tab6" name="status"><span className="tab-bar-name">Dikemas</span></label>
                            <label htmlFor="tab7" className="tab-bar-item tab7" name="status"><span className="tab-bar-name">Dikirim</span></label>
                            <label htmlFor="tab8" className="tab-bar-item tab8" name="status"><span className="tab-bar-name">Selesai</span></label>
                            <label htmlFor="tab9" className="tab-bar-item tab9" name="status"><span className="tab-bar-name">Dibatalkan</span></label>
                        </div>
                        <div className="main-dash">
                            <div className="toolbar toolbar-order">
                                <div className="toolbar-left">
                                    <Formik
                                        initialValues={{ search: ''}}
                                        onSubmit={onChangeSearch}
                                    >
                                        {(formik) => {
                                        const {
                                        } = formik;
                                        return (
                                        <Form>
                                            <div className="form-group search-input">
                                                <label htmlFor="inputSearch" className="sr-only">Search</label>
                                                <input type="text" className="form-control" id="inputSearch" placeholder="Cari Pesanan" value={search} onChange={changeSearch} />
                                                <button className="btn btn-search" onClick={onChangeSearch}><img src="/img/ico-search.svg" alt="search" /></button>
                                            </div>
                                        </Form>
                                        );
                                        }}
                                    </Formik>
                                </div>
                                <div className="toolbar-right">
                                    <div className="toolbar-inner-left">
                                        <div className="toolicon-wrapper">
                                            <button id="show" className="toolicon sort" />
                                            <div id="test" className="tool-inner">
                                                <div className="tool-wrapper">
                                                    <div className="tool-heading">Urutkan</div>
                                                    <ul className="tool-select">
                                                        <button name='sorting' value='asc' onClick={onChangeSort} className = {sorting == 'asc' ? "tool-select-item active": "tool-select-item"}>Nama User A-Z</button>
                                                        <button name='sorting' value='desc' onClick={onChangeSort} className = {sorting == 'desc' ? "tool-select-item active": "tool-select-item"}>Nama User Z-A</button>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="toolicon-wrapper">
                                            <button id="show" className="toolicon filter" />
                                            <div className="tool-inner">
                                                <div className="tool-wrapper">
                                                    <div className="tool-heading">Filter Expedisi</div>
                                                    <div className="tool-scroll">
                                                        <ul className="tool-select">
                                                            <button name='expedition' value='jnt' onClick={onChangeExpedition} className = {expedition == 'jnt' ? "tool-select-item active": "tool-select-item"}><img src="/img/ico-ex-jnt.jpg" alt="J&T" />J&amp;T Express</button>
                                                            <button name='expedition' value='jne' onClick={onChangeExpedition} className = {expedition == 'jne' ? "tool-select-item active": "tool-select-item"}><img src="/img/ico-ex-jne.jpg" alt="JNE" />JNE Express</button>
                                                            <button name='expedition' value='sicepat' onClick={onChangeExpedition} className = {expedition == 'sicepat' ? "tool-select-item active": "tool-select-item"}><img src="/img/ico-ex-sicepat.jpg" alt="Sicepat" />Sicepat Express</button>
                                                            <button name='expedition' value='rpx' onClick={onChangeExpedition} className = {expedition == 'rpx' ? "tool-select-item active": "tool-select-item"}><img src="/img/rpx.png" alt="RPX" className="img-courir" />RPX One Stop Logistics</button>
                                                            <button name='expedition' value='pos' onClick={onChangeExpedition} className = {expedition == 'pos' ? "tool-select-item active": "tool-select-item"}><img src="/img/pos.png" alt="RPX" className="img-courir" />POS Indonesia</button>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tool-daterange daterange">
                                            <DateRangePicker
                                                ref={dateRangePickerRef}
                                                initialSettings={{
                                                    autoUpdateInput: false,
                                                    locale: {
                                                    cancelLabel: 'Clear',
                                                    },
                                                    maxDate : new Date().toLocaleDateString("en-US"),
                                                    startDate: startDate ? moment(startDate, 'YYYY-MM-DD') : undefined,
                                                    endDate: endDate ? moment(endDate, 'YYYY-MM-DD') : undefined,
                                                }}
                                                autoUpdateInput={true}
                                                startDate={moment(startDate, 'YYYY-MM-DD')}
                                                endDate={moment(endDate, 'YYYY-MM-DD')}
                                                locale={{ format: "YYYY-MM-DD" }} 
                                                onCancel={handleCancel}
                                                onApply={onChangeDate}>
                                                <input type="text" className="form-control" value={labelDate} onChange={onChangeDate} placeholder="Tanggal Mulai - Tanggal Akhir"/>
                                            </DateRangePicker>
                                        </div>
                                        <div className="page-item">
                                            <form className="page-input">
                                                <div className="form-group mb-0">
                                                    <label htmlFor="page-count" className="sr-only">Page</label>
                                                    <input type="text" className="form-control" id="page-count" value={limit} onChange={onChangeLimit} />
                                                </div>
                                            </form>
                                            <div className="text-page">/ Page</div>
                                        </div>
                                        <div className="tool-export">
                                            <ReactHTMLTableToExcel
                                                id="test-table-xls-button"
                                                className="btn btn-secondary"
                                                table="table-to-xls"
                                                filename={"orders-"+dateNow+"-"+timeNow}
                                                sheet="Orders"
                                                buttonText="Export"/>
                                        </div>
                                        <div className="tool-clear">
                                            <button className="btn btn-secondary" onClick={clearFilter}>Clear</button>
                                        </div>
                                    </div>
                                    <div className="toolbar-inner-right">
                                        <div className="toolicon-wrapper status">
                                            <button id="show" className="toolicon status"><span>{appState.status}</span></button>
                                            <div className="tool-inner">
                                                <div className="tool-wrapper">
                                                    <div className="tool-heading">Status Pesanan</div>
                                                    <ul className="tool-select">
                                                        <ul className="tool-select">
                                                            <li onClick={() => setAppState({ status: 'on-hold',repos:appState.repos,total:appState.total, pageStatus:appState.pageStatus})} className={handleClass('on-hold')}><span className="label-status grey">On Hold</span>Menunggu Pembayaran</li>
                                                            <li onClick={() => setAppState({ status: 'pending',repos:appState.repos,total:appState.total, pageStatus:appState.pageStatus})} className={handleClass('pending')}><span className="label-status orange">Pending</span>Konfirmasi Ketersediaan</li>
                                                            <li onClick={() => setAppState({ status: 'processing',repos:appState.repos,total:appState.total, pageStatus:appState.pageStatus})} className={handleClass('processing')}><span className="label-status green">Processing</span>Sedang Diproses</li>
                                                            <li onClick={() => setAppState({ status: 'packing',repos:appState.repos,total:appState.total, pageStatus:appState.pageStatus})} className={handleClass('packing')}><span className="label-status green">Packing</span>Sedang Dikemas</li>
                                                            <li onClick={() => setAppState({ status: 'on-delivery',repos:appState.repos,total:appState.total, pageStatus:appState.pageStatus})} className={handleClass('on-delivery')}><span className="label-status green">On Delivery</span>Dalam Ekspedisi</li>
                                                            <li onClick={() => setAppState({ status: 'completed',repos:appState.repos,total:appState.total, pageStatus:appState.pageStatus})} className={handleClass('completed')}><span className="label-status blue">Completed</span>Selesai</li>
                                                            <li onClick={() => setAppState({ status: 'refunded',repos:appState.repos,total:appState.total, pageStatus:appState.pageStatus})} className={handleClass('refunded')}><span className="label-status red">Refunded</span>Dikembalikan</li>
                                                            <li onClick={() => setAppState({ status: 'cancelled',repos:appState.repos,total:appState.total, pageStatus:appState.pageStatus})} className={handleClass('cancelled')}><span className="label-status red">Cancelled</span>Dibatalkan</li>
                                                        </ul>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                        {(() => {
                                                    if (appState.status == 'Pilihan Status') {
                                                        return <button onClick={EditStatus} disabled className="btn btn-primary">Ubah</button>;
                                                    } else {
                                                        return <button onClick={EditStatus} disabled={appState.disabled} className="btn btn-primary">{appState.disabled ? "Loading..." : "Ubah"}</button>;
                                                    }
                                            })()}     
                                        </div>
                                    </div>
                                </div>
                            </div>{/* end of toolbar*/}
                            <ListLoading isLoading={appState.loading} data={appState.repos} total={appState.total} to={appState.to} current_page={appState.current_page} save={(e)=>save(e)} set_page={(e)=>set_page(e)} pageStatus={appState.pageStatus} />
                            
                        </div>{/* end of main-dash*/}
                    </div>
                </section>
            </div>
        </section>
    );
}


export default OrderList;
