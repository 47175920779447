import React,{ useState } from 'react';
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';
import ReactPaginate from 'react-paginate';
const List = (props) => {
  const { data,total,save,set_page,current_page,to, pageStatus } = props;
    const [appState, setAppState]  = useState({
        dataState:data
        });
    
    const changePage = (e) => {
        const selectedPage = e.selected + 1;
        set_page(selectedPage)
        console.log(`active page is ${selectedPage}`);
    }

    const handleAllChecked = (event) => {
        appState.dataState.forEach((result,index) =>{
            appState.dataState[index].isChecked= event.target.checked
            console.log(appState.dataState[index].isChecked)
        })
        setAppState({dataState:appState.dataState });
    }

    const handleCheckChieldElement = (e) => {
        console.log(appState.dataState[e].id)
    
        appState.dataState[e].isChecked = !appState.dataState[e].isChecked
        console.log(appState.dataState)
        setAppState({dataState:appState.dataState });
    
    }
    let today = new Date();
    let dateNow = today.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'numeric', year: 'numeric'
      }).replace(/[^\d,.-]/g,'-');     
    let timeNow = today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
    
  if (!data || data.length === 0) return <p style={{textAlign: 'center', marginTop: 120, marginBottom: 120}}>No data order, sorry</p>;
  return (
    <div>        
        <div className="result">Total <strong>{total} Data</strong> Pesanan</div>
        <table id="table-to-xls" className="hide-table">
            <thead>
            <tr>
                <th>ID Order</th>
                <th>Status Pesanan</th>
                <th>Referral</th>
                <th>Tanggal Order</th>
                <th>AWB</th>
                <th>Payment Method</th>
                <th>Shipping Method</th>
                <th>Pesanan Harus Dikirimkan Sebelum (Menghindari keterlambatan)</th>
                <th>Waktu Pengiriman</th>
                <th>Waktu Pesanan Selesai</th>
                <th>Voucher Code</th>
                <th>Shipping Price</th>
                <th>Catatan</th>
                <th>Email (Pembeli)</th>
                <th>Nama Penerima</th>
                <th>No. Telepon</th>
                <th>Alamat Pengiriman</th>
                <th>Cashback</th>
                <th>Tax</th>
                <th>Grand Total</th>
                <th>Product ID</th>
                <th>Nama Product</th>
                <th>Harga Product</th>
                <th>Qty</th>
                <th>Subtotal</th>
                <th>Product Tax</th>
                <th>Total with Tax</th>
            </tr>
            </thead>
            <tbody>
            {appState.dataState.map(item => (
                <tr>
                    <td rowspan={item.product.total}>{item.invoice_number}</td>
                    <td rowspan={item.product.total}>{item.status}</td>
                    <td rowspan={item.product.total}>{item.export_data.referral}</td>
                    <td rowspan={item.product.total}>{item.created_at}</td>
                    <td rowspan={item.product.total}>{item.export_data.awb}</td>
                    <td rowspan={item.product.total}>{item.payment_method}</td>
                    <td rowspan={item.product.total}>{item.export_data.shipping_method}</td>
                    <td rowspan={item.product.total}>{item.status_time.expired_alt}</td>
                    <td rowspan={item.product.total}>{item.status_time.['on-delivery']}</td>
                    <td rowspan={item.product.total}>{item.status_time.completed}</td>
                    <td rowspan={item.product.total}>{item.export_data.voucher_code}</td>
                    <td rowspan={item.product.total}>{item.export_data.shipping_price}</td>
                    <td rowspan={item.product.total}>{item.notes}</td>
                    <td rowspan={item.product.total}>{item.customer_email}</td>
                    <td rowspan={item.product.total}>{item.customer_name}</td>
                    <td rowspan={item.product.total}>{item.customer_phone}</td>
                    <td rowspan={item.product.total}>{item.customer_address}</td>
                    <td rowspan={item.product.total}>{item.export_data.cashback}</td>
                    <td rowspan={item.product.total}>{item.export_data.tax}</td>
                    <td rowspan={item.product.total}>{item.total_price_text}</td>
                    {item.product.data.map((repo, index) => {
                        return (
                            <div>
                            {(() => {
                            if (index==0) {
                                return <div >
                                    <td>{repo.product_id}</td>
                                    <td>{repo.name}</td>
                                    <td>{repo.total}</td>
                                    <td>{repo.quantity}</td>
                                    <td>{repo.subtotal}</td>
                                    <td>{repo.total_tax}</td>
                                    <td>{repo.total}</td>
                                </div>;
                             } else {
                                return <tr>
                                <td>{repo.product_id}</td>
                                <td>{repo.name}</td>
                                <td>{repo.total}</td>
                                <td>{repo.quantity}</td>
                                <td>{repo.subtotal}</td>
                                <td>{repo.total_tax}</td>
                                <td>{repo.total}</td>
                            </tr>;
                             }
                            })()}
                            </div>
                        );
                    })}
                </tr>
            ))}
            </tbody>
        </table>
        <div className="table-data">
            <div className="table-head">
                <div className="th-order-01">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="customCheck1" onClick={handleAllChecked} value="checkedall" />
                        <label className="custom-control-label" htmlFor="customCheck1" />
                    </div>
                </div>
                <div className="th-order-02">Product</div>
                <div className="th-order-03">Jumlah</div>
                <div className="th-order-04">Alamat</div>
                <div className="th-order-05">Status</div>
                <div className="th-order-06">Hitung Mundur</div>
            </div>
            {appState.dataState.map((repo,index) => {
                return (
                    <div className="table-body" key={index}>
                            <div className="table-body-top">
                                <div className="table-body-top-left">
                                    <div className="tb-bt-01">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id={index} checked={repo.isChecked} onChange={(e)=>handleCheckChieldElement(index)}/>
                                            <label className="custom-control-label" htmlFor={index} />
                                        </div>
                                    </div>
                                    <div className="tb-bt-02">
                                        <img src="/img/ico-user.svg" alt="user" className="image-order" />
                                    </div>
                                    <div className="tb-bt-03"><div className="meta-invoice"><Link to={`/order/detail/${repo.id}`}>{repo.invoice_number}</Link></div></div>
                                    <div className="tb-bt-04"><div className="meta-customer">{repo.customer_name}</div></div>
                                </div>
                                <div className="table-body-top-right">
                                    <div className="meta-date">{repo.created_at}</div>
                                </div>
                            </div>{/* end of table-body-top */}
                            <div className="table-body-btm">
                                <div className="table-body-btm-left">
                                    <div className="table-body-btm-left-inner">
                                        <div className="product-image"><img src={repo.product.data[0].image} alt={repo.product.data[0].name} /></div>
                                        <div className="product-detail">
                                            <div className="product-title">{repo.product.data[0].name}</div>
                                            {(() => {
                                                if (repo.product.total > 1) {
                                                    return <div className="product-more">dan {repo.product.total-1} produk lainnya</div>;
                                                }else {
                                                    return <div className="product-more">{repo.product.total} produk</div>;
                                                }
                                            })()}
                                        </div>
                                    </div>
                                    <div className="product-qty">x{repo.product.data[0].quantity}</div>
                                </div>{/* end of table-body-btm-left */}
                                <div className="table-body-btm-mid">
                                    <div className="order-total">{repo.total_price_text},-</div>
                                    <div className="order-address">{repo.customer_address}</div>
                                </div>{/* end of table-body-btm-mid */}
                                <div className="table-body-btm-right">
                                    <div className="order-status">
                                    {(() => {
                                    if (repo.status == 'processing' || repo.status == 'on-delivery') {
                                        return <span className="label-status green">{repo.status}</span>;
                                    } else if (repo.status == 'cancelled' || repo.status == 'failed') {
                                        return <span className="label-status red">{repo.status}</span>;
                                    } else if (repo.status == 'completed' || repo.status =='packing') {
                                        return <span className="label-status blue">{repo.status}</span>;
                                    } else if (repo.status == 'on-hold') {
                                        return <span className="label-status orange">{repo.status}</span>;
                                    } else if (repo.status == 'pending' || repo.status == 'refunded') {
                                        return <span className="label-status grey">{repo.status}</span>;
                                    }    
                                    })()}
                                    </div>
                                    {(() => {
                                    if (repo.status == 'processing' || repo.status == 'packing') {
                                        if (repo.processing_time == '' || repo.processing_time.status == 'on-time') {
                                            return <div className="order-time">
                                                <Countdown
                                                    date={Date.parse(repo.status_time.expired)}
                                                    intervalDelay={0}
                                                    precision={3}
                                                    renderer={(props) => (
                                                    <div className="order-timer">
                                                        {props.days}d : {props.hours}h : {props.minutes}m : {props.seconds}s
                                                    </div>
                                                    )}
                                                />            
                                                <div className="order-time-update">
                                                    Mohon dikirim sebelum<br /><strong>{repo.status_time.expired_alt}</strong>
                                                </div>
                                            </div>;
                                        } else if (repo.processing_time.status == 'late') {
                                            return <div className="order-time"> 
                                                        <div className="order-timer">Terlambat</div>
                                                        <div className="order-time-update">-</div>
                                                    </div>;
                                        } 
                                    } else if (repo.status == 'cancelled' || repo.status == 'failed') {
                                        return <div className="order-time">
                                            <div className="order-timer">Dibatalkan</div>
                                            <div className="order-time-update">-</div>
                                        </div>;
                                    } else if (repo.status == 'on-delivery') {
                                        return <div className="order-time">
                                            <div className="order-timer">Dikirim</div>
                                            <div className="order-time-update">-</div>
                                        </div>;
                                    } else if (repo.status == 'pending' || repo.status == 'on-hold') {
                                        return <div className="order-time">
                                            <div className="order-timer">Tertahan</div>
                                            <div className="order-time-update">-</div>
                                        </div>;
                                    } else if (repo.status == 'refunded') {
                                        return <div className="order-time">
                                            <div className="order-timer">Dikembalikan</div>
                                            <div className="order-time-update">-</div>
                                        </div>;
                                    } else if (repo.status == 'completed') {
                                        return <div className="order-time">
                                            <div className="order-timer">Selesai</div>
                                            <div className="order-time-update">
                                                Terkirim<br /><strong>{repo.status_time.completed}</strong>
                                            </div>
                                        </div>;
                                    }
                                    })()}
                                    <div className="order-detail">
                                        <Link to={`/order/detail/${repo.id}`}>Selengkapnya</Link>
                                    </div>
                                </div>{/* end of table-body-btm-right */}
                            </div>{/* end of table-body-btm */}
                        </div> /* end of table-body */
                );
            })}
            <div class="pagination">
                <ReactPaginate
                    pageCount={to??1}
                    pageRangeDisplayed={4}
                    marginPagesDisplayed={1}
                    forcePage={current_page - 1}
                    activeLinkClassName='active'
                    containerClassName='paging'
                    previousLabel='&laquo;'
                    nextLabel='&raquo;'
                    onPageChange={changePage}
                />
            </div>
            <div className="text-center">
                <div className="data-result">Total <strong>{total} Data</strong> Pesanan</div>
            </div>
        </div>
    </div>
  );
};
export default List;