import React, { useEffect, useMemo, useRef, useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import swal from 'sweetalert';
import moment from 'moment';
import axios from 'axios';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { authHeader } from '../../../_helpers';
import { API_STAGING } from '../../../_settings'
import Header from '../../../components/Header';
import Sidebar from '../../../components/Sidebar';
import Chart from 'react-apexcharts';

const getServicePerformanceAvgPackingTime = async (startDate, endDate) => {
    const response = await axios.post(
        `${API_STAGING}service-performance/avg-packing-time`,
        null,
        {
            params: {
                start_date: startDate,
                end_date: endDate,
            },
            headers: authHeader(),
        },
    );

    return response.data;
}

function Pengemasan() {
    const location = useLocation();
    const history = useHistory();

    const dateRangePickerRef = useRef(null);

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false)

    const {startDate, endDate} = useMemo(() => {
        const query = new URLSearchParams(location.search);
        const sd = query.get('sd');
        const ed = query.get('ed');
        if (sd && ed) {
            return {
                startDate: new Date(sd),
                endDate: new Date(ed),
            }
        }
        return {
            startDate: new Date(),
            endDate: new Date(),
        }
    }, [location.search])

    useEffect(() => {
        setLoading(true);
        setData(undefined);
        getServicePerformanceAvgPackingTime(
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
        )
            .then((response) => {
                setData(response.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, [startDate, endDate]);

    let periodLabel = 'periode';
    let previousPeriodLabel = 'Periode sebelumnya';
    if (moment(startDate).format('YYYY-MM-DD') === moment(endDate).format('YYYY-MM-DD')) {
        periodLabel = 'hari';
        previousPeriodLabel = 'Kemarin';
    }

    return ( 
        <section id="dashboard-wrapper">
            <div className="container-wrapper">
                <Sidebar />
                <section id="main-dashboard">
                    <Header />
                    <div className="content-wrapper">
                        <div className="main-dash">
                            <div className="position-relative">
                                <div className="tool-daterange-dark">
                                <div className="tool-daterange">
                                    <DateRangePicker
                                        ref={dateRangePickerRef}
                                        initialSettings={{
                                            maxDate: new Date(),
                                            opens: 'center',
                                            locale: {
                                                format: 'YYYY-MM-DD',
                                                cancelLabel: 'Clear',
                                            },
                                            startDate,
                                            endDate,
                                        }}
                                        onCallback={(start, end) => {
                                            history.replace({
                                                search: `?sd=${start.format('YYYY-MM-DD')}&ed=${end.format('YYYY-MM-DD')}`
                                            });
                                        }}
                                        onCancel={() => {
                                            history.replace({
                                                search: undefined
                                            });
                                            if (dateRangePickerRef.current) {
                                                const today = new Date();
                                                dateRangePickerRef.current.setStartDate(today);
                                                dateRangePickerRef.current.setEndDate(today);
                                            }
                                        }}
                                        startDate={startDate}
                                        endDate={endDate}
                                    >
                                        <button className="daterange" style={{maxWidth: 'unset'}}>
                                            {`${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}`}
                                        </button>
                                    </DateRangePicker>
                                </div>
                                </div>
                                <div className="tool-export floating">
                                    <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="btn btn-secondary"
                                        table="table-to-xls"
                                        filename={`packing-time-trends-${require('moment')?.().format('YYYY-MM-DD-hh-mm-ss')}.xls`}
                                        sheet="Orders"
                                        buttonText="Export"/>
                                </div>
                                <table id="table-to-xls" style={{display: 'none'}}>
                                    <thead>
                                        <tr>
                                            <th>Periode</th>
                                            <th>Rata-rata waktu pengemasan</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(data?.trends) && data.trends.map(item => (
                                        <tr key={item.start_date + item.end_date}>
                                            <td>{item.start_date === item.end_date ? item.start_date : `${item.start_date} - ${item.end_date}`}</td>
                                            <td>{item.avg_packing_time} hari</td>
                                        </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                <div className="white-box">
                                    <h3 className="heading-box">Total rata-rata pengemasan {periodLabel} ini<br />&nbsp;</h3>
                                    <div className="d-flex align-items-center my-3">
                                    <div className="heading-percent mr-5">
                                        {data?.avg_packing_time ? `${data.avg_packing_time} Hari` : loading ? '...' : '0 Hari'}
                                    </div>
                                    <Link 
                                        to={{
                                            pathname: '/service-performance/setting-target',
                                            search: location.search,
                                        }}
                                        className="label-green"
                                    >
                                        Target &lt; {data?.target || '...'} Hari
                                    </Link>
                                    </div>
                                    <div className="label-box">
                                        {data?.previous_avg_packing_time ? `${previousPeriodLabel} ${data.previous_avg_packing_time} Hari` : loading ? `${previousPeriodLabel} ...` : `${previousPeriodLabel} 0 Hari`}
                                    </div>
                                    <div className="tool-tip-box">
                                    <span className="tool-tip">
                                        <a href="#" className="tool-tip-icon">?</a>
                                        <div className="tool-tip-content right">
                                            Total rata-rata pengemasan dalam periode tersebut
                                        </div>
                                    </span>
                                    </div>
                                </div>
                                </div>
                                <div className="col-6">
                                <div className="white-box">
                                    <h3 className="heading-box">Jumlah poin penalti<br />yang didapatkan {periodLabel} ini</h3>
                                    <div className="d-flex align-items-center my-3">
                                    <div className="heading-percent mr-5">
                                        {data?.penalty_point ? data.penalty_point : loading ? '...' : '0'}
                                    </div>
                                    </div>
                                    <div className="label-box">
                                        {data?.previous_penalty_point ? `${previousPeriodLabel} ${data.previous_penalty_point}` : loading ? `${previousPeriodLabel} ...` : `${previousPeriodLabel} 0`}
                                    </div>
                                    <div className="tool-tip-box">
                                    <span className="tool-tip">
                                        <a href="#" className="tool-tip-icon">?</a>
                                        <div className="tool-tip-content right">
                                            Jumlah poin pinalti yang di dapatkan dari keterlambatan pengiriman (melebihi waktu pengemasan yang di tentukan), untuk satu order yang terlambat dikenakan 1 poin pinalti
                                        </div>
                                    </span>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                <div className="white-box">
                                    <span className="tool-tip floating">
                                    <a href="#" className="tool-tip-icon">?</a>
                                    <div className="tool-tip-content right">
                                        Rata-rata masa pengemasan pada periode tersebut disajikan dalam grafik
                                    </div>
                                    </span>
                                    <div className="text-center">
                                    <h3 className="heading-box">Tren Masa Pengemasan</h3>
                                    </div>
                                    <div className="text-right pr-5">
                                    <div className="legend-line">Batas</div>
                                    </div>
                                    <div className="box-statistic">
                                        {loading ? (
                                            <div style={{
                                                height: 320,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                <div className="load-2">
                                                    <p>Loading... Please wait</p>
                                                    <div class="line"></div>
                                                    <div class="line"></div>
                                                    <div class="line"></div>
                                                </div>
                                            </div>
                                        ) : data && Array.isArray(data.trends) && (
                                        <Chart
                                            type="line"
                                            height={320}
                                            options={{
                                                labels: data.trends.map(item => {
                                                    if (item.start_date === item.end_date) {
                                                        return item.start_date.slice(5)
                                                    }
                                                    return `${item.start_date.slice(5)} - ${item.end_date.slice(5)}`
                                                }),
                                                annotations: {
                                                    yaxis: [
                                                        {
                                                            y: data.target,
                                                            borderColor: '#982c2c',
                                                            strokeDashArray: 10,
                                                        }
                                                    ]
                                                },
                                                yaxis: {
                                                    min: 0,
                                                    max: Math.ceil(
                                                        data.trends.reduce(
                                                            (max, item) => item.avg_packing_time > max ? item.avg_packing_time : max, 4
                                                        ) / 4
                                                    ) * 4,
                                                    tickAmount: 4,
                                                    labels: {
                                                        formatter: (val) => `${val} Hari`,
                                                        offsetX: -12,
                                                        minWidth: 42,
                                                        style: {
                                                            colors: '#626262',
                                                            fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                            fontSize: 12,
                                                        }
                                                    }
                                                },
                                                xaxis: {
                                                    labels: {
                                                        style: {
                                                            colors: '#626262',
                                                            fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                            fontSize: 12,
                                                        }
                                                    }
                                                },
                                                stroke: {
                                                    width: 2,
                                                    colors: '#e0ead8',
                                                },
                                                markers: {
                                                    size: 5,
                                                },
                                                tooltip: {
                                                    x: {show: false}
                                                },
                                                colors: ['#61805b'],
                                            }}
                                            series={[{ name: 'Waktu pengemasan', data: data.trends.map(item => item.avg_packing_time) }]}
                                        />
                                        )}
                                    </div>
                                </div>{/* end of white-box */}
                                </div>
                            </div>
                        </div>{/* end of main-dash*/}
                    </div>
                </section>
            </div>
        </section>
    );
}

export default Pengemasan;
