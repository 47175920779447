import React from 'react';
import { 
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

import { PrivateRoute } from './_services';

import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import OrderList from './pages/Order';
import OrderDetail from './pages/Order/Detail';
import RequestAWB from './pages/RequestAWB';
import ImportResi from './pages/ImportResi';
import SuratJalan from './pages/SuratJalan';
import Settings from './pages/Settings';
import Notification from './pages/Notification';
import ServicePerformance from './pages/ServicePerformance';
import SettingTarget from './pages/ServicePerformance/SettingTarget';
import TidakTerselesaikan from './pages/ServicePerformance/TidakTerselesaikan';
import Keterlambatan from './pages/ServicePerformance/Keterlambatan';
import Pengemasan from './pages/ServicePerformance/Pengemasan';
import ProductPerformance from './pages/ProductPerformance';
import CustomerInfo from './pages/CustomerInfo';
import ProfileInfo from './pages/ProfileInfo';

function App() {

  return (
    <Router>
        <Switch>
          <Route path='/login' component={Login} />
          <Route path='/forgot-password' component={ForgotPassword} />
          <PrivateRoute exact path='/' component={OrderList} />
          <PrivateRoute exact path='/order' component={OrderList} />
          <PrivateRoute path='/order/detail/:id' component={OrderDetail} />
          <PrivateRoute path='/request-awb' component={RequestAWB} />
          <PrivateRoute path='/import-resi' component={ImportResi} />
          <PrivateRoute path='/surat-jalan' component={SuratJalan} />
          <PrivateRoute exact path='/service-performance' component={ServicePerformance} />
          <PrivateRoute path='/service-performance/setting-target' component={SettingTarget} />
          <PrivateRoute path='/service-performance/tidak-terselesaikan' component={TidakTerselesaikan} />
          <PrivateRoute path='/service-performance/Keterlambatan' component={Keterlambatan} />
          <PrivateRoute path='/service-performance/Pengemasan' component={Pengemasan} />
          <PrivateRoute path='/product-performance' component={ProductPerformance} />
          <PrivateRoute path='/customer-info' component={CustomerInfo} />
          <PrivateRoute path='/settings' component={Settings} />
          <PrivateRoute path='/notification' component={Notification} />
          <PrivateRoute path='/profile-info' component={ProfileInfo} />
        </Switch>
      </Router>
    );
}

export default App;
