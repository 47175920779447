import React,{ useState } from 'react';
import ReactPaginate from 'react-paginate';
const List = (props) => {
  const { data,total,set_page,current_page,last_page } = props;
    const [appState, setAppState]  = useState({
        dataState:data
        });
    
    const changePage = (e) => {
        const selectedPage = e.selected + 1;
        set_page(selectedPage)
        console.log(`active page is ${selectedPage}`);
    }
    let today = new Date();
    let dateNow = today.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'numeric', year: 'numeric'
      }).replace(/[^\d,.-]/g,'-');     
    let timeNow = today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
  if (!data || data.length === 0) return <div>
    <div className="result">Total <strong>0 Data</strong> Produk</div>
    <div className="table-data">
        <div className="table-head table-pp">
            <div className="table-pp-left">
                <div className="tb-pp">Nama Produk</div>
            </div>
            <div className="table-pp-middle">
                <div className="tb-pp">Produk Terjual</div>
                <div className="tb-pp">Produk Dibayar</div>
                <div className="tb-pp">Produk Siap Dikirim</div>
                <div className="tb-pp">Total Pesanan</div>
            </div>
            <div className="table-pp-right">
                <div className="tb-pp">Penjualan</div>
                <div className="tb-pp">Sisa Stock</div>
            </div>
        </div>
        <p style={{textAlign: 'center', marginTop: 120, marginBottom: 120}}>No data product, sorry</p>
        <div class="pagination">
            <ReactPaginate
                pageCount={last_page??1}
                pageRangeDisplayed={4}
                marginPagesDisplayed={1}
                forcePage={current_page - 1}
                activeLinkClassName='active'
                containerClassName='paging'
                previousLabel='&laquo;'
                nextLabel='&raquo;'
                onPageChange={changePage}
            />
        </div>
        <div className="text-center">
            <div className="data-result">Total <strong>0 Data</strong> Produk</div>
        </div>
    </div>
</div>;
  return (
    <div>        
        <div className="result">Total <strong>{total} Data</strong> Produk</div>
            <div className="table-data">
                <div className="table-head table-pp">
                    <div className="table-pp-left">
                        <div className="tb-pp">Nama Produk</div>
                    </div>
                    <div className="table-pp-middle">
                        <div className="tb-pp">Produk Dibayar</div>
                        <div className="tb-pp">Produk Siap Dikirim</div>
                        <div className="tb-pp">Produk Terjual</div>
                        <div className="tb-pp">Total Pesanan</div>
                    </div>
                    <div className="table-pp-right">
                        <div className="tb-pp">Penjualan</div>
                        <div className="tb-pp">Sisa Stock</div>
                    </div>
                </div>
                {appState.dataState.map((repo,index) => {
                return (
                    <div className="table-body table-pp" key={index}>
                        <div className="table-pp-left">
                            <div className="tb-pp">
                                <div className="product-list">
                                    <div className="product-image"><img src={repo.product_image} alt="Image Product 01" /></div>
                                    <div className="product-title">{repo.product_name}</div>
                                </div>
                            </div>
                        </div>
                        <div className="table-pp-middle">
                            <div className="tb-pp">{repo.paid_product}</div>
                            <div className="tb-pp">{repo.shipped_product}</div>
                            <div className="tb-pp">{repo.ordered_product}</div>
                            <div className="tb-pp">{repo.total_order}</div>
                        </div>
                        <div className="table-pp-right">
                            <div className="tb-pp text-blue">{repo.total_price_text}</div>
                            <div className="tb-pp text-red">{repo.left_stock}</div>
                        </div>
                    </div> /* end of table-body */
                    );
                })}
                <div className="pagination">
                    <ReactPaginate
                        pageCount={last_page}
                        pageRangeDisplayed={4}
                        marginPagesDisplayed={1}
                        forcePage={current_page - 1}
                        activeLinkClassName='active'
                        containerClassName='paging'
                        previousLabel='&laquo;'
                        nextLabel='&raquo;'
                        onPageChange={changePage}
                    />
                </div>
                <div className="text-center">
                    <div className="data-result">Total <strong>{total} Data</strong> Produk</div>
                </div>
            </div>
    </div>
  );
};
export default List;