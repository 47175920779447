import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';

function Sidebar() {
    return ( 
        <section id="sidebar">
            <div className="dash-logo">
                <Link to="/"><img src="/img/avo-logo.png" alt="avo-logo" className="img-fluid" /></Link>
            </div>
            <nav className="side-menu">
                <div className="accordion" id="accordion-order">
                    <Switch>
                        <Route exact path='/'>
                            <div className="side-tabs">
                                <div className="side-tab">
                                    <input type="checkbox" id="chck1" defaultChecked />
                                    <label className="side-menu-btn active" htmlFor="chck1"><span className="icon-order" /> Order</label>
                                    <div className="tab-content">
                                        <Link to="/order" className="accordion-nav active">Order List</Link>
                                        <Link to="/request-awb" className="accordion-nav">Request AWB</Link>
                                        <Link to="/import-resi" className="accordion-nav">Import Resi</Link>
                                        <Link to="/surat-jalan" className="accordion-nav">Surat Jalan</Link>
                                    </div>
                                </div>
                            </div>{/* end of side tabs */}
                            <Link to="/service-performance" className="side-menu-btn"><span className="icon-service" /> Service Performance</Link>
                            <Link to="/product-performance" className="side-menu-btn"><span className="icon-product" /> Product Performance</Link>
                            <Link to="/customer-info" className="side-menu-btn"><span className="icon-customer" /> Customer Info</Link>
                        </Route>
                        <Route exact path='/order'>
                            <div className="side-tabs">
                                <div className="side-tab">
                                    <input type="checkbox" id="chck1" defaultChecked />
                                    <label className="side-menu-btn active" htmlFor="chck1"><span className="icon-order" /> Order</label>
                                    <div className="tab-content">
                                        <Link to="/order" className="accordion-nav active">Order List</Link>
                                        <Link to="/request-awb" className="accordion-nav">Request AWB</Link>
                                        <Link to="/import-resi" className="accordion-nav">Import Resi</Link>
                                        <Link to="/surat-jalan" className="accordion-nav">Surat Jalan</Link>
                                    </div>
                                </div>
                            </div>{/* end of side tabs */}
                            <Link to="/service-performance" className="side-menu-btn"><span className="icon-service" /> Service Performance</Link>
                            <Link to="/product-performance" className="side-menu-btn"><span className="icon-product" /> Product Performance</Link>
                            <Link to="/customer-info" className="side-menu-btn"><span className="icon-customer" /> Customer Info</Link>
                        </Route>
                        <Route exact path='/request-awb'>
                            <div className="side-tabs">
                                <div className="side-tab">
                                    <input type="checkbox" id="chck1" defaultChecked />
                                    <label className="side-menu-btn active" htmlFor="chck1"><span className="icon-order" /> Order</label>
                                    <div className="tab-content">
                                        <Link to="/order" className="accordion-nav">Order List</Link>
                                        <Link to="/request-awb" className="accordion-nav active">Request AWB</Link>
                                        <Link to="/import-resi" className="accordion-nav">Import Resi</Link>
                                        <Link to="/surat-jalan" className="accordion-nav">Surat Jalan</Link>
                                    </div>
                                </div>
                            </div>{/* end of side tabs */}
                            <Link to="/service-performance" className="side-menu-btn"><span className="icon-service" /> Service Performance</Link>
                            <Link to="/product-performance" className="side-menu-btn"><span className="icon-product" /> Product Performance</Link>
                            <Link to="/customer-info" className="side-menu-btn"><span className="icon-customer" /> Customer Info</Link>
                        </Route>
                        <Route exact path='/import-resi'>
                            <div className="side-tabs">
                                <div className="side-tab">
                                    <input type="checkbox" id="chck1" defaultChecked />
                                    <label className="side-menu-btn active" htmlFor="chck1"><span className="icon-order" /> Order</label>
                                    <div className="tab-content">
                                        <Link to="/order" className="accordion-nav">Order List</Link>
                                        <Link to="/request-awb" className="accordion-nav">Request AWB</Link>
                                        <Link to="/import-resi" className="accordion-nav active">Import Resi</Link>
                                        <Link to="/surat-jalan" className="accordion-nav">Surat Jalan</Link>
                                    </div>
                                </div>
                            </div>{/* end of side tabs */}
                            <Link to="/service-performance" className="side-menu-btn"><span className="icon-service" /> Service Performance</Link>
                            <Link to="/product-performance" className="side-menu-btn"><span className="icon-product" /> Product Performance</Link>
                            <Link to="/customer-info" className="side-menu-btn"><span className="icon-customer" /> Customer Info</Link>
                        </Route>
                        <Route exact path='/surat-jalan'>
                            <div className="side-tabs">
                                <div className="side-tab">
                                    <input type="checkbox" id="chck1" defaultChecked />
                                    <label className="side-menu-btn active" htmlFor="chck1"><span className="icon-order" /> Order</label>
                                    <div className="tab-content">
                                        <Link to="/order" className="accordion-nav">Order List</Link>
                                        <Link to="/request-awb" className="accordion-nav">Request AWB</Link>
                                        <Link to="/import-resi" className="accordion-nav">Import Resi</Link>
                                        <Link to="/surat-jalan" className="accordion-nav active">Surat Jalan</Link>
                                    </div>
                                </div>
                            </div>{/* end of side tabs */}
                            <Link to="/service-performance" className="side-menu-btn"><span className="icon-service" /> Service Performance</Link>
                            <Link to="/product-performance" className="side-menu-btn"><span className="icon-product" /> Product Performance</Link>
                            <Link to="/customer-info" className="side-menu-btn"><span className="icon-customer" /> Customer Info</Link>
                        </Route>
                        <Route exact path='/service-performance'>
                            <div className="side-tabs">
                                <div className="side-tab">
                                    <input type="checkbox" id="chck1"/>
                                    <label className="side-menu-btn" htmlFor="chck1"><span className="icon-order" /> Order</label>
                                    <div className="tab-content">
                                        <Link to="/order" className="accordion-nav">Order List</Link>
                                        <Link to="/request-awb" className="accordion-nav">Request AWB</Link>
                                        <Link to="/import-resi" className="accordion-nav">Import Resi</Link>
                                        <Link to="/surat-jalan" className="accordion-nav">Surat Jalan</Link>
                                    </div>
                                </div>
                            </div>{/* end of side tabs */}
                            <Link to="/service-performance" className="side-menu-btn active"><span className="icon-service" /> Service Performance</Link>
                            <Link to="/product-performance" className="side-menu-btn"><span className="icon-product" /> Product Performance</Link>
                            <Link to="/customer-info" className="side-menu-btn"><span className="icon-customer" /> Customer Info</Link>
                        </Route>
                        <Route exact path='/service-performance/tidak-terselesaikan'>
                            <div className="side-tabs">
                                <div className="side-tab">
                                    <input type="checkbox" id="chck1"/>
                                    <label className="side-menu-btn" htmlFor="chck1"><span className="icon-order" /> Order</label>
                                    <div className="tab-content">
                                        <Link to="/order" className="accordion-nav">Order List</Link>
                                        <Link to="/request-awb" className="accordion-nav">Request AWB</Link>
                                        <Link to="/import-resi" className="accordion-nav">Import Resi</Link>
                                        <Link to="/surat-jalan" className="accordion-nav">Surat Jalan</Link>
                                    </div>
                                </div>
                            </div>{/* end of side tabs */}
                            <Link to="/service-performance" className="side-menu-btn active"><span className="icon-service" /> Service Performance</Link>
                            <Link to="/product-performance" className="side-menu-btn"><span className="icon-product" /> Product Performance</Link>
                            <Link to="/customer-info" className="side-menu-btn"><span className="icon-customer" /> Customer Info</Link>
                        </Route>
                        <Route exact path='/service-performance/setting-target'>
                            <div className="side-tabs">
                                <div className="side-tab">
                                    <input type="checkbox" id="chck1"/>
                                    <label className="side-menu-btn" htmlFor="chck1"><span className="icon-order" /> Order</label>
                                    <div className="tab-content">
                                        <Link to="/order" className="accordion-nav">Order List</Link>
                                        <Link to="/request-awb" className="accordion-nav">Request AWB</Link>
                                        <Link to="/import-resi" className="accordion-nav">Import Resi</Link>
                                        <Link to="/surat-jalan" className="accordion-nav">Surat Jalan</Link>
                                    </div>
                                </div>
                            </div>{/* end of side tabs */}
                            <Link to="/service-performance" className="side-menu-btn active"><span className="icon-service" /> Service Performance</Link>
                            <Link to="/product-performance" className="side-menu-btn"><span className="icon-product" /> Product Performance</Link>
                            <Link to="/customer-info" className="side-menu-btn"><span className="icon-customer" /> Customer Info</Link>
                        </Route>
                        <Route exact path='/service-performance/keterlambatan'>
                            <div className="side-tabs">
                                <div className="side-tab">
                                    <input type="checkbox" id="chck1"/>
                                    <label className="side-menu-btn" htmlFor="chck1"><span className="icon-order" /> Order</label>
                                    <div className="tab-content">
                                        <Link to="/order" className="accordion-nav">Order List</Link>
                                        <Link to="/request-awb" className="accordion-nav">Request AWB</Link>
                                        <Link to="/import-resi" className="accordion-nav">Import Resi</Link>
                                        <Link to="/surat-jalan" className="accordion-nav">Surat Jalan</Link>
                                    </div>
                                </div>
                            </div>{/* end of side tabs */}
                            <Link to="/service-performance" className="side-menu-btn active"><span className="icon-service" /> Service Performance</Link>
                            <Link to="/product-performance" className="side-menu-btn"><span className="icon-product" /> Product Performance</Link>
                            <Link to="/customer-info" className="side-menu-btn"><span className="icon-customer" /> Customer Info</Link>
                        </Route>
                        <Route exact path='/service-performance/pengemasan'>
                            <div className="side-tabs">
                                <div className="side-tab">
                                    <input type="checkbox" id="chck1"/>
                                    <label className="side-menu-btn" htmlFor="chck1"><span className="icon-order" /> Order</label>
                                    <div className="tab-content">
                                        <Link to="/order" className="accordion-nav">Order List</Link>
                                        <Link to="/request-awb" className="accordion-nav">Request AWB</Link>
                                        <Link to="/import-resi" className="accordion-nav">Import Resi</Link>
                                        <Link to="/surat-jalan" className="accordion-nav">Surat Jalan</Link>
                                    </div>
                                </div>
                            </div>{/* end of side tabs */}
                            <Link to="/service-performance" className="side-menu-btn active"><span className="icon-service" /> Service Performance</Link>
                            <Link to="/product-performance" className="side-menu-btn"><span className="icon-product" /> Product Performance</Link>
                            <Link to="/customer-info" className="side-menu-btn"><span className="icon-customer" /> Customer Info</Link>
                        </Route>
                        <Route exact path='/product-performance'>
                            <div className="side-tabs">
                                <div className="side-tab">
                                    <input type="checkbox" id="chck1"/>
                                    <label className="side-menu-btn" htmlFor="chck1"><span className="icon-order" /> Order</label>
                                    <div className="tab-content">
                                        <Link to="/order" className="accordion-nav">Order List</Link>
                                        <Link to="/request-awb" className="accordion-nav">Request AWB</Link>
                                        <Link to="/import-resi" className="accordion-nav">Import Resi</Link>
                                        <Link to="/surat-jalan" className="accordion-nav">Surat Jalan</Link>
                                    </div>
                                </div>
                            </div>{/* end of side tabs */}
                            <Link to="/service-performance" className="side-menu-btn"><span className="icon-service" /> Service Performance</Link>
                            <Link to="/product-performance" className="side-menu-btn active"><span className="icon-product" /> Product Performance</Link>
                            <Link to="/customer-info" className="side-menu-btn"><span className="icon-customer" /> Customer Info</Link>
                        </Route>
                        <Route exact path='/customer-info'>
                            <div className="side-tabs">
                                <div className="side-tab">
                                    <input type="checkbox" id="chck1"/>
                                    <label className="side-menu-btn" htmlFor="chck1"><span className="icon-order" /> Order</label>
                                    <div className="tab-content">
                                        <Link to="/order" className="accordion-nav">Order List</Link>
                                        <Link to="/request-awb" className="accordion-nav">Request AWB</Link>
                                        <Link to="/import-resi" className="accordion-nav">Import Resi</Link>
                                        <Link to="/surat-jalan" className="accordion-nav">Surat Jalan</Link>
                                    </div>
                                </div>
                            </div>{/* end of side tabs */}
                            <Link to="/service-performance" className="side-menu-btn"><span className="icon-service" /> Service Performance</Link>
                            <Link to="/product-performance" className="side-menu-btn"><span className="icon-product" /> Product Performance</Link>
                            <Link to="/customer-info" className="side-menu-btn active"><span className="icon-customer" /> Customer Info</Link>
                        </Route>
                    </Switch>
                </div>
            </nav>
        </section>
    );
}

export default Sidebar;
