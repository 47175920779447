import React from 'react';

function ForgotPassword() {
    return ( 
        <div className="credential">
            <div className="brand-image">
                <a href="/login"><img src="/img/avo-logo.png" alt="Avoskin" /></a>
            </div>
            <div className="credential-box">
                <h1 className="heading">Lupa Password</h1>
                <form className="form-credentials">
                    <div className="form-group">
                        <label htmlFor="InputEmail" className="form-label">Email</label>
                        <input type="email" className="form-control" id="InputEmail" />
                    </div>
                    <button type="submit" className="btn btn-primary btn-rounded mt-4">Kirim Verifikasi</button>
                </form>
            </div>
            <div className="background-bubble left"><img src="/img/login-bubble.svg" alt="Bubble" /></div>
            <div className="background-bubble right"><img src="/img/login-bubble.svg" alt="Bubble" /></div>
        </div>
    );
}

export default ForgotPassword;