import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import axios from 'axios';
import swal from 'sweetalert';
import DatePicker  from 'react-datepicker';
import { Modal } from 'react-bootstrap';
import { authHeader } from '../../_helpers';
import { API_STAGING } from '../../_settings'
import Header from '../../components/Header';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { enGB, id } from 'date-fns/locale';
import { addDays, subDays } from 'date-fns';
registerLocale('id', id)

function ProfileInfo() {
    const [fullname, setFullName]                           = useState(localStorage.getItem('name'));
    const [name, setName]                                   = useState(localStorage.getItem('name'));
    const [email, setEmail]                                 = useState(localStorage.getItem('email'));
    const [gender, setGender]                               = useState(localStorage.getItem('gender'));
    const [datebirth, setDatebirth]                         = useState(new Date(localStorage.getItem('datebirth')));
    const [user_register, setUserRegister]                  = useState(localStorage.getItem('user_register'));
    const [current_password, setCurrentPassword]            = useState('');
    const [password, setPassword]                           = useState('');
    const [password_confirmation, setPasswordConfirmation]  = useState('');
    const [selectedPage, setPage]                           = useState('1');
    const [show, setShow]                                   = useState(false);
    const [error, setError]                                 = useState('');
    const months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember"
      ];
      function generateArrayOfYears() {
        var max = new Date().getFullYear()
        var min = max - 116
        var years = []
      
        for (var i = max; i >= min; i--) {
          years.push(i)
        }
        return years
      }
    const years = generateArrayOfYears();
    const [appState, setAppState]   = useState({
        loading: false,
        data: null,
        dat: null,
        repos: null,
        detail: null,
        result: null,
        total: null,
        to:null,
        current_page: null,
        disabled: false,
        showLoading: false,
        message: null
    });
    const onChangeDate = (event) => {
        if (event) {
            const datebirth = event;
            setDatebirth(datebirth);
            console.log(event)
        }
    }
    const onChangeName= (e) => {
        const value = e.target.value
        setName(value)
        setError('')
    }
    const onChangeEmail = (e) => {
        const value = e.target.value
        setEmail(value)
        setError('')
    }
    const onChangeGender = (event) => {
        const value = event.target.value
        setGender(value)
    }
    const onChangeCurrentPassword = (e) => {
        const value = e.target.value
        setCurrentPassword(value)
        setError('')
    }
    const onChangePassword = (e) => {
        const value = e.target.value
        setPassword(value)
        setError('')
    }
    const onChangePasswordConfirmation = (e) => {
        const value = e.target.value
        setPasswordConfirmation(value)
        setError('')
    }
    const updateProfilInfo= () => {
        setAppState({ disabled: true });
        axios.post(`${API_STAGING}user/update-profile/?name=${name}&email=${email}&gender=${gender}&datebirth=${((datebirth.getDate() > 9) ? datebirth.getDate() : ('0' + datebirth.getDate()))+'/'+((datebirth.getMonth() > 8) ? (datebirth.getMonth() + 1) : ('0' + (datebirth.getMonth() + 1)))+'/'+datebirth.getFullYear()}`, {
            //...data
        },  {
            headers: authHeader()
        })
        .then(repos => {
            const data = repos.data.message;
            if(repos.data.status == true) {
                var dateString = repos.data.data.datebirth;
                var dateParts = dateString.split("/");
                var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
                var datebirth = dateObject.toString();
                localStorage.setItem('name', repos.data.data.name)
                localStorage.setItem('email', repos.data.data.email)
                localStorage.setItem('gender', repos.data.data.gender)
                localStorage.setItem('datebirth', datebirth)
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.reload();
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
                setAppState({ disabled: false });
            }
        })
    }
    const validateProfil = () => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!email) {
            errors.email = "Email is required";
        } else if (!regex.test(email)) {
            errors.email = "Invalid Email";
        }

        if (!name) {
            errors.name = "Full Name is required";
        }

        return errors;
    };
    const validate = () => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!current_password) {
            errors.current_password = "Old Password is required";
        } else if (current_password.length < 6) {
            errors.current_password = "Password must be 6 characters long.";
        }

        if (!password) {
            errors.password = "New Password is required";
        }

        if (!password_confirmation) {
            errors.password_confirmation = "Confirm New Password is required";
        }

        if (password && password_confirmation) {
            if (password !== password_confirmation) {
                errors.password_confirmation = "New Password not matched";
            }
        }

        return errors;
    };
    function openChangePassword() {
        setShow(true);
    }
    function closeChangePassword() {
        setShow(false);
        setCurrentPassword('');
        setPassword('');
        setPasswordConfirmation('');
    }
    const updateProfilPassword= () => {
        setAppState({ disabled: true });
        axios.post(`${API_STAGING}user/update-password/?current_password=${btoa(current_password)}&password=${btoa(password)}&password_confirmation=${btoa(password_confirmation)}`, {
            //...data
        },  {
            headers: authHeader()
        })
        .then(repos => {
            const data = repos.data.message;
            if(repos.data.status == true) {
                swal({
                    title: "Done!",
                    text: data,
                    icon: "success",
                    timer: 3000,
                    button: false
                })
                setTimeout(function () {
                    window.location.reload();
                }, 3000);
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
                setAppState({ disabled: false });
            }
        })
    }
    return ( 
        <section id="dashboard-wrapper">
            <Header />
            <div className="row">
                <div className="col-md-6 offset-md-3">      
                    <div className="content-wrapper p-40">
                        <div className="box-profile-info">
                        <div className="profile-avatar"><img src="/img/avatar.svg" alt="Avatar" /></div>
                        <div className="profile-data">
                            <div className="profile-name">{fullname}</div>
                            <div className="profile-date">Member since {new Date(user_register).getDate()+ " " + months[new Date(user_register).getMonth()]+ " "+ new Date(user_register).getFullYear()}</div>
                        </div>
                        </div>
                        <Formik
                            initialValues={{ name: '', email: '', gender: '', datebirth: ''}}
                            validate={validateProfil}
                            onSubmit={updateProfilInfo}
                            >
                            {(formik) => {
                            const {
                                values,
                                isSubmitting,
                                handleChange,
                                handleSubmit,
                                errors,
                                touched,
                                handleBlur,
                                isValid,
                                dirty
                            } = formik;
                            return (
                                <Form>
                                    <div className="form-group">
                                        <label htmlFor="InputUsername" className="form-label"><strong>Full Name *</strong></label>
                                        <input type="text" className="form-control" name="name" id="InputUsername" value={name} onChange={onChangeName} onBlur={handleBlur}/>
                                        {errors.name && touched.name ? <span className="tooltiptext" >{errors.name}</span> : null}
                                    </div>
                                    <div className="form-row align-items-center">
                                        <div className="col-8">
                                            <div className="form-group">
                                                <label htmlFor="InputEmail" className="form-label"><strong>Email *</strong></label>
                                                <input type="email" className="form-control" name="email" id="InputEmail" value={email} onChange={onChangeEmail} onBlur={handleBlur}/>
                                                {errors.email && touched.email ? <span className="tooltiptext" >{errors.email}</span> : null}
                                            </div>
                                        </div>
                                        <div className="col-4 text-right">
                                            <div onClick={openChangePassword} className="btn btn-tersier" style={{marginTop: '12px'}}>Change Password</div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="InputEmail" className="form-label"><strong>Gender</strong></label><br />
                                        <div className="form-check form-check-inline">
                                            <div className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input" name="gender" id="GenderMale" value={1} checked={gender == "1"} name="radio-stacked" defaultChecked="checked" />
                                                <label className="custom-control-label" htmlFor="GenderMale">Male</label>
                                            </div>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <div className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input" name="gender" id="GenderFemale" value={2} checked={gender == "2"} name="radio-stacked" />
                                                <label className="custom-control-label" htmlFor="GenderFemale">Female</label>
                                            </div>
                                        </div>
                                    </div>
                                    <label><strong>Date of Birth</strong></label><br />
                                    <div className="box-datebirth">
                                        <DatePicker
                                            locale="id"
                                            renderCustomHeader={({
                                                date,
                                                changeYear,
                                                changeMonth,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled
                                            }) => (
                                                <div
                                                style={{
                                                    marginBottom: "20px",
                                                    display: "flex",
                                                    justifyContent: "center"
                                                }}
                                                >
                                                <div onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="btn-left">
                                                    <img src="/img/ico-chevron-right.svg"/>
                                                </div>
                                                <select
                                                    value={months[date.getMonth()]}
                                                    onChange={({ target: { value } }) =>
                                                    changeMonth(months.indexOf(value))
                                                    }
                                                    className="mr-2"
                                                >
                                                    {months.map(option => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                    ))}
                                                </select>
                                                <select
                                                    value={date.getFullYear()}
                                                    onChange={({ target: { value } }) => changeYear(value)}
                                                >
                                                    {years.map(option => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                    ))}
                                                </select>
                                                <div onClick={increaseMonth} disabled={nextMonthButtonDisabled}  className="btn-right">
                                                    <img src="/img/ico-chevron-right.svg"/>
                                                </div>
                                                </div>
                                            )}
                                            selected={datebirth}
                                            dateFormat="dd/MM/yyyy"
                                            onChange={onChangeDate.bind(datebirth)}
                                            maxDate={addDays(new Date(), 0)}
                                            calendarClassName="calender-datebirth"
                                            inline
                                            />
                                    </div>
                                    <button type="submit" style={{ color: 'white' }} disabled={errors.email || errors.name || !email || !name }  className="btn btn-primary btn-rounded mt-4">{appState.disabled ? "Loading..." : "Save"}</button>
                                </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>   
            <div id="popup-changepassword" className="overlay">
                <div className="popup">
                    <Modal show={show} className="modal-password">
                        <button className="close" onClick={closeChangePassword}><img src="/img/ico-close.svg" alt="close" /></button>
                        <div className="content">
                            <h3 className="heading-box lg mb-4 text-center">Change Password</h3>
                            <Formik
                            initialValues={{ current_password: '', password: '', password_confirmation: ''}}
                            validate={validate}
                            onSubmit={updateProfilPassword}
                            >
                            {(formik) => {
                            const {
                                values,
                                isSubmitting,
                                handleChange,
                                handleSubmit,
                                errors,
                                touched,
                                handleBlur,
                                isValid,
                                dirty
                            } = formik;
                            return (
                                <Form className="form-password">
                                    <div className="Form-fields">
                                        <label htmlFor="password" className="Control-label Control-label--password"><strong>Old Password</strong></label>
                                        <input type="checkbox" id="show-old-password" className="show-password" defaultChecked />    
                                        <label htmlFor="show-old-password" className="Control-label Control-label--showPassword">          
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width={32} height={32} className="svg-toggle-password" title="Toggle Password Security">
                                            <title>Hide/Show Password</title>
                                            <path d="M24,9A23.654,23.654,0,0,0,2,24a23.633,23.633,0,0,0,44,0A23.643,23.643,0,0,0,24,9Zm0,25A10,10,0,1,1,34,24,10,10,0,0,1,24,34Zm0-16a6,6,0,1,0,6,6A6,6,0,0,0,24,18Z" />
                                            <rect x="20.133" y="2.117" height={44} transform="translate(23.536 -8.587) rotate(45)" className="closed-eye" />
                                            <rect x={22} y="3.984" width={4} height={44} transform="translate(25.403 -9.36) rotate(45)" style={{fill: '#fff'}} className="closed-eye" />
                                            </svg>
                                        </label>
                                        <input type="text" id="password" name="current_password" value={current_password} onChange={onChangeCurrentPassword} onBlur={handleBlur} className="ControlInput ControlInput--password" />
                                        {errors.current_password && touched.current_password ? <span className="tooltiptext" >{errors.current_password}</span> : null}
                                    </div>
                                    <div className="Form-fields">
                                        <label htmlFor="password" className="Control-label Control-label--password"><strong>New Password</strong></label>
                                        <input type="checkbox" id="show-password" className="show-password" defaultChecked />    
                                        <label htmlFor="show-password" className="Control-label Control-label--showPassword">          
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width={32} height={32} className="svg-toggle-password" title="Toggle Password Security">
                                            <title>Hide/Show Password</title>
                                            <path d="M24,9A23.654,23.654,0,0,0,2,24a23.633,23.633,0,0,0,44,0A23.643,23.643,0,0,0,24,9Zm0,25A10,10,0,1,1,34,24,10,10,0,0,1,24,34Zm0-16a6,6,0,1,0,6,6A6,6,0,0,0,24,18Z" />
                                            <rect x="20.133" y="2.117" height={44} transform="translate(23.536 -8.587) rotate(45)" className="closed-eye" />
                                            <rect x={22} y="3.984" width={4} height={44} transform="translate(25.403 -9.36) rotate(45)" style={{fill: '#fff'}} className="closed-eye" />
                                            </svg>
                                        </label>
                                        <input type="text" id="password" name="password" value={password} onChange={onChangePassword} onBlur={handleBlur} className="ControlInput ControlInput--password" />
                                        {errors.password && touched.password ? <span className="tooltiptext" >{errors.password}</span> : null}
                                    </div>
                                    <div className="Form-fields">
                                        <label htmlFor="password" className="Control-label Control-label--password"><strong>Confirm New Password</strong></label>
                                        <input type="checkbox" id="show-confirm-password" className="show-password" defaultChecked />    
                                        <label htmlFor="show-confirm-password" className="Control-label Control-label--showPassword">          
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width={32} height={32} className="svg-toggle-password" title="Toggle Password Security">
                                            <title>Hide/Show Password</title>
                                            <path d="M24,9A23.654,23.654,0,0,0,2,24a23.633,23.633,0,0,0,44,0A23.643,23.643,0,0,0,24,9Zm0,25A10,10,0,1,1,34,24,10,10,0,0,1,24,34Zm0-16a6,6,0,1,0,6,6A6,6,0,0,0,24,18Z" />
                                            <rect x="20.133" y="2.117" height={44} transform="translate(23.536 -8.587) rotate(45)" className="closed-eye" />
                                            <rect x={22} y="3.984" width={4} height={44} transform="translate(25.403 -9.36) rotate(45)" style={{fill: '#fff'}} className="closed-eye" />
                                            </svg>
                                        </label>
                                        <input type="text" id="password" name="password_confirmation" value={password_confirmation} onChange={onChangePasswordConfirmation} onBlur={handleBlur} className="ControlInput ControlInput--password" />
                                        {errors.password_confirmation && touched.password_confirmation ? <span className="tooltiptext" >{errors.password_confirmation}</span> : null}
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" style={{ color: 'white' }} disabled={errors.current_password || errors.password || errors.password_confirmation || !current_password || !password || !password_confirmation} className="btn btn-primary">{appState.disabled ? "Loading..." : "Update"}</button>
                                    </div>
                                </Form>
                                );
                            }}
                        </Formik>
                        </div>
                    </Modal>
                </div>
            </div>
        </section>
    );
}

export default ProfileInfo;
