import React, { useEffect, useState, useRef } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import { Formik, Form } from 'formik';
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import { authHeader } from '../../_helpers';
import { API_STAGING } from '../../_settings'
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import List from './List';
import Loading from '../../components/Loading';

function SuratJalan() {
    const ListLoading              = Loading(List);
    const [jalan, setSuratJalan]   = useState('sorting');
    const [search, setSearchTerm] = useState('');
    const [limit, setLimit]           = useState('10');
    const [sorting, setSorting]       = useState('');
    const [expedition, setExpedition] = useState('');
    const [startDate, setStartDate]   = useState('');
    const [endDate, setEndDate]       = useState('');
    const dateRangePickerRef          = useRef(null);
    const [selectedPage, setPage]     = useState('');
    const [show, setShow] = useState(false);
    const componentRef = useRef();
    const [showPrint, setShowPrint] = useState(false);
    const [id, setId]       = useState('');
    const [error, setError]       = useState('');
    const dateManifest = new Date();
    const idManifest = new Date();
    const [appState, setAppState]  = useState({
        loading: false,
        data: null,
        pageJalan: 'sorting',
        disabled: false,
        startDate : '',
        endDate: '',
        repos: null,
        dat: null,
        total: null,
        to:null,
        current_page: null,
        detail: null
    });
    const onChangeId = (e) => {
        const value = e.target.value
        const invoice_number = justNumbers(value)
        setId(invoice_number)
        setError('')
        if (invoice_number.length > 4) {        
            setTimeout(function () {
                setAppState({ loading: true, disabled: true });
                axios.post(`${API_STAGING}order/need-print?id=${invoice_number}`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.message;
                    if(repos.data.status == true) {
                    swal({
                        title: "Done!",
                        text: data,
                        icon: "success",
                        timer: 3000,
                        button: false
                    })
                    setAppState({ loading: false, total: appState.total, current_page: appState.current_page, to: appState.to,pageJalan: appState.pageJalan });
                    openScan({pageJalan: appState.pageJalan})
                    } else {
                        swal({
                            title: "Error!",
                            text: data,
                            icon: "error",
                            button: true
                        })
                        setAppState({ loading: false, total: appState.total, current_page: appState.current_page, to: appState.to,pageJalan: appState.pageJalan });
                        openScan({pageJalan: appState.pageJalan})
                    }
                });
            },  5000);
        }
        console.log(value)
    }
    const validate = () => {
        let errors = {};

        if (!id) {
            errors.id = "Kode pesanan wajib di isi";
        } else if (id.length < 4 ) {
            errors.id = "Kode pesanan yang di input minimal 4 digit";
        }
        return errors;
    };
    function justNumbers(string) {
        var numsStr = string.replace(/\D/g,"");
        return parseInt(numsStr);
    }
    const ScanBarcode = () => {
        const invoice_number = id
        setAppState({ loading: true, disabled: true, pageJalan: appState.pageJalan });
        axios.post(`${API_STAGING}order/need-print?id=${invoice_number}`, {
            //...data
        },  {
            headers: authHeader()
        })
        .then((repos) => {
            const data = repos.data.message;
            if(repos.data.status == true) {
            swal({
                title: "Done!",
                text: data,
                icon: "success",
                timer: 3000,
                button: false
              })
              setAppState({ loading: false, total: appState.total, current_page: appState.current_page, to: appState.to,pageJalan: appState.pageJalan });
              openScan({pageJalan: appState.pageJalan})
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
                setAppState({ loading: false, total: appState.total, current_page: appState.current_page, to: appState.to,pageJalan: appState.pageJalan });
                openScan({pageJalan: appState.pageJalan})
            }
        });
    }
    const onChangeSuratJalan = (event) => {
        const pageJalan = event.pageJalan
        setAppState({ loading: true, pageJalan: event.pageJalan });
        if (sorting == "asc" || sorting == "desc"){
            axios.post(`${API_STAGING}order/listing?status=packing&awb=yes&surat-jalan=${pageJalan}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=1&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({  pageJalan: event.pageJalan, loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to });
            });
        } else {
            axios.post(`${API_STAGING}order/listing?status=packing&awb=yes&surat-jalan=${pageJalan}&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=1&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({  pageJalan: event.pageJalan, loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to });
            });
        }
    }
    const changeSearch = (e) => {
        const value = e.target.value
        setSearchTerm(value)
    }
    const onChangeSearch = (event) => {
        const data = {
            search: search,
        }
        setAppState({ loading: true, pageJalan: appState.pageJalan });
        if (sorting == "asc" || sorting == "desc"){
            axios.post(`${API_STAGING}order/listing?status=packing&awb=yes&surat-jalan=${appState.pageJalan}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageJalan: appState.pageJalan });
            });
        } else {
            axios.post(`${API_STAGING}order/listing?status=packing&awb=yes&surat-jalan=${appState.pageJalan}&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageJalan: appState.pageJalan });
            });
        }
    }
    const onChangeLimit = (event) => {
        const limit = event.target.value
        setLimit(limit);
        setAppState({ loading: true, pageJalan: appState.pageJalan });
        if (sorting == "asc" || sorting == "desc"){
            axios.post(`${API_STAGING}order/listing?status=packing&awb=yes&surat-jalan=${appState.pageJalan}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageJalan: appState.pageJalan });
            });
        } else {
            axios.post(`${API_STAGING}order/listing?status=packing&awb=yes&surat-jalan=${appState.pageJalan}&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                //...data
              },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageJalan: appState.pageJalan });
            });
        }
    }
    const onChangeSort = event =>  {
        const sorting = event.target.value
        setSorting(sorting);
        setAppState({ loading: true, pageJalan: appState.pageJalan });
        axios.post(`${API_STAGING}order/listing?status=packing&awb=yes&surat-jalan=${appState.pageJalan}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
            //...data
          },  {
            headers: authHeader()
        })
        .then((repos) => {
            const data = repos.data.data;
            setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageJalan: appState.pageJalan });
          });
    }
    const onChangeExpedition = event =>  {
        const expedition = event.target.value
        setExpedition(expedition);
        setAppState({ loading: true, pageJalan: appState.pageJalan });
        if (sorting == "asc" || sorting == "desc"){
            axios.post(`${API_STAGING}order/listing?status=packing&awb=yes&surat-jalan=${appState.pageJalan}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageJalan: appState.pageJalan });
            });
        } else {
            axios.post(`${API_STAGING}order/listing?status=packing&awb=yes&surat-jalan=${appState.pageJalan}&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageJalan: appState.pageJalan });
            });
        }
    }
    function onChangeDate (event,picker) {
        setAppState({loading: true, pageJalan: appState.pageJalan});
        const startDate = picker.startDate.format("YYYY-MM-DD")
        setStartDate(startDate);
        const endDate =  picker.endDate.format("YYYY-MM-DD")
        setEndDate(endDate);
        if (sorting == "asc" || sorting == "desc"){
            axios.post(`${API_STAGING}order/listing?status=packing&awb=yes&surat-jalan=${appState.pageJalan}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageJalan: appState.pageJalan });
            });
        } else {
            axios.post(`${API_STAGING}order/listing?status=packing&awb=yes&surat-jalan=${appState.pageJalan}&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageJalan: appState.pageJalan });
            });
        }
    }
    const handleCancel = (event, picker) => {
        setAppState({loading: true, pageJalan: appState.pageJalan});
        if (dateRangePickerRef.current) {
            const today = new Date();
            dateRangePickerRef.current.setStartDate(today);
            dateRangePickerRef.current.setEndDate(today);
        }
        const startDate = ''
        setStartDate(startDate);
        const endDate =  ''
        setEndDate(endDate);
        if (sorting == "asc" || sorting == "desc"){
            axios.post(`${API_STAGING}order/listing?status=packing&awb=yes&surat-jalan=${appState.pageJalan}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageJalan: appState.pageJalan });
            });
        } else {
            axios.post(`${API_STAGING}order/listing?status=packing&awb=yes&surat-jalan=${appState.pageJalan}&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageJalan: appState.pageJalan });
            });
        }
    }
    function clearFilter () {
        setAppState({loading: true, pageJalan: appState.pageJalan });
        const search = ''
        setSearchTerm(search);
        const sorting = ''
        setSorting(sorting);
        const expedition = ''
        setExpedition(expedition);
        const startDate = ''
        setStartDate(startDate);
        const endDate =  ''
        setEndDate(endDate);
        axios.post(`${API_STAGING}order/listing?status=packing&awb=yes&surat-jalan=${appState.pageJalan}&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&export-data=yes`, {
            //...data
          },  {
            headers: authHeader()
        })
        .then((repos) => {
            const data = repos.data.data;
            setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageJalan: appState.pageJalan });
        });
    }
    let start = startDate
    let end = endDate
    let labelDate = start + ' - ' + end;
    if (start === end) {
        labelDate = start;
    }
    let today = new Date();
    let dateNow = today.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'numeric', year: 'numeric'
      }).replace(/[^\d,.-]/g,'-');     
    let timeNow = today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();  
    function MovePrint() {
        appState.repos.forEach((result,index) =>{
            if(appState.repos[index].isChecked==true){
                setAppState({loading: true, disabled: true});
                    axios.post(`${API_STAGING}order/need-print?id=${appState.repos[index].id}`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.message;
                    swal({
                        title: "Done!",
                        text: data,
                        icon: "success",
                        timer: 3000,
                        button: false
                    })
                    setAppState({ loading: false, total: appState.total, current_page: appState.current_page, to: appState.to,pageJalan: appState.pageJalan });
                    onChangeSuratJalan({ pageJalan: appState.pageJalan})
                });
            }
        })
    }
    function MoveDelivery() {
        appState.repos.forEach((result,index) =>{
            if(appState.repos[index].isChecked==true){
                setAppState({loading: true, disabled: true});
                    axios.post(`${API_STAGING}order/to-ondelivery?id=${appState.repos[index].id}`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.message;
                    swal({
                        title: "Done!",
                        text: data,
                        icon: "success",
                        timer: 3000,
                        button: false
                    })
                    setAppState({ loading: false, total: appState.total, current_page: appState.current_page, to: appState.to,pageJalan: appState.pageJalan });
                    onChangeSuratJalan({ pageJalan: appState.pageJalan})
                });
            }
        })
    }
    function set_page(e){
        const selectedPage = e
        setPage(selectedPage);
        setAppState({ loading: true, pageJalan: appState.pageJalan });
        if (sorting == "asc" || sorting == "desc"){
            axios.post(`${API_STAGING}order/listing?status=packing&awb=yes&surat-jalan=${appState.pageJalan}&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=${selectedPage}&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageJalan: appState.pageJalan });
            });
        } else {
            axios.post(`${API_STAGING}order/listing?status=packing&awb=yes&surat-jalan=${appState.pageJalan}&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=${selectedPage}&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageJalan: appState.pageJalan });
            });
        }
    }
    function openScan() {
        setShow(true);
        setId('')
        setAppState({ loading: true, pageJalan: appState.pageJalan });
        if (sorting == "asc" || sorting == "desc"){
            axios.post(`${API_STAGING}order/listing?status=packing&awb=yes&surat-jalan=sorting&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=1&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageJalan: appState.pageJalan });
            });
        } else {
            axios.post(`${API_STAGING}order/listing?status=packing&awb=yes&surat-jalan=sorting&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=1&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageJalan: appState.pageJalan });
            });
        }
    }
    function closeScan() {
        setShow(false);
        setId('')
        setAppState({ loading: true, pageJalan: appState.pageJalan });
        if (sorting == "asc" || sorting == "desc"){
            axios.post(`${API_STAGING}order/listing?status=packing&awb=yes&surat-jalan=sorting&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=1&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageJalan: appState.pageJalan });
            });
        } else {
            axios.post(`${API_STAGING}order/listing?status=packing&awb=yes&surat-jalan=sorting&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=1&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageJalan: appState.pageJalan });
            });
        }
    }
    function openPrint() {
        setShowPrint(true);
        let arr =[];
        appState.repos.forEach((result,index) =>{
            if(appState.repos[index].isChecked==true){
                setAppState({loading: true, pageJalan: appState.pageJalan});
                    axios.post(`${API_STAGING}order/listing/${appState.repos[index].id}`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const detail = repos.data;
                    arr.push(detail)
                    setAppState({ loading: false, detail: arr,repos:appState.repos,  total:appState.total, pageJalan: appState.pageJalan});
                });
            }
        })
    }
    function closePrint () {
        setShowPrint(false);
        setAppState({ loading: true, pageJalan: appState.pageJalan });
        if (sorting == "asc" || sorting == "desc"){
            axios.post(`${API_STAGING}order/listing?status=packing&awb=yes&surat-jalan=need-print&search=${search}&per_page=${limit}&sorting=${sorting}&orderby=customer-name&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=1&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageJalan: appState.pageJalan });
            });
        } else {
            axios.post(`${API_STAGING}order/listing?status=packing&awb=yes&surat-jalan=need-print&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=1&export-data=yes`, {
                //...data
            },  {
                headers: authHeader()
            })
            .then((repos) => {
                const data = repos.data.data;
                setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageJalan: appState.pageJalan });
            });
        }
    }
    function printManifest() {
        appState.repos.forEach((result,index) =>{
            if(result.isChecked==true){
                setAppState({loading: true, disabled: true});
                    axios.post(`${API_STAGING}order/surat-jalan?id=${appState.repos[index].id}`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.message;
                    swal({
                        title: "Done!",
                        text: data,
                        icon: "success",
                        timer: 3000,
                        button: false
                      })
                      setAppState({ loading: false, total: appState.total, current_page: appState.current_page, to: appState.to,pageJalan: appState.pageJalan });
                      onChangeSuratJalan({ pageJalan: appState.pageJalan})
                });
            }
        })
    }
    function formatDate(e,type){
        var date = e;
        var tahun = date.getFullYear();
        var bulan = date.getMonth();
        var tanggal = date.getDate();
        var hari = date.getDay();
        var jam = date.getHours();
        var menit = ('0'+date.getMinutes()).slice(-2);
        var detik = date.getSeconds();
        switch(bulan) {
            case 0: bulan = "Januari"; break;
            case 1: bulan = "Februari"; break;
            case 2: bulan = "Maret"; break;
            case 3: bulan = "April"; break;
            case 4: bulan = "Mei"; break;
            case 5: bulan = "Juni"; break;
            case 6: bulan = "Juli"; break;
            case 7: bulan = "Agustus"; break;
            case 8: bulan = "September"; break;
            case 9: bulan = "Oktober"; break;
            case 10: bulan = "November"; break;
            case 11: bulan = "Desember"; break;
        }
        var tampilTanggal =  tanggal + " " + bulan + " " + tahun;
        var tampilWaktu = jam + "" + menit + "" + detik;
            if(type == 'waktu'){
                return tampilWaktu
            }else{

                return tampilTanggal
            }
    }
    useEffect(() => {
        setAppState({ loading: true, pageJalan: appState.pageJalan });
        axios.post(`${API_STAGING}order/listing?status=packing&awb=yes&surat-jalan=sorting&search=${search}&per_page=${limit}&sorting=${sorting}&expedition=${expedition}&start_date=${startDate}&end_date=${endDate}&page=1&export-data=yes`, {
            //...data
          },  {
            headers: authHeader()
        })
        .then((repos) => {
            const data = repos.data.data;
            setAppState({ loading: false, repos: data.data, total: data.total, current_page: data.current_page, to: data.to, pageJalan: appState.pageJalan });
          });
      }, [setAppState]);
    function save(e){
        setAppState({dat: e, repos: appState.repos, total: appState.total, current_page: appState.current_page, to: appState.to, pageJalan: appState.pageJalan})
    }
    class ComponentToPrint extends React.Component {
        render() {
            return (
                <div>
                    <div className="img-manifest">
                        <img src="/img/avoskin-logo.jpg" alt="Avoskin Logo" />
                    </div>
                    <div className="title-popup">Manifest</div>
                    <div className="table-top">
                    <div className="table-top-item">
                        <div className="label-title">ID Manifest</div>
                        <div className="label-title">:</div>
                        <div className="label-title">MNF - {formatDate(idManifest,'waktu')}</div>
                    </div>
                    <div className="table-top-item">
                        <div className="label-title">Tanggal Manifest</div>
                        <div className="label-title">:</div>
                        <div className="label-title">{formatDate(dateManifest,'tanggal')}</div>
                    </div>
                    </div>
                    <div className="table-popup table-data">
                        <div className="table-head">
                            <div>Kode Pesanan</div>
                            <div>Tanggal Pesanan</div>
                            <div>Pelanggan</div>
                            <div>Ekspedisi</div>
                            <div>Kode Ekspedisi</div>
                        </div>
                        {appState.detail.map((repo,index) => {
                            return (
                                <div className="table-body d-flex align-items-center">
                                    <div>
                                        <div className="label-desc green">{repo.data.invoice_number}</div>
                                    </div>
                                    <div>
                                        <div className="label-desc">{repo.data.shipping_label.date}</div>
                                    </div>
                                    <div>
                                        <span><img src="/img/ico-user.svg" alt="user" className="image-order" /></span>
                                        <span>{repo.data.customer_name}</span>
                                    </div>
                                    <div>
                                        <div className="courier-item align-items-center">
                                            <div className="courier-logo sm"><img src={repo.data.ekspedisi.image} alt={repo.data.ekspedisi.name} className="img-fluid" /></div>
                                            <div className="courier-service">{repo.data.ekspedisi.name}</div>
                                        </div>
                                    </div>
                                    <div>{repo.data.resi}</div>
                                </div>/* end of table-body */
                                );
                            })}
                    </div>
                    <div className="signature-wrapper">
                        <div className="signature-box">
                            <div className="label-title text-center">Kurir</div>
                            <div className="sign-base">(.............................................)</div>
                        </div>
                        <div className="signature-box">
                            <div className="label-title text-center">Admin</div>
                            <div className="sign-base">(.............................................)</div>
                        </div>
                    </div>{/* end of signature-wrapper */}    
                </div>
            );
        }
    }
    return ( 
        <section id="dashboard-wrapper">
            <div className="container-wrapper">
                <Sidebar />
                <section id="main-dashboard">
                    <Header />
                    <div className="content-wrapper tab-header">
                        <input defaultChecked="checked" id="tab1" type="radio" name="label" value="sorting" onChange={() => onChangeSuratJalan({ pageJalan: 'sorting'})}/>
                        <input id="tab2" type="radio" name="label" value="need-print" onChange={() => onChangeSuratJalan({ pageJalan: 'need-print'})}/>
                        <input id="tab3" type="radio" name="label" value="has-print" onChange={() => onChangeSuratJalan({ pageJalan: 'has-print'})}/>
                        <div className="tab-bar">
                            <label htmlFor="tab1" className="tab-bar-item tab1" name="label" value="sorting"><span className="tab-bar-name">Pengemasan</span></label>
                            <label htmlFor="tab2" className="tab-bar-item tab2" name="label" value="need-print"><span className="tab-bar-name">Print Surat Jalan</span></label>
                            <label htmlFor="tab3" className="tab-bar-item tab3" name="label" value="has-print"><span className="tab-bar-name">Tanpa Resi</span></label>
                        </div>
                        <div className="main-dash">
                            <div className="toolbar toolbar-surat-jalan">
                                <div className="toolbar-left" style={{flex: '0 0 20%'}}>
                                    <Formik
                                        initialValues={{ search: ''}}
                                        onSubmit={onChangeSearch}
                                    >
                                        {(formik) => {
                                        const {
                                        } = formik;
                                        return (
                                        <Form>
                                            <div className="form-group search-input">
                                                <label htmlFor="inputSearch" className="sr-only">Search</label>
                                                <input type="text" className="form-control" id="inputSearch" placeholder="Cari Pesanan" value={search} onChange={changeSearch} />
                                                <button className="btn btn-search" onClick={onChangeSearch}><img src="/img/ico-search.svg" alt="search" /></button>
                                            </div>
                                        </Form>
                                        );
                                        }}
                                    </Formik>
                                </div>
                                <div className="toolbar-right" style={{flex: '0 0 81%'}}>
                                    <div className="toolbar-inner-left" style={{flex: '0 0 auto'}}>
                                        <div className="toolicon-wrapper">
                                            <button id="show" className="toolicon sort" />
                                            <div id="test" className="tool-inner">
                                                <div className="tool-wrapper">
                                                    <div className="tool-heading">Urutkan</div>
                                                    <ul className="tool-select">
                                                        <button name='sorting' value='asc' onClick={onChangeSort} className = {sorting == 'asc' ? "tool-select-item active": "tool-select-item"}>Nama User A-Z</button>
                                                        <button name='sorting' value='desc' onClick={onChangeSort} className = {sorting == 'desc' ? "tool-select-item active": "tool-select-item"}>Nama User Z-A</button>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="toolicon-wrapper">
                                            <button id="show" className="toolicon filter" />
                                            <div className="tool-inner">
                                                <div className="tool-wrapper">
                                                    <div className="tool-heading">Filter Expedisi</div>
                                                    <div className="tool-scroll">
                                                        <ul className="tool-select">
                                                            <button name='expedition' value='jnt' onClick={onChangeExpedition} className = {expedition == 'jnt' ? "tool-select-item active": "tool-select-item"}><img src="/img/ico-ex-jnt.jpg" alt="J&T" />J&amp;T Express</button>
                                                            <button name='expedition' value='jne' onClick={onChangeExpedition} className = {expedition == 'jne' ? "tool-select-item active": "tool-select-item"}><img src="/img/ico-ex-jne.jpg" alt="JNE" />JNE Express</button>
                                                            <button name='expedition' value='sicepat' onClick={onChangeExpedition} className = {expedition == 'sicepat' ? "tool-select-item active": "tool-select-item"}><img src="/img/ico-ex-sicepat.jpg" alt="Sicepat" />Sicepat Express</button>
                                                            <button name='expedition' value='rpx' onClick={onChangeExpedition} className = {expedition == 'rpx' ? "tool-select-item active": "tool-select-item"}><img src="/img/rpx.png" alt="RPX" className="img-courir" />RPX One Stop Logistics</button>
                                                            <button name='expedition' value='pos' onClick={onChangeExpedition} className = {expedition == 'pos' ? "tool-select-item active": "tool-select-item"}><img src="/img/pos.png" alt="RPX" className="img-courir" />POS Indonesia</button>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tool-daterange daterange">
                                            <DateRangePicker
                                                ref={dateRangePickerRef}
                                                initialSettings={{
                                                    autoUpdateInput: false,
                                                    locale: {
                                                    cancelLabel: 'Clear',
                                                    },
                                                    maxDate : new Date().toLocaleDateString("en-US")
                                                }}
                                                autoUpdateInput={true}
                                                startDate={startDate}
                                                endDate={endDate}
                                                locale={{ format: "YYYY-MM-DD" }} 
                                                onCancel={handleCancel}
                                                onApply={onChangeDate}>
                                                <input type="text" className="form-control" value={labelDate} onChange={onChangeDate} placeholder="Tanggal Mulai - Tanggal Akhir"/>
                                            </DateRangePicker>
                                        </div>
                                        <div className="page-item">
                                            <form className="page-input">
                                                <div className="form-group mb-0">
                                                    <label htmlFor="page-count" className="sr-only">Page</label>
                                                    <input type="text" className="form-control" id="page-count" value={limit} onChange={onChangeLimit} />
                                                </div>
                                            </form>
                                            <div className="text-page">/ Page</div>
                                        </div>
                                        <div className="tool-export">
                                            <ReactHTMLTableToExcel
                                                id="test-table-xls-button"
                                                className="btn btn-secondary"
                                                table="table-to-xls"
                                                filename={"orders-"+dateNow+"-"+timeNow}
                                                sheet="Orders"
                                                buttonText="Export"/>
                                        </div>
                                        <div className="tool-clear">
                                            <button className="btn btn-secondary" onClick={clearFilter}>Clear</button>
                                        </div>
                                    </div>
                                    <div className="toolbar-inner-right justify-content-end" style={{flex: 'auto'}}>
                                    {(() => {
                                            if (appState.pageJalan == 'sorting') {
                                                return <div className="btn-two">
                                                    <button className="btn btn-blue btn-icon mr-2" onClick={MovePrint} disabled={appState.disabled} ><i className="icon-print" />{appState.disabled ? "Loading..." : "Pindah ke Surat Jalan"}</button>
                                                    <button className="btn btn-blue btn-icon" onClick={openScan}><img src="/img/ico-scan.svg" alt="scan" className="icon-scan" /> Scan Barcode</button>
                                                </div>;
                                            } else if (appState.pageJalan == 'need-print'){
                                                return <div><button className="btn btn-blue btn-icon" onClick={openPrint}><i className="icon-print" /> Print Surat Jalan</button></div>
                                            } else if (appState.pageJalan == 'has-print'){
                                                return <div><button className="btn btn-blue btn-icon" onClick={MoveDelivery} disabled={appState.disabled}><i className="icon-print" />{appState.disabled ? "Loading..." : "Pindah ke Delivery"}</button></div>
                                            }
                                    })()}
                                        <div id="popup-scan" className="overlay">
                                            <div className="popup">
                                                <Modal show={show} >
                                                    <button className="close hide-on-print" onClick={closeScan}><img src="/img/ico-close.svg" alt="close" /></button>
                                                    <div className="content">
                                                        <div className="img-scan">
                                                            <img src="/img/avo-scan.png" alt="Scan Barcode" />
                                                        </div>
                                                        <div className="scan-instruction">Arahkan laser scanner pada barcode yang tersedia pada kode pesanan</div>
                                                        <div className="scan-block">
                                                            <div className="scan-title">Kode Pesanan</div>
                                                            <div className="scan-sub-title">Arahkan kursor pada kolom di bawah</div>
                                                        </div>
                                                        {
                                                        error && (
                                                            <div className="alert alert-danger">
                                                                {error}
                                                            </div>
                                                        )
                                                    }
                                                    <Formik
                                                        initialValues={{ id: ''}}
                                                        validate={validate}
                                                        onSubmit={ScanBarcode}
                                                    >
                                                        {(formik) => {
                                                        const {
                                                            values,
                                                            isSubmitting,
                                                            handleChange,
                                                            handleSubmit,
                                                            errors,
                                                            touched,
                                                            handleBlur,
                                                            isValid,
                                                            dirty
                                                        } = formik;
                                                        return (
                                                        <Form className="form-scan">
                                                            <div className="form-group">
                                                                <label htmlFor="InputCode" className="form-label sr-only">Kode Pesanan</label>
                                                                <input type="text" className="form-control" id="InputCode" name="id" value={"AVS-"+id} onChange={onChangeId} onBlur={handleBlur} placeholder="Ketik kode pesanan" />
                                                                {errors.id && touched.id ? <span className="tooltiptext" >{errors.id}</span> : null}
                                                            </div>
                                                        <div className="scan-footer">
                                                            <button type="submit" style={{ color: 'white' }} disabled={errors.id  || !id} className="btn btn-primary">{appState.disabled ? "Loading..." : "Selesai"}</button>
                                                        </div>
                                                        </Form>
                                                        );
                                                        }}
                                                    </Formik>
                                                    </div>
                                                </Modal>
                                            </div>
                                        </div>{/* end of overlay */}

                                        <div id="popup-manifest" className="overlay">
                                        {(() => {
                                        if (appState.detail) {
                                            return  <div className="popup">
                                                        <Modal show={showPrint} className="popup-manifest">
                                                            <button className="close hide-on-print" onClick={closePrint}><img src="/img/ico-close.svg" alt="close" /></button>
                                                            <div className="content">    
                                                                <ComponentToPrint ref={componentRef} /> 
                                                                <div className="d-flex align-items-center justify-content-between mt-4">
                                                                    <button className="btn btn-blue btn-icon hide-on-print" onClick={printManifest}><i className="icon-print" /> Pindah ke Delivery</button>
                                                                    <ReactToPrint
                                                                    trigger={() =>  <button className="btn btn-blue btn-icon hide-on-print"><i className="icon-print" /> Print Label</button>}
                                                                    content={() =>componentRef.current}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Modal>
                                                    </div>;
                                                } 
                                            })()}
                                        </div>{/* end of overlay */}
                                            
                                    </div>
                                </div>
                            </div>{/* end of toolbar*/}
                            <ListLoading isLoading={appState.loading} data={appState.repos} total={appState.total} to={appState.to} current_page={appState.current_page} save={(e)=>save(e)} set_page={(e)=>set_page(e)} pageJalan={appState.pageJalan} />
                        </div>{/* end of main-dash*/}
                    </div>
                </section>
            </div>
        </section>
    );
}

export default SuratJalan;
