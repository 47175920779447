import React,{ useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { Modal } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { authHeader } from '../../_helpers';
import { API_STAGING } from '../../_settings'

const List = (props) => {
    const { data,total,save,set_page,current_page,last_page,total_not_read } = props;
    const [appState, setAppState]  = useState({
        dataState: data,
        total_not_read: total_not_read,
        loading: false
    });
    const handleCheckChieldElement = (e) => {
        setAppState({loading:true});
        axios.post(`${API_STAGING}notification/read/${e.id}`, {
            //...data
        },  {
            headers: authHeader()
        })
        .then((repos) => {
            const data = repos.data.message;
            if(repos.data.status == true) {
                window.location.assign(`/order/detail/${e.order_id}`)
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
                setAppState({dataState:appState.dataState, total_not_read:appState.total_not_read });
                window.location.reload()
            }
        });
    }
    const changePage = (e) => {
        const selectedPage = e.selected + 1;
        set_page(selectedPage)
        console.log(`active page is ${selectedPage}`);
    }
  return (   
    <div>
    {(() => {
        if (appState.loading == false){
        return <div className="list-setting">
            {appState.dataState && appState.dataState.length> 0 ? appState.dataState.map((repo,index) => {
                return (
                <div>
                    {(() => {
                    if (repo.is_read == false){ 
                        return <div onClick={()=>handleCheckChieldElement({id:repo.id, order_id:repo.order_id})} className="list-setting-item new" key={index}>
                            <div className="ls-item-01">{repo.date}</div>
                            <div className="ls-item-full">{repo.body}</div>
                        </div>
                    } else {
                        return <Link to={`/order/detail/${repo.order_id}`} className="list-setting-item" key={index}>
                            <div className="ls-item-01">{repo.date}</div>
                            <div className="ls-item-full">{repo.body}</div>
                        </Link>
                    }
                    })()}
                </div>
                );
            }): <div className="list-setting" style={{textAlign: 'center', marginTop: 120, marginBottom: 120}}>No data notification, sorry</div>}
            <div class="pagination">
                <ReactPaginate
                    pageCount={last_page??1}
                    pageRangeDisplayed={4}
                    marginPagesDisplayed={1}
                    forcePage={current_page - 1}
                    activeLinkClassName='active'
                    containerClassName='paging'
                    previousLabel='&laquo;'
                    nextLabel='&raquo;'
                    onPageChange={changePage}
                />
            </div>
        </div>   
        } else {
        return <div>
            <div className="credential-50">
                <div className="credential-box-noborder-nowidth">
                <div className="load-2">
                    <p>Loading... Please wait</p>
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
                </div>
            </div>
        </div>
        }
    })()}
    </div>
  );
}
export default List;