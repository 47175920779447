import React, { useEffect, useRef, useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Chart from 'react-apexcharts';
import { Formik, Form } from 'formik';
import axios from 'axios';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { authHeader } from '../../_helpers';
import { API_STAGING } from '../../_settings'
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import List from './List';
import Loading from '../../components/Loading';

function ProductPerformance() {
    const ListLoading                 = Loading(List);
    const [search, setSearchTerm] = useState('');
    const [sorting, setSorting]       = useState('');
    const [category, setCategory]     = useState('');
    const [startDate, setStartDate]   = useState(new Date().getFullYear() + "-" + ((new Date().getMonth() > 8) ? (new Date().getMonth() + 1) : ('0' + (new Date().getMonth() + 1))) + "-" + ((new Date().getDate() > 9) ? new Date().getDate() : ('0' + new Date().getDate())));
    const [endDate, setEndDate]       = useState(new Date().getFullYear() + "-" + ((new Date().getMonth() > 8) ? (new Date().getMonth() + 1) : ('0' + (new Date().getMonth() + 1))) + "-" + ((new Date().getDate() > 9) ? new Date().getDate() : ('0' + new Date().getDate())));
    const dateRangePickerRef          = useRef(null);
    const [limit, setLimit]           = useState('20');
    const [selectedPage, setPage]     = useState('1');
    const [appState, setAppState]     = useState({
        loading: false,
        showLoading: false,
        performance: null,
        data: null,
        status: 'Pilihan Status',
        disabled: false,
        pageStatus: 'any',
        repos: null,
        dat: null,
        total: null,
        last_page:null,
        product: null,
        current_page: null,
        category: null
    });
    const changeSearch = (e) => {
        const value = e.target.value
        setSearchTerm(value)
    }
    const onChangeSearch = (event) => {
        const data = {
            search: search,
        }
        setAppState({ loading: true, showLoading:false, category: appState.category, performance: appState.performance });
        axios.post(`${API_STAGING}product-performance-list/?start_date=${startDate}&end_date=${endDate}&per_page=${limit}&sorting=${sorting}&search=${search}&category=${category}`, {
            //...data
            },  {
            headers: authHeader()
        })
        .then((repos) => {
            const data = repos.data.data;
            setAppState({ loading: false, showLoading:false, data: data.data, total: data.total, current_page: data.current_page, last_page: data.last_page, category: appState.category, performance: appState.performance });
        });
    }
    const onChangeSort = event =>  {
        const sorting = event.target.value
        setSorting(sorting);
        setAppState({ loading: true, showLoading:false, category: appState.category, performance: appState.performance });
        axios.post(`${API_STAGING}product-performance-list/?start_date=${startDate}&end_date=${endDate}&per_page=${limit}&sorting=${sorting}&search=${search}&category=${category}`, {
            //...data
            },  {
            headers: authHeader()
        })
        .then((repos) => {
            const data = repos.data.data;
            setAppState({ loading: false, showLoading:false, data: data.data, total: data.total, current_page: data.current_page, last_page: data.last_page, category: appState.category, performance: appState.performance });
        });
    }
    const onChangeCategory = event =>  {
        const category = event.target.value
        setCategory(category);
        setAppState({ loading: true, showLoading:false, category: appState.category, performance: appState.performance });
        axios.post(`${API_STAGING}product-performance-list/?start_date=${startDate}&end_date=${endDate}&per_page=${limit}&sorting=${sorting}&search=${search}&category=${category}`, {
            //...data
            },  {
            headers: authHeader()
        })
        .then((repos) => {
            const data = repos.data.data;
            setAppState({ loading: false, showLoading:false, data: data.data, total: data.total, current_page: data.current_page, last_page: data.last_page, category: appState.category, performance: appState.performance });
        });
    }
    const onChangeLimit = (event) => {
        const limit = event.target.value
        setLimit(limit);
        setAppState({ loading: true, showLoading:false, category: appState.category, performance: appState.performance });
        axios.post(`${API_STAGING}product-performance-list/?start_date=${startDate}&end_date=${endDate}&per_page=${limit}&sorting=${sorting}&search=${search}&category=${category}`, {
            //...data
            },  {
            headers: authHeader()
        })
        .then((repos) => {
            const data = repos.data.data;
            setAppState({ loading: false, showLoading:false, data: data.data, total: data.total, current_page: data.current_page, last_page: data.last_page, category: appState.category, performance: appState.performance });
        });
    }
    function onChangeDate (event,picker) {
        setAppState({loading: true, showLoading:true, category: appState.category, performance: appState.performance, data: appState.data});
        const startDate = picker.startDate.format("YYYY-MM-DD")
        setStartDate(startDate);
        const endDate =  picker.endDate.format("YYYY-MM-DD")
        setEndDate(endDate);
        const fetchData = async () => {
        const respPerformance = await axios.post(
            `${API_STAGING}product-performance/?start_date=${startDate}&end_date=${endDate}`, {
                //...data
            },  {
                headers: authHeader()
            })
          const respProduct = await axios.post(
            `${API_STAGING}product-performance-list/?start_date=${startDate}&end_date=${endDate}&per_page=${limit}&sorting=${sorting}&search=${search}&category=${category}`, {
                //...data
            },  {
                headers: authHeader()
            })
            setAppState({ loading:false, showLoading: false, performance: respPerformance.data.data, data: respProduct.data.data.data, total: respProduct.data.data.total, current_page: respProduct.data.data.current_page, last_page: respProduct.data.data.last_page, category: appState.category });
        };
        fetchData();
    }
    const handleCancel = (event, picker) => {
        setAppState({loading: true, showLoading: true,  category: appState.category, performance: appState.performance});
        if (dateRangePickerRef.current) {
            const today = new Date();
            dateRangePickerRef.current.setStartDate(today);
            dateRangePickerRef.current.setEndDate(today);
        }
        const startDate = new Date().toISOString().slice(0, 10)
        setStartDate(startDate);
        const endDate =  new Date().toISOString().slice(0, 10)
        setEndDate(endDate);
        const fetchData = async () => {
        const respPerformance = await axios.post(
            `${API_STAGING}product-performance/?start_date=${startDate}&end_date=${endDate}`, {
                //...data
            },  {
                headers: authHeader()
            })
            const respProduct = await axios.post(
            `${API_STAGING}product-performance-list/?start_date=${startDate}&end_date=${endDate}&per_page=${limit}&sorting=${sorting}&search=${search}&category=${category}`, {
                //...data
            },  {
                headers: authHeader()
            })
            setAppState({ loading:false, showLoading: false, performance: respPerformance.data.data, data: respProduct.data.data.data, total: respProduct.data.data.total, current_page: respProduct.data.data.current_page, last_page: respProduct.data.data.last_page, category: appState.category });
        };
        fetchData();
    };
    function justNumbers(string) {
        var numsStr = string.replace(/\D/g,"");
        return parseInt(numsStr);
    }
    let start = startDate
    let end = endDate
    let labelDate = start + ' - ' + end;
    if (start === end) {
        labelDate = start;
    }
    const today = new Date();
    let dateNow = today.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'numeric', year: 'numeric'
      }).replace(/[^\d,.-]/g,'-');     
    let timeNow = today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
    function set_page(e){
        const selectedPage = e
        setPage(selectedPage);
        setAppState({ loading: true, showLoading: false, category: appState.category, performance: appState.performance });
        axios.post(`${API_STAGING}product-performance-list/?start_date=${startDate}&end_date=${endDate}&per_page=${limit}&page=${selectedPage}&sorting=${sorting}&search=${search}&category=${category}`, {
            //...data
            },  {
            headers: authHeader()
        })
        .then((repos) => {
            const data = repos.data.data;
            setAppState({ loading: false, showLoading: false, data: data.data, total: data.total, current_page: data.current_page, last_page: data.last_page, category: appState.category, performance: appState.performance });
        });
    }
    useEffect(() => {
        setAppState({ loading: true, showLoading:true, category: appState.category});
        const fetchData = async () => {
        const respPerformance = await axios.post(
            `${API_STAGING}product-performance/?start_date=${startDate}&end_date=${endDate}`, {
                //...data
            },  {
                headers: authHeader()
            })
          const respProduct = await axios.post(
            `${API_STAGING}product-performance-list/?start_date=${startDate}&end_date=${endDate}&per_page=${limit}&page=${selectedPage}&sorting=${sorting}&search=${search}&category=${category}`, {
                //...data
            },  {
                headers: authHeader()
            })
          const respCategory = await axios.get(
            `${API_STAGING}product-category`, {
                headers: authHeader()
            })
            setAppState({ loading: false, showLoading: false, performance: respPerformance.data.data, data: respProduct.data.data.data, total: respProduct.data.data.total, current_page: respProduct.data.data.current_page, last_page: respProduct.data.data.last_page, category: respCategory.data.data });
        };
        fetchData();
      }, []);

    function clearFilter () {
        setAppState({loading: true, showLoading: false, category: appState.category, performance: appState.performance});
        const search = ''
        setSearchTerm(search);
        const sorting = ''
        setSorting(sorting);
        const category = ''
        setCategory(category);
        axios.post(`${API_STAGING}product-performance-list/?start_date=${startDate}&end_date=${endDate}&per_page=${limit}&sorting=${sorting}&search=${search}&category=${category}`, {
            //...data
            },  {
            headers: authHeader()
        })
        .then((repos) => {
            const data = repos.data.data;
            setAppState({ loading: false, showLoading: false, data: data.data, total: data.total, current_page: data.current_page, last_page: data.last_page, category: appState.category, performance: appState.performance });
        });
    }
    function currencyFormat(num) {
        return 'Rp ' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }
    return ( 
        <section id="dashboard-wrapper">
            <div className="container-wrapper">
                <Sidebar />
                <section id="main-dashboard">
                    <Header />
                    <div className="content-wrapper">
                        <div className="main-dash">
                            <div className="position-relative">
                                <div className="tool-daterange-dark">
                                    <div className="tool-daterange">
                                        <DateRangePicker
                                            ref={dateRangePickerRef}
                                            initialSettings={{
                                                autoUpdateInput: false,
                                                locale: {
                                                cancelLabel: 'Clear',
                                                },
                                                maxDate : new Date().toLocaleDateString("en-US")
                                            }}
                                            autoUpdateInput={true}
                                            startDate={startDate}
                                            endDate={endDate}
                                            locale={{ format: "YYYY-MM-DD" }} 
                                            onCancel={handleCancel}
                                            onApply={onChangeDate}>
                                            <button className="daterange" style={{maxWidth: 'unset'}}>
                                                {`${startDate} - ${endDate}`}
                                            </button>
                                        </DateRangePicker>
                                    </div>
                                </div>
                                <div className="tool-export floating">
                                    <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="btn btn-secondary"
                                        table="table-to-xls"
                                        filename={"product-perfromance-"+dateNow+"-"+timeNow}
                                        sheet="Orders"
                                        buttonText="Export"/>
                                </div>
                                {(() => {
                                    if (appState.data) {
                                    return <table id="table-to-xls" className="hide-table">
                                        <thead>
                                        <tr>
                                            <th>Order Saat ini</th>
                                            <th>Order Periode Sebelumnya</th>
                                            <th>Traffic Order</th>
                                            <th>Penjualan Produk Saat ini</th>
                                            <th>Penjualan Produk Periode Sebelumnya</th>
                                            <th>Traffic Penjualan Produk</th>
                                            <th>Produk Terjual Saat ini</th>
                                            <th>Produk Terjual Periode Sebelumnya</th>
                                            <th>Traffic Produk Terjual</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{appState.performance.total_order}</td>
                                                <td>{appState.performance.previous_total_order}</td>
                                                {(() => {
                                                    if (appState.performance.total_order < appState.performance.previous_total_order) {
                                                        if (appState.performance.traffic_total_order_percentage == 100 || appState.performance.traffic_total_order_percentage == null) {
                                                            return  <td>Menurun {appState.performance.traffic_total_order_percentage}%</td>
                                                        }  else {
                                                            return  <td>Menurun {100-appState.performance.traffic_total_order_percentage}%</td>
                                                        }
                                                    } else {    
                                                        if (appState.performance.traffic_total_order_percentage == 100 || appState.performance.traffic_total_order_percentage == null) {
                                                            return  <td>Meningkat {appState.performance.traffic_total_order_percentage}%</td>
                                                        } else {
                                                            return  <td>Meningkat {appState.performance.traffic_total_order_percentage-100}%</td>
                                                        }
                                                    }
                                                })()}
                                                <td>{appState.performance.total_penjualan}</td>
                                                <td>{appState.performance.previous_total_penjualan}</td>
                                                {(() => {
                                                    if (appState.performance.total_penjualan != null && appState.performance.previous_total_penjualan != null) {
                                                        if (justNumbers(appState.performance.total_penjualan) < justNumbers(appState.performance.previous_total_penjualan)) {
                                                            if (appState.performance.traffic_total_penjualan_percentage == 100 || appState.performance.traffic_total_penjualan_percentage == null) {
                                                                return  <td>Menurun {appState.performance.traffic_total_penjualan_percentage}%</td>
                                                            }  else {
                                                                return  <td>Menurun {100-appState.performance.traffic_total_penjualan_percentage}%</td>
                                                            }
                                                        } else {
                                                            if (appState.performance.traffic_total_penjualan_percentage == 100 || appState.performance.traffic_total_penjualan_percentage == null) {
                                                                return  <td>Meningkat {appState.performance.traffic_total_penjualan_percentage}%</td>
                                                            } else {
                                                                return  <td>Meningkat {appState.performance.traffic_total_penjualan_percentage-100}%</td>
                                                            }
                                                        }
                                                    } else  if (appState.performance.total_penjualan != null && appState.performance.previous_total_penjualan == null) {
                                                        return  <td>Meningkat {appState.performance.traffic_total_penjualan_percentage ?? 0}%</td>
                                                    } else  if (appState.performance.total_penjualan == null && appState.performance.previous_total_penjualan != null) {
                                                        return  <td>Menurun {appState.performance.traffic_total_penjualan_percentage ?? 0}%</td>
                                                    } else  if (appState.performance.total_penjualan == null && appState.performance.previous_total_penjualan == null) {
                                                        return  <td>Meningkat {appState.performance.traffic_total_penjualan_percentage ?? 0}%</td>
                                                    }
                                                })()}
                                                <td>{appState.performance.total_prod_terjual}</td>
                                                <td>{appState.performance.previous_total_prod_terjual}</td>
                                                {(() => {
                                                    if (appState.performance.total_prod_terjual < appState.performance.previous_total_prod_terjual) {
                                                        if (appState.performance.traffic_total_prod_terjual_percentage == 100 || appState.performance.traffic_total_prod_terjual_percentage == null) {
                                                            return  <td>Menurun {appState.performance.traffic_total_prod_terjual_percentage}%</td>
                                                        }  else {
                                                            return  <td>Menurun {100-appState.performance.traffic_total_prod_terjual_percentage}%</td>
                                                        }
                                                    } else {
                                                        if (appState.performance.traffic_total_prod_terjual_percentage == 100 || appState.performance.traffic_total_prod_terjual_percentage == null) {
                                                            return  <td>Meningkat {appState.performance.traffic_total_prod_terjual_percentage}%</td>
                                                        }  else {
                                                            return  <td>Meningkat {appState.performance.traffic_total_prod_terjual_percentage-100}%</td>
                                                        }
                                                    }
                                                })()} 
                                            </tr>
                                        </tbody>
                                        <thead>
                                        <tr>
                                            <th>Periode</th>
                                            <th>Total Order</th>
                                            <th>Total Penjualan Produk</th>
                                            <th>Total Produk Terjual</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {appState.performance.trends.map(item => (
                                            <tr>
                                                <td>{item.start_date}</td>
                                                <td>{item.total_order}</td>
                                                <td>{currencyFormat(item.total_penjualan)}</td>
                                                <td>{item.total_prod_terjual}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                        <tr></tr><tr></tr>
                                        <thead>
                                        <tr>
                                            <th>Nama Produk</th>
                                            <th>Produk Dibayar</th>
                                            <th>Produk Siap Dikirim</th>
                                            <th>Produk Terjual</th>
                                            <th>Total Pesanan</th>
                                            <th>Penjualan</th>
                                            <th>Sisa Stock</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {appState.data.map(item => (
                                            <tr>
                                                <td>{item.product_name}</td>
                                                <td>{item.paid_product}</td>
                                                <td>{item.shipped_product}</td>
                                                <td>{item.ordered_product}</td>
                                                <td>{item.total_order}</td>
                                                <td>{item.total_price_text}</td>
                                                <td>{item.left_stock}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    }
                                })()} 
                            </div>
                            <div className="tab-header">
                                <input defaultChecked="checked" id="tab1" type="radio" name="avo-tab" />
                                <input id="tab2" type="radio" name="avo-tab" />
                                <input id="tab3" type="radio" name="avo-tab" />
                                <div className="row">
                                <div className="col-4">
                                    <label htmlFor="tab1" className="tab-bar-item tab1 w-100">
                                    {(() => {
                                    if (appState.performance && appState.showLoading == false) {
                                    return <div className="white-box box-hover">
                                            <h3 className="heading-box lg">Order</h3>
                                            <div className="d-flex align-items-center justify-content-between my-3">
                                                <div className="heading-number">{appState.performance.total_order ?? 0}</div>
                                                <div className="heading-number">
                                                {(() => {
                                                    if (appState.performance.total_order < appState.performance.previous_total_order) {
                                                        if (appState.performance.traffic_total_order_percentage == 100 || appState.performance.traffic_total_order_percentage == null) {
                                                            return  <span className="trend trend-down">{appState.performance.traffic_total_order_percentage ?? 0}%</span>
                                                        }  else {
                                                            return  <span className="trend trend-down">{100-appState.performance.traffic_total_order_percentage ?? 0}%</span>
                                                        }
                                                    } else {
                                                        if (appState.performance.traffic_total_order_percentage == 100 || appState.performance.traffic_total_order_percentage == null) {
                                                            return  <span className="trend trend-up">{appState.performance.traffic_total_order_percentage ?? 0}%</span>
                                                        } else {
                                                            return  <span className="trend trend-up">{appState.performance.traffic_total_order_percentage-100 ?? 0}%</span>
                                                        }
                                                    }
                                                })()}     
                                                </div>
                                            </div>
                                            <div className="label-box grey">Periode Sebelumnya <span className="text-blue pl-2">{appState.performance.previous_total_order ?? 0}</span></div>
                                            <div className="tool-tip-box">
                                                <span className="tool-tip">
                                                <button className="tool-tip-icon">?</button>
                                                <div className="tool-tip-content right">
                                                    Data total Order diambil dari pesanan yang sudah dibayarkan oleh customer pada periode tersebut. Persentase kenaikan pesanan di ambil dari jumlah pesanan pada periode tersebut dibagi jumlah pesanan pada periode sebelumnya dikali 100%
                                                </div>
                                                </span>
                                            </div>
                                        </div>
                                    } else {
                                    return <div className="white-box box-hover">
                                        <div className="load-performance">
                                            <div className="load-2">
                                                <p>Loading... Please wait</p>
                                                <div class="line"></div>
                                                <div class="line"></div>
                                                <div class="line"></div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    })()}
                                    </label>
                                </div>
                                <div className="col-4">
                                    <label htmlFor="tab2" className="tab-bar-item tab2 w-100">
                                    {(() => {
                                    if (appState.performance && appState.showLoading == false) {
                                    return <div className="white-box box-hover">
                                            <h3 className="heading-box lg">Penjualan Produk</h3>
                                            <div className="d-flex align-items-center justify-content-between my-3">
                                                <div className="heading-number">{appState.performance.total_penjualan ?? "Rp 0"}</div>
                                                <div className="heading-number">
                                                {(() => {
                                                    if (appState.performance.total_penjualan != null && appState.performance.previous_total_penjualan != null) {
                                                        if (justNumbers(appState.performance.total_penjualan) < justNumbers(appState.performance.previous_total_penjualan)) {
                                                            if (appState.performance.traffic_total_penjualan_percentage == 100 || appState.performance.traffic_total_penjualan_percentage == null) {
                                                                return  <span className="trend trend-down">{appState.performance.traffic_total_penjualan_percentage}%</span>
                                                            }  else {
                                                                return  <span className="trend trend-down">{100-appState.performance.traffic_total_penjualan_percentage}%</span>
                                                            }
                                                        } else {
                                                            if (appState.performance.traffic_total_penjualan_percentage == 100 || appState.performance.traffic_total_penjualan_percentage == null) {
                                                                return  <span className="trend trend-up">{appState.performance.traffic_total_penjualan_percentage}%</span>
                                                            } else {
                                                                return  <span className="trend trend-up">{appState.performance.traffic_total_penjualan_percentage-100}%</span>
                                                            }
                                                        }
                                                    } else  if (appState.performance.total_penjualan != null && appState.performance.previous_total_penjualan == null) {
                                                        return  <span className="trend trend-up">{appState.performance.traffic_total_penjualan_percentage ?? 0}%</span>
                                                    } else  if (appState.performance.total_penjualan == null && appState.performance.previous_total_penjualan != null) {
                                                        return  <span className="trend trend-down">{appState.performance.traffic_total_penjualan_percentage ?? 0}%</span>
                                                    } else  if (appState.performance.total_penjualan == null && appState.performance.previous_total_penjualan == null) {
                                                        return  <span className="trend trend-up">{appState.performance.traffic_total_penjualan_percentage ?? 0}%</span>
                                                    }
                                                })()} 
                                                </div>  
                                            </div>
                                            <div className="label-box grey">Periode Sebelumnya<span className="text-blue pl-2">{appState.performance.previous_total_penjualan ?? "Rp 0"}</span></div>
                                            <div className="tool-tip-box">
                                                <span className="tool-tip">
                                                <button className="tool-tip-icon">?</button>
                                                <div className="tool-tip-content right">
                                                    Data total penjualan produk (Rp.). diambil dari jumlah keseluruhan produk yang terjual (Rp.) pada periode tersebut. Persentase penjualan pesanan di ambil dari total penjualan pada periode tersebut dibagi jumlah penjualan pada periode sebelumnya dikali 100%
                                                </div>
                                                </span>
                                            </div>
                                        </div>
                                    } else {
                                    return <div className="white-box box-hover">
                                        <div className="load-performance">
                                            <div className="load-2">
                                                <p>Loading... Please wait</p>
                                                <div class="line"></div>
                                                <div class="line"></div>
                                                <div class="line"></div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    })()}
                                    </label>
                                </div>
                                <div className="col-4">
                                    <label htmlFor="tab3" className="tab-bar-item tab3 w-100">
                                    {(() => {
                                    if (appState.performance && appState.showLoading == false) {
                                    return <div className="white-box box-hover">
                                            <h3 className="heading-box lg">Produk Terjual</h3>
                                            <div className="d-flex align-items-center justify-content-between my-3">
                                                <div className="heading-number">{appState.performance.total_prod_terjual ?? 0}</div>
                                                <div className="heading-number">
                                                {(() => {
                                                    if (appState.performance.total_prod_terjual < appState.performance.previous_total_prod_terjual) {
                                                        if (appState.performance.traffic_total_prod_terjual_percentage == 100 || appState.performance.traffic_total_prod_terjual_percentage == null) {
                                                            return  <span className="trend trend-down">{appState.performance.traffic_total_prod_terjual_percentage ?? 0}%</span>
                                                        }  else {
                                                            return  <span className="trend trend-down">{100-appState.performance.traffic_total_prod_terjual_percentage ?? 0}%</span>
                                                        }
                                                    } else {
                                                        if (appState.performance.traffic_total_prod_terjual_percentage == 100 || appState.performance.traffic_total_prod_terjual_percentage == null) {
                                                            return  <span className="trend trend-up">{appState.performance.traffic_total_prod_terjual_percentage ?? 0}%</span>
                                                        }  else {
                                                            return  <span className="trend trend-up">{appState.performance.traffic_total_prod_terjual_percentage-100 ?? 0}%</span>
                                                        }
                                                    }
                                                })()}
                                                </div>
                                            </div>
                                            <div className="label-box grey">Periode Sebelumnya<span className="text-blue pl-2">{appState.performance.previous_total_prod_terjual ?? 0}</span></div>
                                            <div className="tool-tip-box">
                                                <span className="tool-tip">
                                                <button className="tool-tip-icon">?</button>
                                                <div className="tool-tip-content right">
                                                    Data total produk terjual diambil dari jumlah keseluruhan produk yang terjual pada periode tersebut. Persentase kenaik produk terjual di ambil dari jumlah produk terjual pada periode tersebut dibagi jumlah produk terjual pada periode sebelumnya dikali 100%
                                                </div>
                                                </span>
                                            </div>
                                        </div>
                                    } else {
                                    return <div className="white-box box-hover">
                                        <div className="load-performance">
                                            <div className="load-2">
                                                <p>Loading... Please wait</p>
                                                <div class="line"></div>
                                                <div class="line"></div>
                                                <div class="line"></div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    })()} 
                                    </label>
                                </div>
                                </div>
                                <section className="tab-container">
                                <div className="tabs tab1">
                                    <div className="row">
                                        <div className="col">
                                            <div className="white-box" style={{padding: '16px 6px 16px 6px'}}>
                                                <span className="tool-tip floating" style={{top: '16px'}}>
                                                    <button className="tool-tip-icon">?</button>
                                                    <div className="tool-tip-content right tool-tip-chart">
                                                        Grafik tren order 
                                                    </div>
                                                </span>
                                                <div className="text-center">
                                                    <h3 className="heading-box">Tren Order</h3>
                                                </div>
                                                <div className="box-statistic">
                                                {appState.showLoading ? (
                                                        <div style={{
                                                            height: 320,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}>
                                                            <div className="load-2">
                                                                <p>Loading... Please wait</p>
                                                                <div class="line"></div>
                                                                <div class="line"></div>
                                                                <div class="line"></div>
                                                            </div>
                                                        </div>
                                                    ) : appState.performance && Array.isArray(appState.performance.trends) && (
                                                        <Chart
                                                            type="line"
                                                            height={320}
                                                            options={{
                                                                labels: appState.performance.trends.map(item => {
                                                                    if (item.start_date === item.end_date) {
                                                                        return item.start_date.slice(5)
                                                                    }
                                                                    return `${item.start_date.slice(5)} - ${item.end_date.slice(5)}`
                                                                }),
                                                                annotations: {
                                                                    yaxis: [
                                                                        {
                                                                            y: 600000000000000000000,
                                                                            borderColor: '#982c2c',
                                                                            strokeDashArray: 10,
                                                                        }
                                                                    ]
                                                                },
                                                                yaxis: {
                                                                    min: 0,
                                                                    max: Math.ceil(
                                                                        appState.performance.trends.reduce(
                                                                            (max, item) => item.total_order > max ? item.total_order : max, 10
                                                                        ) / 5
                                                                    ) * 5,
                                                                    tickAmount: 5,
                                                                    labels: {
                                                                        formatter: (val) => `${val}`,
                                                                        offsetX: -12,
                                                                        minWidth: 42,
                                                                        style: {
                                                                            colors: '#626262',
                                                                            fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                            fontSize: 12,
                                                                        }
                                                                    }
                                                                },
                                                                xaxis: {
                                                                    labels: {
                                                                        style: {
                                                                            colors: '#626262',
                                                                            fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                            fontSize: 12,
                                                                        }
                                                                    }
                                                                },
                                                                stroke: {
                                                                    width: 2,
                                                                    colors: '#e0ead8',
                                                                },
                                                                markers: {
                                                                    size: 5,
                                                                },
                                                                tooltip: {
                                                                    x: {show: false}
                                                                },
                                                                colors: ['#61805b'],
                                                            }}
                                                            series={[{ name: 'Order', data: appState.performance.trends.map(item => item.total_order) }]}
                                                        />
                                                    )}
                                                </div>
                                            </div>{/* end of white-box */}
                                        </div>
                                    </div>
                                </div>
                                <div className="tabs tab2">
                                    <div className="row">
                                        <div className="col">
                                            <div className="white-box" style={{padding: '16px 6px 16px 6px'}}>
                                                <span className="tool-tip floating" style={{top: '16px'}}>
                                                    <button className="tool-tip-icon">?</button>
                                                    <div className="tool-tip-content right tool-tip-chart">
                                                        Grafik penjualan produk
                                                    </div>
                                                </span>
                                                <div className="text-center">
                                                    <h3 className="heading-box">Tren Penjualan Produk</h3>
                                                </div>
                                                <div className="box-statistic">
                                                {appState.showLoading ? (
                                                        <div style={{
                                                            height: 320,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}>
                                                            <div className="load-2">
                                                                <p>Loading... Please wait</p>
                                                                <div class="line"></div>
                                                                <div class="line"></div>
                                                                <div class="line"></div>
                                                            </div>
                                                        </div>
                                                    ) : appState.performance && Array.isArray(appState.performance.trends) && (
                                                        <Chart
                                                            type="line"
                                                            height={320}
                                                            options={{
                                                                labels: appState.performance.trends.map(item => {
                                                                    if (item.start_date === item.end_date) {
                                                                        return item.start_date.slice(5)
                                                                    }
                                                                    return `${item.start_date.slice(5)} - ${item.end_date.slice(5)}`
                                                                }),
                                                                annotations: {
                                                                    yaxis: [
                                                                        {
                                                                            y: 600000000000000000000,
                                                                            borderColor: '#982c2c',
                                                                            strokeDashArray: 10,
                                                                        }
                                                                    ]
                                                                },
                                                                yaxis: {
                                                                    min: 0,
                                                                    max: Math.ceil(
                                                                        appState.performance.trends.reduce(
                                                                            (max, item) => item.total_penjualan > max ? item.total_penjualan : max, 10
                                                                        ) / 5
                                                                    ) * 5,
                                                                    tickAmount: 5,
                                                                    labels: {
                                                                        formatter: (val) => `${currencyFormat(val)}`,
                                                                        offsetX: -12,
                                                                        minWidth: 42,
                                                                        style: {
                                                                            colors: '#626262',
                                                                            fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                            fontSize: 12,
                                                                        }
                                                                    }
                                                                },
                                                                xaxis: {
                                                                    labels: {
                                                                        style: {
                                                                            colors: '#626262',
                                                                            fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                            fontSize: 12,
                                                                        }
                                                                    }
                                                                },
                                                                stroke: {
                                                                    width: 2,
                                                                    colors: '#e0ead8',
                                                                },
                                                                markers: {
                                                                    size: 5,
                                                                },
                                                                tooltip: {
                                                                    x: {show: false}
                                                                },
                                                                colors: ['#61805b'],
                                                            }}
                                                            series={[{ name: 'Penjualan Produk', data: appState.performance.trends.map(item => item.total_penjualan) }]}
                                                        />
                                                    )}
                                                </div>
                                            </div>{/* end of white-box */}
                                        </div>
                                    </div>
                                </div>
                                <div className="tabs tab3">
                                    <div className="row">
                                        <div className="col">
                                            <div className="white-box" style={{padding: '16px 6px 16px 6px'}}>
                                                <span className="tool-tip floating" style={{top: '16px'}}>
                                                    <button className="tool-tip-icon">?</button>
                                                    <div className="tool-tip-content right tool-tip-chart">
                                                        Grafik produk terjual
                                                    </div>
                                                </span>
                                                <div className="text-center">
                                                    <h3 className="heading-box">Tren Produk Terjual</h3>
                                                </div>
                                                <div className="box-statistic">
                                                    {appState.showLoading ? (
                                                        <div style={{
                                                            height: 320,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}>
                                                            <div className="load-2">
                                                                <p>Loading... Please wait</p>
                                                                <div class="line"></div>
                                                                <div class="line"></div>
                                                                <div class="line"></div>
                                                            </div>
                                                        </div>
                                                    ) : appState.performance && Array.isArray(appState.performance.trends) && (
                                                        <Chart
                                                            type="line"
                                                            height={320}
                                                            options={{
                                                                labels: appState.performance.trends.map(item => {
                                                                    if (item.start_date === item.end_date) {
                                                                        return item.start_date.slice(5)
                                                                    }
                                                                    return `${item.start_date.slice(5)} - ${item.end_date.slice(5)}`
                                                                }),
                                                                annotations: {
                                                                    yaxis: [
                                                                        {
                                                                            y: 600000000000000000000,
                                                                            borderColor: '#982c2c',
                                                                            strokeDashArray: 10,
                                                                        }
                                                                    ]
                                                                },
                                                                yaxis: {
                                                                    min: 0,
                                                                    max: Math.ceil(
                                                                        appState.performance.trends.reduce(
                                                                            (max, item) => item.total_prod_terjual > max ? item.total_prod_terjual : max, 10
                                                                        ) / 5
                                                                    ) * 5,
                                                                    tickAmount: 5,
                                                                    labels: {
                                                                        formatter: (val) => `${val}`,
                                                                        offsetX: -12,
                                                                        minWidth: 42,
                                                                        style: {
                                                                            colors: '#626262',
                                                                            fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                            fontSize: 12,
                                                                        }
                                                                    }
                                                                },
                                                                xaxis: {
                                                                    labels: {
                                                                        style: {
                                                                            colors: '#626262',
                                                                            fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                            fontSize: 12,
                                                                        }
                                                                    }
                                                                },
                                                                stroke: {
                                                                    width: 2,
                                                                    colors: '#e0ead8',
                                                                },
                                                                markers: {
                                                                    size: 5,
                                                                },
                                                                tooltip: {
                                                                    x: {show: false}
                                                                },
                                                                colors: ['#61805b'],
                                                            }}
                                                            series={[{ name: 'Produk Terjual', data: appState.performance.trends.map(item => item.total_prod_terjual) }]}
                                                        />
                                                    )}
                                                </div>
                                            </div>{/* end of white-box */}
                                        </div>
                                    </div>
                                </div>
                                </section>
                            </div>
                            <div className="toolbar">
                                <div className="toolbar-left" style={{flex: '0 0 22%'}}>
                                    <Formik
                                        initialValues={{ search: ''}}
                                        onSubmit={onChangeSearch}
                                    >
                                        {(formik) => {
                                        const {
                                        } = formik;
                                        return (
                                        <Form>
                                            <div className="form-group search-input">
                                                <label htmlFor="inputSearch" className="sr-only">Search</label>
                                                <input type="text" className="form-control" id="inputSearch" placeholder="Cari Produk" value={search} onChange={changeSearch} />
                                                <button className="btn btn-search" onClick={onChangeSearch}><img src="/img/ico-search.svg" alt="search" /></button>
                                            </div>
                                        </Form>
                                        );
                                        }}
                                    </Formik>
                                </div>
                                <div className="toolbar-right" style={{flex: '0 0 78%'}}>
                                    <div className="toolbar-inner-left" style={{flex: '0 0 auto'}}>
                                        <div className="toolicon-wrapper">
                                            <button id="show" className="toolicon sort" />
                                            <div id="test" className="tool-inner">
                                            <div className="tool-wrapper">
                                                <div className="tool-heading">Urutkan</div>
                                                <ul className="tool-select">
                                                    <button name='sorting' value='asc' onClick={onChangeSort} className = {sorting == 'asc' ? "tool-select-item active": "tool-select-item"}>Jumlah Produk Dipesan Terkecil</button>
                                                    <button name='sorting' value='desc' onClick={onChangeSort} className = {sorting == 'desc' ? "tool-select-item active": "tool-select-item"}>Jumlah Produk Dipesan Terbesar</button>
                                                </ul>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="toolicon-wrapper">
                                            <button id="show" className="toolicon filter" />
                                            <div className="tool-inner">
                                            <div className="tool-wrapper">
                                                <div className="tool-heading">Filter Kategori</div>
                                                <div className="tool-scroll">
                                                {(() => {
                                                    if (appState.category) {
                                                    return <ul className="tool-select">
                                                    {appState.category.map((item ,index) => {
                                                        return (
                                                        <div key={index}>
                                                            <button name='category' value={item.id} onClick={onChangeCategory} className = {category == `${item.id}` ? "tool-select-item active": "tool-select-item"}>{item.name}</button>
                                                        </div>
                                                        );
                                                    })}
                                                    </ul>
                                                    } else {
                                                        return  <ul className="tool-select">
                                                                <div className="credential-50">
                                                                    <div className="credential-box-noborder-nowidth">
                                                                    <div className="load-2">
                                                                        <p>Loading... Please wait</p>
                                                                        <div class="line"></div>
                                                                        <div class="line"></div>
                                                                        <div class="line"></div>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </ul>;
                                                    }
                                                })()}   
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="page-item mr-3">
                                            <form className="page-input-product">
                                                <div className="form-group mb-0">
                                                    <label htmlFor="page-count" className="sr-only">Page</label>
                                                    <input type="text" className="form-control" id="page-count" value={limit} onChange={onChangeLimit} />
                                                </div>
                                            </form>
                                            <div className="text-page">/ Page</div>
                                        </div>
                                        <div className="toolicon-wrapper">
                                            <div className="tool-clear mr-0">
                                                <button className="btn btn-secondary" onClick={clearFilter}>Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>{/* end of toolbar*/}
                            <ListLoading isLoading={appState.loading} data={appState.data}  total={appState.total} last_page={appState.last_page} current_page={appState.current_page} set_page={(e)=>set_page(e)}  />
                        </div>{/* end of main-dash*/}
                    </div>
                </section>
            </div>
        </section>
    );
}


export default ProductPerformance;