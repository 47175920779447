import React, { useState, useRef }  from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import Barcode from 'react-barcode';
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import '../../../_styles/PrintA6.css';
import { authHeader } from '../../../_helpers';
import { API_STAGING } from '../../../_settings';
import he from 'he';

const List = (props) => {
    const { data,product,history_order,tracking,isLoading } = props;
    const componentRef = useRef();
    const [appState, setAppState]  = useState({
        data: 1,
        viewAll: false,
        active: '',
        disabled: false,
        show: false,
        formatBarcode: 'pharmacode',
        status:'Pilihan Status'
    });
    
    const handleClass = status => appState.status === status ? "tool-select-item active" : "tool-select-item";
    
    function getRenderedItems (){
        if(appState.viewAll){
            return tracking 
        }else{
            return tracking.slice(0,3);
        }
    }

    function change () {
        setAppState({ viewAll: !appState.viewAll});
    }

    function ChangeStatus() {
        setAppState({ disabled: true});
        axios.post(`${API_STAGING}order/status?id=${data.id}&status=${appState.status}`, {
            //...data
        },  {
            headers: authHeader()
        })
        .then((repos) => {
            const data = repos.data.message;
            swal({
                title: "Done!",
                text: data,
                icon: "success",
                timer: 3000,
                button: false
              })
            setTimeout(function () {
                window.location.reload();
            }, 3000);
          });
    }
    function openPrint() {
        setAppState({ show: true});
    }
    function closePrint() {
        setAppState({ show: false });
    }
    function copyResi(){
        swal({
            title: "Done!",
            text: 'Resi berhasil di copy',
            icon: "success",
            timer: 2000,
            button: false
        })
    }
    class ComponentToPrint extends React.Component {
        render() {
            return (
                <div className="label-list size-a6">
                    <div className="d-flex justify-content-between">
                        <div className="text-center"><img src="/img/avoskin-logo-black.jpg" alt="avoskin" width={130} height={61} /></div>
                        <div className="text-center barcode-resi"><Barcode value={data.resi} width={2} /></div>
                        <div className="text-center"><img src={data.ekspedisi.image} alt={data.ekspedisi.name} width={130} className="logo-ekspedisi"  /></div>
                    </div>
                    <div className="d-flex border-line no-border">
                        <div className="col-wrap flex-50">
                            <span className="label-title mr-3">Jenis Layanan</span>
                            <span className="label-desc">{data.ekspedisi.name}</span>
                        </div>
                        <div className="col-wrap flex-50">
                            <span className="label-title mr-3">Tanggal Order</span>
                            <span className="label-desc">{data.shipping_label.date}</span>
                        </div>
                    </div>
                    <div className="d-flex border-line">
                        <div className="flex-50 d-flex align-items-center justify-content-center barcode-invoice">
                            <Barcode value={data.invoice_number} width={2}/>
                            <text class="barcode-inv-text" text-anchor="middle" x="57" y="122">{data.invoice_number}</text>
                        </div>
                        <div className="flex-50 border-line-lt no-border">
                            <div className="col-wrap border-line no-border">
                                <span className="label-title mw-80">Asuransi</span>
                                <span className="label-desc">-</span>
                            </div>
                            <div className="col-wrap border-line no-border">
                                <span className="label-title mw-80">Berat</span>
                                <span className="label-desc">{data.shipping_label.weight}</span>
                            </div>
                            <div className="col-wrap">
                                <span className="label-title mw-80">Quantity</span>
                                <span className="label-desc">{data.shipping_label.qty}</span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex border-line">
                        <div className="flex-50">
                            <div className="col-wrap">
                                <div className="label-title">Nama Penerima</div>
                                <div className="label-desc">{data.customer_name}</div>
                            </div>
                        </div>
                        <div className="flex-50">
                            <div className="col-wrap">
                                <div className="label-title">Nama Pengirim</div>
                                <div className="label-desc">{data.shipping_label.shipper_name}</div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex border-line">
                        <div className="flex-50">
                            <div className="col-wrap">
                                <div className="label-title">Alamat Penerima</div>
                                <div className="label-desc">{data.shipping_address}</div>
                            </div>
                        </div>
                        <div className="flex-50">
                            <div className="col-wrap">
                                <div className="label-title">Alamat Pengirim</div>
                                <div className="label-desc">{data.shipping_label.shipper_address}</div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex border-line">
                        <div className="flex-50">
                            <div className="col-wrap">
                                <div className="label-title">Nomor Telpon Penerima</div>
                                <div className="label-desc">{data.phone}</div>
                            </div>
                        </div>
                        <div className="flex-50">
                            <div className="col-wrap">
                                <div className="label-title">Nomor Telpon Pengirim</div>
                                <div className="label-desc">{data.shipping_label.shipper_phone}</div>
                            </div>
                        </div>
                    </div>
                    <div className="border-line">
                        <div className="col-wrap">
                            <div className="label-title">Catatan</div>
                            <div className="label-desc">{data.notes_customer}</div>
                        </div>
                    </div>
                    <div className="border-line mb-5">
                        <div className="col-wrap">
                            <div className="label-title">Detail Barang</div>
                            {product.map((repo) => {
                                return (
                                    <div className="d-flex justify-content-between">
                                        <div className="label-desc">{repo.name}</div>
                                        <div className="label-desc">{repo.quantity}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>    
            );
        }
    }
  if (!data || data.length === 0) return <p>No data order, sorry</p>;
  return (
        <div className="container-wrapper">
            <div className="order-detail-wrapper">
                <div className="content-wrapper">
                    <div className="heading-wrapper">
                        <div className="order-heading">
                            <div className="order-heading-left">
                                <img src="/img/ico-user.svg" alt="user" className="image-order mr-3" />
                                <div className="meta-customer mr-3">{data.customer_name}</div>
                                {(() => {
                                if (data.status == 'processing' || data.status == 'on-delivery') {
                                    return <span className="label-status green">{data.status}</span>;
                                } else if (data.status == 'cancelled' || data.status == 'failed') {
                                    return <span className="label-status red">{data.status}</span>;
                                } else if (data.status == 'completed' || data.status =='packing') {
                                    return <span className="label-status blue">{data.status}</span>;
                                } else if (data.status == 'on-hold') {
                                    return <span className="label-status orange">{data.status}</span>;
                                } else if (data.status == 'pending' || data.status == 'refunded') {
                                    return <span className="label-status grey">{data.status}</span>;
                                }    
                                })()}
                            </div>
                            <div className="order-heading-right">
                                <div className="meta-date">{data.created_at}</div>
                            </div>
                        </div>
                    </div>{/* end of heading wrapper */}
                    <div className="main-dash">
                        <div className="status-toolbar">
                            <div className="toolicon-wrapper status">
                                <button id="show" className="toolicon status"><span>{appState.status}</span></button>
                                <div className="tool-inner">
                                    <div className="tool-wrapper">
                                        <div className="tool-heading">Status Pesanan</div>
                                        <ul className="tool-select">
                                            <li onClick={() => setAppState({ status: 'on-hold'})} className={handleClass('on-hold')}><span className="label-status grey">On Hold</span>Menunggu Pembayaran</li>
                                            <li onClick={() => setAppState({ status: 'pending'})} className={handleClass('pending')}><span className="label-status orange">Pending</span>Konfirmasi Ketersediaan</li>
                                            <li onClick={() => setAppState({ status: 'processing'})} className={handleClass('processing')}><span className="label-status green">Processing</span>Sedang Diproses</li>
                                            <li onClick={() => setAppState({ status: 'packing'})} className={handleClass('packing')}><span className="label-status green">Packing</span>Sedang Dikemas</li>
                                            <li onClick={() => setAppState({ status: 'on-delivery'})} className={handleClass('on-delivery')}><span className="label-status green">On Delivery</span>Dalam Ekspedisi</li>
                                            <li onClick={() => setAppState({ status: 'completed'})} className={handleClass('completed')}><span className="label-status blue">Completed</span>Selesai</li>
                                            <li onClick={() => setAppState({ status: 'refunded'})} className={handleClass('refunded')}><span className="label-status red">Refunded</span>Dikembalikan</li>
                                            <li onClick={() => setAppState({ status: 'cancelled'})} className={handleClass('cancelled')}><span className="label-status red">Cancelled</span>Dibatalkan</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div>
                            {(() => {
                                if (appState.status == 'Pilihan Status') {
                                    return <button onClick={ChangeStatus} disabled className="btn btn-primary">Ubah</button>;
                                } else {
                                    return <button onClick={ChangeStatus} disabled={appState.disabled} className="btn btn-primary">{appState.disabled ? "Loading..." : "Ubah"}</button>;
                                }
                            })()} 
                            </div>
                        </div>
                        <div className="order-info">
                            <div className="row">
                                <div className="col-4">
                                    <div className="label-item">
                                        <div className="label-title">No. Pesanan</div>
                                        <div className="label-desc text-green">{data.invoice_number}</div>
                                    </div>
                                    <div className="label-item">
                                        <div className="label-title">Alamat Pengiriman</div>
                                        <div className="label-desc">{data.shipping_address}</div>
                                    </div>
                                    <div className="label-item">
                                        <div className="label-title">Nomor Telp.</div>
                                        <div className="label-desc">{data.phone}</div>
                                    </div>
                                    <div className="label-item">
                                        <div className="label-title">Catatan dari Customer</div>
                                        <div className="label-desc">{data.notes_customer}</div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="label-item">
                                        <div className="label-title">Informasi Jasa Kirim</div>
                                        <div className="courier-item">
                                            <div className="courier-logo"><img src={data.ekspedisi.image} alt={data.ekspedisi.name} className="img-fluid" /></div>
                                            <div className="courier-info">
                                                <div className="courier-resi">{data.resi}</div>
                                                <div className="courier-service">{data.ekspedisi.name}</div>
                                                <div className="courier-city">{data.ekspedisi.origin} - {data.ekspedisi.destination}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {(() => {
                                    if (data.resi != '') {
                                        return <div className="label-item">
                                            <div className="label-title">No. Resi</div>
                                            <div className="label-desc large mb-2">{data.resi}</div>
                                            <CopyToClipboard text={data.resi}>
                                                <button className="btn btn-plain" onClick={copyResi}><i className="icon-copy" /> Copy Resi</button>
                                            </CopyToClipboard>  
                                            <button className="btn btn-plain" onClick={openPrint}><i className="icon-print" /> Cetak Resi</button>
                                            <div id="popup-print" className="overlay">
                                                <div className="popup">
                                                <Modal show={appState.show} >
                                                    <button className="close hide-on-print" onClick={closePrint}><img src="/img/ico-close.svg" alt="close" /></button>        
                                                    <div className="content">
                                                        <div className="label-wrapper">
                                                            <ComponentToPrint ref={componentRef} />
                                                            <div className="text-right mt-4">
                                                                <ReactToPrint
                                                                trigger={() =>  <button className="btn btn-blue btn-icon hide-on-print"><i className="icon-print" /> Print Label</button>}
                                                                content={() => componentRef.current}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Modal>
                                                </div>
                                            </div>{/* end of overlay */}
                                        </div>;
                                    } else {
                                        return <div className="label-item">
                                                    <div className="label-title">No. Resi</div>
                                                    <div className="label-desc medium mb-2">Data resi not found</div>
                                                </div>;
                                    }
                                    })()}
                                </div>
                                <div className="col-4">
                                    {(() => {
                                        if (data.tracking.status != 'fail') {
                                            return <div>
                                                {getRenderedItems().map((repo, i) => {
                                                    return (  
                                                        <div className="box-green" key={i}>
                                                        {(() => {
                                                            if(tracking.length == tracking.length - i){
                                                                return <div className="timeline active">
                                                                    <div className="label-title">{repo.position}</div>
                                                                    <div className="label-desc">
                                                                        {repo.title}<br />
                                                                        {repo.date}
                                                                    </div>
                                                                </div>;
                                                            } else {
                                                                return <div className="timeline">
                                                                    <div className="label-title">{repo.position}</div>
                                                                    <div className="label-desc">
                                                                        {repo.title}<br />
                                                                        {repo.date}
                                                                    </div>
                                                                </div>;
                                                            }
                                                        })()}
                                                        </div>
                                                    );
                                                })}
                                                <div className="order-detail mt-3">
                                                {(() => {
                                                    if(data.tracking.data.length > 3) {
                                                    return <button className="btn-track" onClick={change}>{appState.viewAll?'Sembunyikan':'Selengkapnya'}</button>;
                                                    }
                                                })()}
                                                </div>
                                            </div>;   
                                        } else {
                                            return <div className="box-green">
                                                <div className="label-desc">
                                                    Data tracking not found
                                                </div>
                                            </div>;
                                        }
                                    })()}
                                </div>
                            </div>
                        </div>{/* end of order-info */}
                        <hr />
                        <div className="d-flex justify-content-between mb-3">
                            <div className="label-title large">Info Pembayaran</div>
                            <div className="label-pile">Riwayat pembayaran</div>
                        </div>
                        {product.map((repo) => {
                            return (
                                <div className="product-table d-flex justify-content-between">
                                    <div className="product-info d-flex align-items-center">
                                        <div className="product-image"><img src={repo.image} alt={repo.name} /></div>
                                        <div className="product-title">{repo.name}</div>
                                    </div>
                                    <div className="product-payment d-flex align-items-center">
                                        <div className="product-price">@{repo.price}</div>
                                        <div className="product-qty">x{repo.quantity}</div>
                                        <div className="product-payment">{repo.total}</div>
                                    </div>
                                </div>/* end of product table */
                            );
                        })}
                        <hr />
                        <div className="product-table d-flex justify-content-between">
                            <div className="label-title green">SUBTOTAL</div>
                            <div className="label-title green">{data.product.subtotal_price}</div>
                        </div>{/* end of product table */}
                        <div className="product-table d-flex justify-content-between">
                            <div className="label-title green">Ongkos Kirim</div>
                            <div className="label-title green">{data.ekspedisi.price}</div>
                        </div>{/* end of product table */}
                        <div className="label-title large mb-3">Voucher yang diterapkan</div>
                        {(() => {
                            if(data.coupon != '') {
                            return <div className="product-table d-flex justify-content-between">
                                        <div className="label-cashback">{data.coupon}</div>
                                        <div className="label-title red">{data.product.discount}</div>
                                    </div>;/* end of product table */
                            } else {
                                return <div className="product-table d-flex justify-content-between"> Tidak ada </div>;
                            }
                        })()}
                        <div className="product-table d-flex justify-content-between">
                            <div className="label-title green">PPn</div>
                            <div className="label-title green">{data.product.ppn}</div>
                        </div>{/* end of product table */}
                        <div className="product-table-green green d-flex justify-content-between">
                            <div className="label-title large green">TOTAL</div>
                            <div className="label-title large green">{data.product.total_price}</div>
                        </div>{/* end of product table */}
                    </div> {/* end of main-dash */}
                </div>
            </div>
            <div className="order-history-wrapper">
                <div className="content-wrapper">
                    <div className="heading-wrapper">
                        <div className="heading-title text-center">Riwayat Pesanan</div>
                    </div>
                    {(() => {
                        if(data.history_order.data != null) {
                            return  <div className="main-dash p-4">
                                        <div class="timeline completed">
                                            <div class="label-title">Selesai</div>
                                            <div class="label-desc">
                                                <p>Belum ada data</p>
                                            </div>
                                        </div>
                                        {history_order.map((repo, i) => {
                                            return (
                                                <div>
                                                {(() => {
                                                    if(history_order.length == history_order.length - i){
                                                        return <div className="timeline active">
                                                            <div className="label-title"></div>
                                                            <div className="label-desc">
                                                                <p>{ he.decode(repo.description) }</p>
                                                                <p>{repo.date}</p>
                                                            </div>
                                                        </div>;
                                                    } else {
                                                        return <div className="timeline">
                                                            <div className="label-title"></div>
                                                            <div className="label-desc">
                                                                <p>{ he.decode(repo.description) }</p>
                                                                <p>{repo.date}</p>
                                                            </div>
                                                        </div>;
                                                    }
                                                })()}
                                                </div>
                                            );
                                        })}
                                    </div>;
                        } else {
                            return <div className="main-dash p-4">Data not found</div>;
                        }
                    })()}
                </div>
            </div>
        </div>
  );
}
export default List;