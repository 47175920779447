import React,{ useState } from 'react';
import Countdown from 'react-countdown';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ReactPaginate from 'react-paginate';
import ReactJson from 'react-json-view';
import swal from 'sweetalert';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import { authHeader } from '../../_helpers';
import { API_STAGING } from '../../_settings'
const List = (props) => {
    const { data,total,save,set_page,current_page,to,label,pageLabel } = props;
    const [appState, setAppState]  = useState({
        dataState: data,
        disabled: false
    });
    const changePage = (e) => {
        const selectedPage = e.selected + 1;
        set_page(selectedPage)
        console.log(`active page is ${selectedPage}`);
    }

    const handleAllChecked = (event) => {
        appState.dataState.forEach((result,index) =>{
            appState.dataState[index].isChecked= event.target.checked
            console.log(appState.dataState[index].isChecked)
        })
        setAppState({dataState:appState.dataState });
    }

    const handleCheckChieldElement = (e) => {
        console.log(appState.dataState[e].id)

        appState.dataState[e].isChecked = !appState.dataState[e].isChecked
        console.log(appState.dataState)
        setAppState({dataState:appState.dataState });

    }

    function UpdateParameter(updatedParam) {
        setAppState(ps => ({ ...ps, disabled: true}));
        const fd = new FormData();
        fd.append('data', updatedParam)
        axios.post(`${API_STAGING}order/update-awb`,
            fd,
            {
                headers: authHeader()
            })
        .then((repos) => {
            swal({
                title: "Done!",
                text: 'Parameter berhasil diperbarui!',
                icon: "success",
                timer: 3000,
                button: false
              })
            setTimeout(function () {
                set_page(current_page)
            }, 3000);
          });
    }
    function copyResi(){
        swal({
            title: "Done!",
            text: 'Resi berhasil di copy',
            icon: "success",
            timer: 2000,
            button: false
        })
    }

    let datas = []
  if (!data || data.length === 0) return <p style={{textAlign: 'center', marginTop: 120, marginBottom: 120}}>No data request awb, sorry</p>;
  return (
    <div>        
        <div className="result">Total <strong>{total} Data</strong> Pesanan</div>
        <table id="table-to-xls" className="hide-table">
            <thead>
            <tr>
                <th>ID Order</th>
                <th>Referral</th>
                <th>Tanggal Order</th>
                <th>Nama Customer</th>
                <th>AWB</th>
                <th>Payment Method</th>
                <th>Shipping Method</th>
                <th>Voucher Code</th>
                <th>Shipping Price</th>
                <th>Cashback</th>
                <th>Tax</th>
                <th>Grand Total</th>
                <th>Product ID</th>
                <th>Nama Product</th>
                <th>Harga Product</th>
                <th>Qty</th>
                <th>Subtotal</th>
                <th>Product Tax</th>
                <th>Total with Tax</th>
            </tr>
            </thead>
            <tbody>
            {appState.dataState.map(item => (
                <tr>
                    <td>{item.invoice_number}</td>
                    <td>{item.export_data.referral}</td>
                    <td>{item.created_at}</td>
                    <td>{item.customer_name}</td>
                    <td>{item.export_data.awb}</td>
                    <td>{item.payment_method}</td>
                    <td>{item.export_data.shipping_method}</td>
                    <td>{item.export_data.voucher_code}</td>
                    <td>{item.export_data.shipping_price}</td>
                    <td>{item.export_data.cashback}</td>
                    <td>{item.export_data.tax}</td>
                    <td>{item.total_price_text}</td>
                    {item.product.data.map((repo) => {
                        return (
                            <div>
                                <td>{repo.product_id}</td>
                                <td>{repo.name}</td>
                                <td>{repo.total}</td>
                                <td>{repo.quantity}</td>
                                <td>{repo.subtotal}</td>
                                <td>{repo.total_tax}</td>
                                <td>{repo.total}</td>
                            </div>
                        );
                    })}
                </tr>
            ))}
            </tbody>
        </table>
        <div className="table-data">
            {(() => {
                if (pageLabel == 'error') {
                    return  <div className="table-head">
                                <div className="th-order-01">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="customCheck1" onClick={handleAllChecked} value="checkedall" />
                                        <label className="custom-control-label" htmlFor="customCheck1" />
                                    </div>
                                </div>
                                <div className="th-order-02">Product</div>
                                <div className="th-order-03">Jumlah</div>
                                <div className="th-order-07">Expedisi</div>
                            </div>;
                } else {
                    return <div className="table-head">
                                <div className="th-order-01">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="customCheck1" onClick={handleAllChecked} value="checkedall" />
                                        <label className="custom-control-label" htmlFor="customCheck1" />
                                    </div>
                                </div>
                                <div className="th-order-02">Product</div>
                                <div className="th-order-03">Jumlah</div>
                                <div className="th-order-07">Expedisi</div>
                                <div className="th-order-08">Resi</div>
                                <div className="th-order-06">Perlu Dikirim</div>
                            </div>;
                }
            })()}
            {appState.dataState.map((repo,index) => {
                return (
                    <div className="table-body" key={index}>
                        <div className="table-body-top">
                            <div className="table-body-top-left">
                            <div className="tb-bt-01">
                                <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id={index} checked={repo.isChecked} onChange={(e)=>handleCheckChieldElement(index)}/>
                                <label className="custom-control-label" htmlFor={index} />
                                </div>
                            </div>
                            <div className="tb-bt-02">
                                <img src="/img/ico-user.svg" alt="user" className="image-order" />
                            </div>
                            <div className="tb-bt-03"><div className="meta-invoice">{repo.invoice_number}</div></div>
                            <div className="tb-bt-04"><div className="meta-customer">{repo.customer_name}</div></div>
                            </div>
                            <div className="table-body-top-right">
                            <div className="meta-date">{repo.created_at}</div>
                            </div>
                        </div>{/* end of table-body-top */}
                        {(() => {
                            if (pageLabel == 'error') {
                                return  <div>
                                            <div className="table-body-btm">
                                                <div className="table-body-btm-left">
                                                <div className="table-body-btm-left-inner">
                                                    <div className="product-image"><img src={repo.product.data[0] ? repo.product.data[0].image : ""} alt={repo.product.data[0] ? repo.product.data[0].name : ""} /></div>
                                                    <div className="product-detail">
                                                        <div className="product-title">{repo.product.data[0] ? repo.product.data[0].name : ""}</div>
                                                        {(() => {
                                                            if (repo.product.total > 1) {
                                                                return <div className="product-more">dan {repo.product.total-1} produk lainnya</div>;
                                                            }else {
                                                                return <div className="product-more">{repo.product.total} produk</div>;
                                                            }
                                                        })()}
                                                    </div>
                                                </div>
                                                <div className="product-qty">x{repo.product.data[0] ? repo.product.data[0].quantity : ""}</div>
                                                </div>{/* end of table-body-btm-left */}
                                                <div className="table-body-btm-mid">
                                                    <div className="order-total">{repo.total_price_text},- <span className="order-payment">{repo.payment_method}</span></div>
                                                    <div className="courier-item">
                                                        <div className="courier-logo"><img src={repo.ekspedisi.image} alt={repo.ekspedisi.name} className="img-fluid" /></div>
                                                        <div className="courier-info mr-2">
                                                            <div className="courier-city">{repo.ekspedisi.origin} - {repo.ekspedisi.destination}</div>
                                                            <div className="courier-service">{repo.ekspedisi.name}</div>
                                                            <div className="courier-price">{repo.ekspedisi.price},-</div>
                                                        </div>
                                                    </div>
                                                </div>{/* end of table-body-btm-mid */}
                                            </div>{/* end of table-body-btm */}
                                            <div className="err-tabs">
                                                <div className="err-tab">
                                                <input type="checkbox" id={"error-tab"+ index} />
                                                <label className="err-tab-label" htmlFor={"error-tab"+ index}>Detail</label>
                                                <div className="err-tab-content">
                                                    <div className="err-sub-tabs">
                                                    <div className="err-sub-tab">
                                                        <input type="checkbox" id={"error-sub-tab1"+ index} />
                                                        <label className="err-sub-tab-label" htmlFor={"error-sub-tab1"+ index}>Semua Pesan Error</label>
                                                        <div className="err-sub-tab-content">
                                                            <pre>{repo.awb_error.error_all}</pre>
                                                        </div>
                                                    </div>
                                                    <div className="err-sub-tab">
                                                        <input type="checkbox" id={"error-sub-tab2"+ index} />
                                                        <label className="err-sub-tab-label" htmlFor={"error-sub-tab2"+ index}>Request Parameter</label>
                                                        <div className="err-sub-tab-content">
                                                            <pre><ReactJson src={repo.awb_error.request_param} /></pre>
                                                        </div>
                                                    </div>
                                                    <div className="err-sub-tab">
                                                        <input type="checkbox" id={"error-sub-tab3"+ index} />
                                                        <label className="err-sub-tab-label" htmlFor={"error-sub-tab3"+ index}>Update Parameter</label>
                                                        <div className="err-sub-tab-content">
                                                            <Formik
                                                                // "name" tag doesn't support square bracket, use angle bracket as an alternative
                                                                initialValues={[
                                                                    ...Object.keys(repo.awb_error.request_param).filter(key => key !== 'PackageList').map((key) => [`param<${key}>`, repo.awb_error.request_param[key]]),
                                                                    ...(repo.awb_error.request_param['PackageList']?.[0]
                                                                        ? Object.keys(repo.awb_error.request_param.PackageList[0]).map((key) => [`param<PackageList><0><${key}>`,repo.awb_error.request_param.PackageList[0][key]])
                                                                        : []),
                                                                ].reduce((obj, cur) => ({...obj, [cur[0]]: cur[1]}), {})}
                                                                onSubmit={(val) => {
                                                                    const updatedParam = {...val, order_id: repo.id, courier: repo.awb_error.courier }
                                                                    // convert angle brackets back to square brackets
                                                                    const queryStringParam = Object.keys(updatedParam).map((key) => `${key.replaceAll('<', '[').replaceAll('>', ']')}=${updatedParam[key]}`).join('&')
                                                                    const encodedParam = encodeURI(queryStringParam)
                                                                    UpdateParameter(encodedParam)
                                                                }}>
                                                                {() => (
                                                                    <Form>
                                                                        <div className="form-row">
                                                                            {Object.keys(repo.awb_error.request_param).filter(key => key !== 'PackageList').map((key) => (
                                                                                <div className="form-group col-md-6" key={`param[${key}]`}>
                                                                                    <label htmlFor={`${repo.id}-param[${key}]`}>{key}</label>
                                                                                    <Field type="text" className="form-control" id={`${repo.id}-param[${key}]`} name={`param<${key}>`} />
                                                                                </div>
                                                                            ))}
                                                                            {repo.awb_error.request_param.PackageList?.[0] && Object.keys(repo.awb_error.request_param.PackageList[0]).map((key) => (
                                                                                <div className="form-group col-md-6" key={`param[PackageList][0][${key}]`}>
                                                                                    <label htmlFor={`${repo.id}-param[PackageList][0][${key}]`}>{key}</label>
                                                                                    <Field type="text" className="form-control" id={`${repo.id}-param[PackageList][0][${key}]`} name={`param<PackageList><0><${key}>`} />
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        <div className="text-right">
                                                                            <button type="submit" className="btn btn-blue">Update</button>
                                                                        </div>
                                                                    </Form>
                                                                )}
                                                            </Formik>
                                                        </div>
                                                    </div>
                                                    </div>{/* end of err-sub-tabs */}
                                                </div>
                                                </div>
                                            </div>{/* end of err-tabs */}
                                        </div>; /* end of table-body-btm */
                            } else {
                                return <div className="table-body-btm">
                                            <div className="table-body-btm-left">
                                            <div className="table-body-btm-left-inner">
                                                <div className="product-image">
                                                    <img src={repo.product.data[0] ? repo.product.data[0].image : ""} alt={repo.product.data[0] ? repo.product.data[0].name : ""} />
                                                </div>
                                                <div className="product-detail">
                                                    <div className="product-title">{repo.product.data[0] ? repo.product.data[0].name : ""}</div>
                                                    {(() => {
                                                        if (repo.product.total > 1) {
                                                            return <div className="product-more">dan {repo.product.total-1} produk lainnya</div>;
                                                        }else {
                                                            return <div className="product-more">{repo.product.total} produk</div>;
                                                        }
                                                    })()}
                                                </div>
                                            </div>
                                            <div className="product-qty">x{repo.product.data[0] ? repo.product.data[0].quantity : ""}</div>
                                            </div>{/* end of table-body-btm-left */}
                                            <div className="table-body-btm-mid">
                                                <div className="order-total">{repo.total_price_text},- <span className="order-payment">{repo.payment_method}</span></div>
                                                <div className="courier-item">
                                                    <div className="courier-logo"><img src={repo.ekspedisi.image} alt={repo.ekspedisi.name} className="img-fluid" /></div>
                                                    <div className="courier-info mr-2">
                                                        <div className="courier-city">{repo.ekspedisi.origin} - {repo.ekspedisi.destination}</div>
                                                        <div className="courier-service">{repo.ekspedisi.name}</div>
                                                        <div className="courier-price">{repo.ekspedisi.price},-</div>
                                                    </div>
                                                </div>
                                            </div>{/* end of table-body-btm-mid */}
                                            <div className="table-body-btm-right">
                                            {(() => {
                                                if (repo.resi == '') {
                                                    return <div className="order-resi" style={{width: '40%'}}>Belum tersedia</div>;
                                                } else {
                                                    return <div className="order-resi" style={{width: '40%'}}>
                                                                <div class="mb-2">{repo.resi}</div>
                                                                <CopyToClipboard text={repo.resi}>
                                                                    <button class="btn btn-plain" onClick={copyResi}><i class="icon-copy"></i> Copy Resi</button>
                                                                </CopyToClipboard>
                                                            </div>;
                                                }
                                            })()}
                                            {(() => {
                                                if (repo.status == 'processing' || repo.status == 'packing') {
                                                    if (repo.processing_time == '' || repo.processing_time.status == 'on-time') {
                                                        return <div className="order-time">
                                                            <Countdown
                                                                date={Date.parse(repo.status_time.expired)}
                                                                intervalDelay={0}
                                                                precision={3}
                                                                renderer={(props) => (
                                                                <div className="order-timer">
                                                                    {props.days}d : {props.hours}h : {props.minutes}m : {props.seconds}s
                                                                </div>
                                                                )}
                                                            />            
                                                            <div className="order-time-update">
                                                                Mohon dikirim sebelum<br /><strong>{repo.status_time.expired_alt}</strong>
                                                            </div>
                                                        </div>;
                                                    } else if (repo.processing_time.status == 'late') {
                                                        return  <div> 
                                                                    <div className="order-timer">Terlambat</div>
                                                                    <div className="order-time-update">-</div>
                                                                </div>;
                                                    } 
                                                } else if (repo.status == 'cancelled' || repo.status == 'failed') {
                                                    return <div className="order-time" style={{width: '50%'}}>
                                                        <div className="order-timer">Dibatalkan</div>
                                                        <div className="order-time-update">-</div>
                                                    </div>;
                                                } else if (repo.status == 'on-delivery') {
                                                    return <div className="order-time" style={{width: '50%'}}>
                                                        <div className="order-timer">Dikirim</div>
                                                        <div className="order-time-update">-</div>
                                                    </div>;
                                                } else if (repo.status == 'pending' || repo.status == 'on-hold') {
                                                    return <div className="order-time" style={{width: '50%'}}>
                                                        <div className="order-timer">Tertahan</div>
                                                        <div className="order-time-update">-</div>
                                                    </div>;
                                                } else if (repo.status == 'refunded') {
                                                    return <div className="order-time" style={{width: '50%'}}>
                                                        <div className="order-timer">Dikembalikan</div>
                                                        <div className="order-time-update">-</div>
                                                    </div>;
                                                } else if (repo.status == 'completed') {
                                                    return <div className="order-time" style={{width: '50%'}}>
                                                        <div className="order-timer">Selesai</div>
                                                        <div className="order-time-update">
                                                            Terkirim<br /><strong>{repo.status_time.completed}</strong>
                                                        </div>
                                                    </div>;
                                                }
                                                })()}
                                            </div>{/* end of table-body-btm-right */}
                                        </div>; /* end of table-body-btm */
                            }
                        })()}
                    </div> /* end of table-body */
                );
            })}
            <div class="pagination">
                <ReactPaginate
                    pageCount={to??1}
                    pageRangeDisplayed={4}
                    marginPagesDisplayed={1}
                    forcePage={current_page - 1}
                    activeLinkClassName='active'
                    containerClassName='paging'
                    previousLabel='&laquo;'
                    nextLabel='&raquo;'
                    onPageChange={changePage}
                />
            </div>
            <div className="text-center">
                <div className="data-result">Total <strong>{total} Data</strong> Pesanan</div>
            </div>
        </div>
    </div>
  );
};
export default List;
