export function authHeader() {
    // return authorization header with jwt token
    let token = localStorage.getItem('token')

    if (token) {
        return {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };
    } else {
        return {};
    }
}