import React,{ useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Modal } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { authHeader } from '../../_helpers';
import { API_STAGING } from '../../_settings'

const List = (props) => {
    const { data,total,save,set_page,current_page,to,clearFilter } = props;
    const [notes, setNotes]         = useState('');
    const [appState, setAppState]  = useState({
        dataState: data,
        active: '',
        disabled: false,
        showDelete: false,
        showEdit: false,
        showLoading: false,
        show: false,
        notes: '',
        view: '',
    });
    const changePage = (e) => {
        const selectedPage = e.selected + 1;
        set_page(selectedPage)
        console.log(`active page is ${selectedPage}`);
    }
    const onChangeNotes = (e) => {
        const value = e.target.value
        setNotes(value)
    }
    const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
    const openDelete = (e) => {
        setAppState({showDelete: true, dataState:appState.dataState});
        appState.dataState[e].isChecked = !appState.dataState[e].isChecked
        console.log(appState.dataState)
    }
    function closeDelete(e) {
        setAppState({showDelete: false});
        clearFilter();
    }
    function deleteSetting(){
        let arr =[];
        appState.dataState.forEach((result,index) =>{
            if(appState.dataState[index].isChecked==true){
                setAppState({disabled: true, dataState:appState.dataState, showDelete: true});
                    axios.post(`${API_STAGING}setting-date/delete?date=${appState.dataState[index].date}`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.message;
                    if(repos.data.status == true) {
                        swal({
                            title: "Done!",
                            text: data,
                            icon: "success",
                            timer: 3000,
                            button: false
                        })
                        setTimeout(function () {
                            window.location.reload()
                        }, 3000);
                    } else {
                        swal({
                            title: "Error!",
                            text: data,
                            icon: "error",
                            button: true
                        })
                        setTimeout(function () {
                            window.location.reload()
                        }, 3000);
                    }
                });
            }
        })
    }

    const openEdit = (e) => {
        setAppState({showLoading: true, dataState:appState.dataState});
        appState.dataState[e].isChecked = !appState.dataState[e].isChecked
        console.log(appState.dataState[e].date)
        axios.post(`${API_STAGING}setting-date/listing?date=${appState.dataState[e].date}`, {
            //...data
            },  {
            headers: authHeader()
        })
        .then((repos) => {
            const value = repos.data.data.data[0].notes;
            setNotes(value);
            setAppState({showLoading: false,  showEdit: true, dataState:appState.dataState });
        });
    }
    function closeEdit() {
        setAppState({showEdit: false});
        clearFilter();
    }
    function editSetting(){
        let arr =[];
        appState.dataState.forEach((result,index) =>{
            if(appState.dataState[index].isChecked==true){
                setAppState({disabled: true, dataState:appState.dataState, showEdit: true});
                    axios.post(`${API_STAGING}setting-date/add?date=${appState.dataState[index].date}&note=${notes}`, {
                    //...data
                },  {
                    headers: authHeader()
                })
                .then((repos) => {
                    const data = repos.data.message;
                    if(repos.data.status == true) {
                        swal({
                            title: "Done!",
                            text: data,
                            icon: "success",
                            timer: 3000,
                            button: false
                        })
                        window.location.reload()
                    } else {
                        swal({
                            title: "Error!",
                            text: data,
                            icon: "error",
                            button: true
                        })
                        window.location.reload()
                    }
                });
            }
        })
    }
  return (   
      <div className="list-setting">
        {appState.dataState && appState.dataState.length> 0 ? appState.dataState.map((repo,index) => {
            return (
            <div className="list-setting-item" key={index}>
                <div className="ls-item-01">{new Date(repo.date).getDate()+ " " + monthNames[new Date(repo.date).getMonth()]+ " "+ new Date(repo.date).getFullYear()}</div>
                <div className="ls-item-02">{repo.notes}</div>
                <div className="ls-item-03">
                    <button className="btn-setting delete" id={index} checked={repo.isChecked} onClick={(e)=>openDelete(index)}><span className="sr-only">Delete</span></button>
                    <button className="btn-setting edit" id={index} checked={repo.isChecked} onClick={(e)=>openEdit(index)}><span className="sr-only">Edit</span></button>
                </div>
            </div>
            );
        }): <div className="list-setting" style={{textAlign: 'center', marginTop: 120, marginBottom: 120}}>No data setiings, sorry</div>}
        <div id="popup-delete" className="overlay">
            <div className="popup">
                <Modal show={appState.showDelete}>
                    <button className="close" onClick={closeDelete}><img src="img/ico-close.svg" alt="close" /></button>
                    <div className="content content-delete">
                        <h3 className="heading-box lg mb-4">Delete Stop Order Processing</h3>
                        <p>Apakah Anda yakin ingin menghapus jadwal stop order processing ini?</p>
                        <div className="button-popup">
                            <button className="btn btn-white" onClick={closeDelete}>Batalkan</button>
                            <button className="btn btn-red"  onClick={deleteSetting} disabled={appState.disabled}>{appState.disabled ? "Loading..." : "Hapus"}</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
        <div id="popup-edit" className="overlay">
            <div className="popup">
                <Modal show={appState.showEdit}>
                    <button className="close" onClick={closeEdit}><img src="img/ico-close.svg" alt="close" /></button>
                    <div className="content">
                        <h3 className="heading-box lg mb-4">Edit Stop Order Processing</h3>
                        <div className="form-group">
                            <textarea className="form-control" id="inputText" rows={10} value={notes} onChange={onChangeNotes} />
                        </div>
                        <div className="text-right">
                        {(() => {
                            if (notes==''){
                                return <button type="submit" className="btn btn-primary" disabled="true">Ubah</button>;
                                
                            } else {
                                return <button type="submit" className="btn btn-primary" onClick={editSetting} disabled={appState.disabled}>{appState.disabled ? "Loading..." : "Ubah"}</button>;
                            }
                        })()}
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
        <div id="popup-edit" className="overlay">
            <div className="popup">
                <Modal show={appState.showLoading}>
                    <button className="close" onClick={closeEdit}><img src="img/ico-close.svg" alt="close" /></button>
                    <div className="content">
                        <h3 className="heading-box lg mb-4">Edit Stop Order Processing</h3>
                        <div className="credential-50">
                            <div className="credential-box-noborder">
                            <div className="load-2">
                                <p>Loading... Please wait</p>
                                <div class="line"></div>
                                <div class="line"></div>
                                <div class="line"></div>
                            </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
        <div class="pagination">
            <ReactPaginate
                pageCount={to??1}
                pageRangeDisplayed={4}
                marginPagesDisplayed={1}
                forcePage={current_page - 1}
                activeLinkClassName='active'
                containerClassName='paging'
                previousLabel='&laquo;'
                nextLabel='&raquo;'
                onPageChange={changePage}
            />
        </div>
    </div>   
  );
}
export default List;