import React, { useState, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Formik, Form } from 'formik';
import axios from 'axios';
import { API_STAGING } from '../../_settings'

function Login(){
    const [email, setEmail]       = useState('');
    const [password, setPassword] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [error, setError]       = useState('');

    const onChangeEmail = (e) => {
        const value = e.target.value
        setEmail(value)
        setError('')
    }

    const onChangePassword = (e) => {
        const value = e.target.value
        setPassword(value)
        setError('')
    }

    const validate = () => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!email) {
            errors.email = "Email is required";
        } else if (!regex.test(email)) {
            errors.email = "Invalid Email";
        }

        if (!password) {
            errors.password = "Password is required";
        } else if (password.length < 4) {
            errors.password = "Password too short";
        }

        return errors;
    };

    const submitLogin = () => {
        const data = {
            email: email,
            password: password
        }
        axios.post(`${API_STAGING}login/?data=${btoa(JSON.stringify(data))}`)
        .then(result => {
            if(result.data.status == true) {
                var dateString = result.data.data.datebirth;
                var dateParts = dateString.split("/");
                var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
                var datebirth = dateObject.toString();
                localStorage.setItem('token', result.data.data.token)
                localStorage.setItem('name', result.data.data.name)
                localStorage.setItem('email', result.data.data.email)
                localStorage.setItem('gender', result.data.data.gender)
                localStorage.setItem('datebirth', datebirth)
                localStorage.setItem('user_register', result.data.data.user_register)
                localStorage.setItem('max_days', result.data.data.expired.day)
                localStorage.setItem('max_hours', result.data.data.expired.hour)
                localStorage.setItem('max_minutes', result.data.data.expired.minute)
                setRedirect(true)
            }
            else {
                setError(result.data.msg)
            }
        })
    }
    
    const token = localStorage.getItem('token')
    if (token) {
        return <Redirect to="/order" />
    }

    return ( 
        <Fragment>
            {
                redirect && (
                    <Redirect to="/order" />
                )
            }
            <div className="credential">
                <div className="brand-image">
                    <Link to="/login"><img src="/img/avo-logo.png" alt="Avoskin" /></Link>
                </div>
                <div className="credential-box">
                    <h1 className="heading">Log In</h1>
                    <div className="form-credentials">
                        {
                            error && (
                                <div className="alert alert-danger">
                                    {error}
                                </div>
                            )
                        }
                        <Formik
                            initialValues={{ email: '', password: '' }}
                            validate={validate}
                            onSubmit={submitLogin}
                        >
                            {(formik) => {
                            const {
                                values,
                                isSubmitting,
                                handleSubmit,
                                errors,
                                touched,
                                handleBlur,
                                isValid,
                                dirty
                            } = formik;
                            return (
                            <Form>
                                <div className="form-group">
                                    <label htmlFor="InputUsername" className="form-label">Email</label>
                                    <input type="email" id="InputUsername" name="email" value={email} onChange={onChangeEmail} onBlur={handleBlur} className="form-control"/>
                                    {errors.email && touched.email ? <span className="tooltiptext" >{errors.email}</span> : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="InputPassword" className="form-label">Password</label>
                                    <input type="password" id="InputPassword" name="password" value={password} onChange={onChangePassword} onBlur={handleBlur} className="form-control"/>
                                    {errors.password && touched.password ? (<span className="tooltiptext">{errors.password}</span>) : null}
                                </div>
                                <div className="form-group mt-4">
                                    <Link to="/forgot-password">Lupa Password</Link>
                                </div>
                                <button onClick={submitLogin} style={{ color: 'white' }} disabled={errors.email || errors.password || !email || !password} className="btn btn-primary btn-rounded mt-4">Masuk</button>
                            </Form>
                            );
                            }}
                        </Formik>
                    </div>
                </div>
                <div className="background-bubble left"><img src="/img/login-bubble.svg" alt="Bubble" /></div>
                <div className="background-bubble right"><img src="/img/login-bubble.svg" alt="Bubble" /></div>
            </div>
        </Fragment>
    );
}
  

export default Login;