import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import XLSX from 'xlsx';
import Chart from 'react-apexcharts';
import swal from 'sweetalert';
import axios from 'axios';
import moment from 'moment';
import { authHeader } from '../../_helpers';
import { API_STAGING } from '../../_settings'
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import List from './List';
import {useHistory, useLocation} from 'react-router';
import {useDebouncedCallback} from 'use-debounce/lib';

const getCustomerInfoStats = async (startDate, endDate, exportData) => {
    const response = await axios.post(
    `${API_STAGING}customer-info/stats`,
        null,
        {
            params: {
                start_date: startDate,
                end_date: endDate,
                export: exportData ? 'yes' : undefined
            },
            headers: authHeader(),
        },
    )

    return response.data;
}

/**
 * Get Statistics per City
 *
 * @param {Object} params
 * @param {string} params.start_date
 * @param {string} params.end_date
 * @param {number} params.page
 * @param {string} params.sorting - ASC | DESC
 * @param {string} params.expedition - jne | jnt | pos | sicepat | rpx
 * @param {string} params.search
 * @param {string} params.orderby - city | total_customer | total_order | sold_products | total_value | value_percentage
 * @param {number} params.per_page
 */
const getCityStats = async (params) => {
    const response = await axios.post(
        `${API_STAGING}customer-info/city-stats`,
        null,
        {
            params,
            headers: authHeader(),
        },
    );

    return response.data
}

const cityStatsSortOptions = [
    {
        label: 'Nama Kota A-Z',
        sorting: 'ASC',
        orderby: 'city'
    },
    {
        label: 'Nama Kota Z-A',
        sorting: 'DESC',
        orderby: 'city'
    },
    {
        label: 'Jumlah Pembeli Terbanyak',
        sorting: 'DESC',
        orderby: 'total_customer'
    },
    {
        label: 'Jumlah Pembeli Terdikit',
        sorting: 'ASC',
        orderby: 'total_customer'
    },
    {
        label: 'Jumlah Biaya Terbanyak',
        sorting: 'DESC',
        orderby: 'total_value'
    },
    {
        label: 'Jumlah Biaya Terdikit',
        sorting: 'ASC',
        orderby: 'total_value'
    },
];

const cityStatsExpeditionOptions = [
    {
        label: 'J&T Express',
        value: 'jnt',
        image: '/img/ico-ex-jnt.jpg',
    },
    {
        label: 'JNE Express',
        value: 'jne',
        image: '/img/ico-ex-jne.jpg',
    },
    {
        label: 'Sicepat Express',
        value: 'sicepat',
        image: '/img/ico-ex-sicepat.jpg',
    },
    {
        label: 'RPX One Stop Logistics',
        value: 'rpx',
        image: '/img/rpx.png',
    },
    {
        label: 'POS Indonesia',
        value: 'pos',
        image: '/img/pos.png',
    }
];

const defaultCityStatsParam = {
    page: 1,
    orderby: undefined,
    sorting: 'DESC',
    expedition: undefined,
    search: undefined,
}

function CustomerInfo() {
    const location = useLocation();
    const history = useHistory();
    
    const dateRangePickerRef = useRef(null)

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false)
    const [cityStatsParam, setCityStatsParam] = useState({...defaultCityStatsParam, per_page: 20})
    const [keyword, setKeyword] = useState('');
    const [cityStats, setCityStats] = useState();
    const [cityStatsLoading, setCityStatsLoading] = useState(false);

    const debouncedChangePerPage = useDebouncedCallback((limit) => {
        setCityStatsParam(ps => ({...ps, page: 1, per_page: limit}))
    }, 1000)

    const {startDate, endDate} = useMemo(() => {
        const query = new URLSearchParams(location.search);
        const sd = query.get('sd');
        const ed = query.get('ed');
        if (sd && ed) {
            return {
                startDate: new Date(sd),
                endDate: new Date(ed),
            }
        }
        return {
            startDate: new Date(),
            endDate: new Date(),
        }
    }, [location.search])

    const {page, orderby, sorting, expedition, search, per_page} = cityStatsParam;

    const exportData = useCallback(async () => {
        if (!Array.isArray(cityStats?.city) || !data) return;
        const exportCityStats = await getCityStats({
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).add(1, 'd').format('YYYY-MM-DD'),
            page: 1,
            sorting,
            expedition,
            search,
            orderby,
            per_page: cityStats.total,
        });
        const filename = `customer-info-${moment().format('YYYY-MM-DD-hh-mm-ss')}.xlsx`;
        const customerData = await getCustomerInfoStats(
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).add(1, 'd').format('YYYY-MM-DD'),
            true,
        )
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([])
        if (data.gender) {
            const cells = []
            cells.push(['Jenis Kelamin Pembeli', 'Jumlah'])
            cells.push(['Perempuan',data?.gender?.female ?? 0])
            cells.push(['Laki-laki', data?.gender?.male ?? 0])
            cells.push(['Tidak Disebutkan', data?.gender?.unknown ?? 0])
            XLSX.utils.sheet_add_aoa(ws, cells);
        }
        if (data.customer_type) {
            const cells = []
            cells.push(['Tipe Pembeli', 'Jumlah'])
            cells.push(['Baru', data?.customer_type?.baru ?? 0])
            cells.push(['Reguler', data?.customer_type?.regular ?? 0])
            cells.push(['Setia', data?.customer_type?.setia ?? 0])
            XLSX.utils.sheet_add_aoa(ws, cells, {origin: "D1"});
        }
        if (Array.isArray(data.age)) {
            const cells = []
            cells.push(['Usia Pembeli', 'Jumlah'])
            data.age.forEach(item => {
                cells.push([item.label, item.value])
            })
            XLSX.utils.sheet_add_aoa(ws, cells, {origin: 'G1'});
        }
        const cells = []

        cells.push(null)
        cells.push([
            'Kota / Kab',
            'Jumlah Pembeli',
            'Jml. Pesanan',
            'Produk Terjual',
            'Total Pesanan',
            'Nilai Pesanan'
        ])
        exportCityStats.data.city.forEach(item => {
            cells.push([
                item.city,
                item.total_customer,
                item.total_order,
                item.sold_products,
                typeof item.total_value_text === 'string' ? item.total_value_text.replaceAll('Rp ', '').replaceAll('.', '') : '',
                item.value_percentage
            ]);
        })
        XLSX.utils.sheet_add_aoa(ws, cells, { origin: -1 });

        XLSX.utils.book_append_sheet(wb, ws, 'Export Data');

        const ws2 = XLSX.utils.aoa_to_sheet([])
        const cellsWs2 = []

        if (Array.isArray(customerData?.data?.data)) {
            const genderMap = {
                male: 'Laki-laki',
                female: 'Perempuan',
            };
            const typeMap = {
                setia: 'Setia',
                regular: 'Reguler',
                baru: 'Baru',
            }

            cellsWs2.push(['Nama', 'Email', 'Nomor HP', 'Tipe pembeli', 'Jenis Kelamin', 'Usia Pembeli'])
            customerData?.data?.data.forEach(item => {
                cellsWs2.push([
                    item.name,
                    item.email,
                    item.phone_number,
                    typeMap[item.customer_type] || item.customer_type,
                    genderMap[item.gender] || 'Tidak disebutkan',
                    item.age,
                ])
            })
        }
        XLSX.utils.sheet_add_aoa(ws2, cellsWs2);
        XLSX.utils.book_append_sheet(wb, ws2, 'Customer');

        XLSX.writeFile(wb, filename);
    }, [data, cityStats, endDate, startDate, expedition, search, orderby, sorting])

    useEffect(() => {
        setLoading(true)
        setData(undefined)
        getCustomerInfoStats(
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).add(1, 'd').format('YYYY-MM-DD')
        )
            .then(response => {
                setData(response.data)
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            });
    }, [startDate, endDate])

    useEffect(() => {
        setCityStatsLoading(true);
        setCityStats(undefined);
        getCityStats({
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).add(1, 'd').format('YYYY-MM-DD'),
            page,
            sorting,
            expedition,
            search,
            orderby,
            per_page,
        })
            .then((response) => {
                setCityStats(response.data);
                setCityStatsLoading(false);
            })
            .catch(() => {
                setCityStatsLoading(false)
            });
    }, [page, sorting, expedition, search, orderby, startDate, endDate, per_page])

    const genderFemale = data?.gender?.female ?? 0;
    const genderMale = data?.gender?.male ?? 0;
    const genderUnknown = data?.gender?.unknown ?? 0;

    const typeBaru = data?.customer_type?.baru ?? 0;
    const typeRegular = data?.customer_type?.regular ?? 0;
    const typeSetia = data?.customer_type?.setia ?? 0;

    const largestAgeGroup = (data?.age?.reduce(
        (max, item) => item.value > max ? item.value : max, 0
    ) ?? 0)

    return ( 
        <section id="dashboard-wrapper">
            <div className="container-wrapper">
                <Sidebar />
                <section id="main-dashboard">
                    <Header />
                    <div className="content-wrapper">
                        <div className="main-dash">
                            <div className="ci-chart-wrapper">
                                <div className="ci-chart-box">
                                    <div className="white-box narrow">
                                        <h3 className="heading-box text-center">Jenis Kelamin Pembeli</h3>
                                        <div className="sub-heading-box text-center">{loading ? '...' : genderFemale + genderMale + genderUnknown} Pembeli</div>
                                        <div className="box-chart" style={{display: 'flex', justifyContent: 'center'}}>
                                            <Chart
                                                options={{
                                                    chart: {
                                                        type: 'pie',
                                                    },
                                                    colors: ['#FFD9EE', '#A0C3F0', '#E3F9FF'],
                                                    legend: {
                                                        show: false,
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            dataLabels: {
                                                                offset: -30
                                                            }
                                                        }
                                                    },
                                                    stroke: {
                                                        width: 0,
                                                    },
                                                    dataLabels: {
                                                        formatter: (val) => `${Math.round(val)}%`,
                                                        style: {
                                                            fontFamily: "'Nunito Sans', sans-serif",
                                                            fontSize: '14px',
                                                            colors: ['#AA1166', '#2C60A2', '#2094B4'],
                                                        },
                                                        dropShadow: {
                                                            enabled: false,
                                                        }
                                                    },
                                                    tooltip: {
                                                        enabled: false,
                                                    }
                                                }}
                                                type="pie"
                                                height={250}
                                                width={220}
                                                series={[
                                                    genderFemale,
                                                    genderMale,
                                                    genderUnknown,
                                                ]
                                                }
                                            />
                                        </div>
                                        <div className="legend-wrapper">
                                            <div className="legend pink">Perempuan <span className="legend-data">({loading ? '...' : genderFemale} user)</span></div>
                                            <div className="legend blue">Laki-laki <span className="legend-data">({loading ? '...' : genderMale} user)</span></div>
                                            <div className="legend cyan">Tidak Disebutkan <span className="legend-data">({loading ? '...' : genderUnknown} user)</span></div>
                                        </div>
                                        <div className="tool-tip-box">
                                            <span className="tool-tip">
                                                <a href="#" className="tool-tip-icon">?</a>
                                                <div className="tool-tip-content right">
                                                    Jenis kelamin pembeli di ambil dari data profile pembeli
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="ci-chart-box">
                                    <div className="white-box narrow">
                                    <h3 className="heading-box text-center">Tipe Pembeli</h3>
                                        <div className="sub-heading-box text-center">{loading ? '...' : typeBaru + typeRegular + typeSetia} Pembeli</div>
                                        <div className="box-chart" style={{display: 'flex', justifyContent: 'center'}}>
                                            <Chart
                                                options={{
                                                    chart: {
                                                        type: 'pie',
                                                    },
                                                    colors: ['#D5EDBF', '#FFDEDE', '#FFF1CC'],
                                                    legend: {
                                                        show: false,
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            dataLabels: {
                                                                offset: -30
                                                            }
                                                        }
                                                    },
                                                    stroke: {
                                                        width: 0,
                                                    },
                                                    dataLabels: {
                                                        formatter: (val) => `${Math.round(val)}%`,
                                                        style: {
                                                            fontFamily: "'Nunito Sans', sans-serif",
                                                            fontSize: '14px',
                                                            colors: ['#5C9031', '#B95656', '#D8AE40'],
                                                        },
                                                        dropShadow: {
                                                            enabled: false,
                                                        }
                                                    },
                                                    tooltip: {
                                                        enabled: false,
                                                    }
                                                }}
                                                type="pie"
                                                height={250}
                                                width={220}
                                                series={[
                                                    typeBaru,
                                                    typeRegular,
                                                    typeSetia,
                                                ]
                                                }
                                            />
                                    </div>
                                    <div className="legend-wrapper">
                                        <div className="legend green">Pembeli Baru <span className="legend-data">({loading ? '...' : typeBaru} user)</span></div>
                                        <div className="legend red">Pembeli Reguler <span className="legend-data">({loading ? '...' : typeRegular} user)</span></div>
                                        <div className="legend orange">Pembeli Setia <span className="legend-data">({loading ? '...' : typeSetia} user)</span></div>
                                    </div>
                                    <div className="tool-tip-box">
                                        <span className="tool-tip">
                                        <a href="#" className="tool-tip-icon">?</a>
                                            <div className="tool-tip-content right" style={{whiteSpace: 'pre-wrap'}}>
                                                {`Tipe pembeli di ambil dari history pembelian user dengan detail :
- Pembeli baru : melakukan pembelian pertama kali
- Pembeli reguler : melakukan pembelian  2-3 kali
- Pembeli setia : melakukan pembelian lebih dari 3 kali`}
                                        </div>
                                        </span>
                                    </div>
                                    </div>
                                </div>
                                <div className="ci-chart-box">
                                    <div className="white-box narrow">
                                        <h3 className="heading-box text-center">Usia Pembeli</h3>
                                        <div className="sub-heading-box text-center">{loading? '...' : data?.age?.reduce((total, cur) => total + cur.value, 0) ?? 0} Pembeli</div>
                                        <div className="box-chart">
                                            <Chart
                                                type="bar"
                                                options={{
                                                    labels: data?.age?.map(item => item.label.split(' ')) ?? [],
                                                    yaxis: {
                                                        min: 0,
                                                        max: Math.ceil(largestAgeGroup / 5) * 5,
                                                        tickAmount: 5,
                                                        labels: {
                                                            offsetX: -12,
                                                            minWidth: 42,
                                                            style: {
                                                                colors: '#626262',
                                                                fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                fontSize: 12,
                                                            }
                                                        }
                                                    },
                                                    xaxis: {
                                                        labels: {
                                                            style: {
                                                                colors: '#626262',
                                                                fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                fontSize: 12,
                                                            }
                                                        }
                                                    },
                                                    plotOptions: {
                                                        bar: {
                                                            columnWidth: '40%',
                                                            distributed: true
                                                        }
                                                    },
                                                    stroke: {
                                                        width: 1,
                                                        colors: data?.age?.map(item => item.value === largestAgeGroup ? '#CE8383' : '#4A8EC7'),
                                                    },
                                                    tooltip: {
                                                        x: {show: false}
                                                    },
                                                    colors: data?.age?.map(item => item.value === largestAgeGroup ? '#FFDEDE' : '#DEF0FF'),
                                                    dataLabels: {
                                                        enabled: false
                                                    },
                                                    legend: {
                                                        show: false,
                                                    },
                                                }}
                                                series={[{ name: 'Jumlah', data: data?.age?.map(item => item.value) ?? [] }]}
                                            />
                                        </div>
                                        <div className="tool-tip-box">
                                            <span className="tool-tip">
                                                <a href="#" className="tool-tip-icon">?</a>
                                                <div className="tool-tip-content right">
                                                    Usia pembeli di ambil dari data profile pembeli dan dikelompokkan dengan rentang umur sesuai pada grafik
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="toolbar">
                                <div className="toolbar-left">
                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            setCityStatsParam(ps => ({...ps, page: 1, search: keyword}));
                                        }}
                                    >
                                        <div className="form-group search-input">
                                            <label htmlFor="inputSearch" className="sr-only">Search</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="inputSearch"
                                                placeholder="Cari Kota / Kab"
                                                onChange={
                                                    ({target: {value}}) => setKeyword(value)
                                                }
                                            />
                                            <button className="btn btn-search">
                                                <img src="/img/ico-search.svg" alt="search" />
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div className="toolbar-right">
                                    <div className="toolbar-inner-left">
                                        <div className="toolicon-wrapper">
                                            <a href="#show" className="toolicon sort" />
                                            <div className="tool-inner">
                                                <div className="tool-wrapper">
                                                    <div className="tool-heading">Urutkan</div>
                                                    <ul className="tool-select">
                                                        {cityStatsSortOptions.map(item => (
                                                            <li
                                                                className={`tool-select-item ${sorting === item.sorting && orderby === item.orderby ? 'active' : ''}`}
                                                                onClick={() => {
                                                                    setCityStatsParam(ps => ({
                                                                        ...ps,
                                                                        page: 1,
                                                                        sorting: item.sorting,
                                                                        orderby: item.orderby,
                                                                    }));
                                                                }}
                                                            >
                                                                {item.label}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="toolicon-wrapper">
                                            <a href="#show" className="toolicon filter" />
                                            <div className="tool-inner">
                                                <div className="tool-wrapper">
                                                    <div className="tool-heading">Filter Expedisi</div>
                                                    <ul className="tool-select">
                                                        {cityStatsExpeditionOptions.map(item => (
                                                            <li
                                                                className={`tool-select-item ${item.value === expedition ? 'active': ''}`}
                                                                onClick={() => {
                                                                    if (expedition === item.value) {
                                                                        setCityStatsParam(ps => ({
                                                                            ...ps,
                                                                            page: 1,
                                                                            expedition: undefined,
                                                                        }));
                                                                        return;
                                                                    }
                                                                    setCityStatsParam(ps => ({
                                                                        ...ps,
                                                                        page: 1,
                                                                        expedition: item.value,
                                                                    }));
                                                                }}
                                                            >
                                                                <img src={item.image} alt="logo" /> {item.label}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="page-item mr-3">
                                            <form className="page-input-product" onSubmit={e => e.preventDefault()}>
                                                <div className="form-group mb-0">
                                                    <label htmlFor="page-count" className="sr-only">Page</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="page-count"
                                                        defaultValue={per_page}
                                                        onChange={({ target: {value} }) => debouncedChangePerPage(value)}
                                                    />
                                                </div>
                                            </form>
                                            <div className="text-page">/ Page</div>
                                        </div>
                                        <div className="toolicon-wrapper">
                                            <div className="tool-clear">
                                                <button className="btn btn-secondary" onClick={() => setCityStatsParam(defaultCityStatsParam)}>
                                                    Clear
                                                </button>
                                            </div>
                                        </div>
                                        <div className="tool-daterange">
                                            <DateRangePicker
                                                ref={dateRangePickerRef}
                                                initialSettings={{
                                                    maxDate: new Date(),
                                                    opens: 'center',
                                                    locale: {
                                                        format: 'YYYY-MM-DD',
                                                        cancelLabel: 'Clear',
                                                    },
                                                    startDate,
                                                    endDate,
                                                }}
                                                onCallback={(start, end) => {
                                                    history.replace({
                                                        search: `?sd=${start.format('YYYY-MM-DD')}&ed=${end.format('YYYY-MM-DD')}`
                                                    });
                                                }}
                                                onCancel={() => {
                                                    history.replace({
                                                        search: undefined
                                                    });
                                                    if (dateRangePickerRef.current) {
                                                        const today = new Date();
                                                        dateRangePickerRef.current.setStartDate(today);
                                                        dateRangePickerRef.current.setEndDate(today);
                                                    }
                                                }}
                                                startDate={startDate}
                                                endDate={endDate}
                                            >
                                                <button className="daterange" style={{maxWidth: 'unset'}}>
                                                    {`${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}`}
                                                </button>
                                            </DateRangePicker>
                                        </div>
                                        <div className="tool-export">
                                            <button
                                                className="btn btn-secondary"
                                                onClick={() => {
                                                    exportData()
                                                }}
                                            >
                                            Export
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>{/* end of toolbar*/}
                            <List
                                loading={cityStatsLoading}
                                data={cityStats}
                                onChangePage={
                                    (newPage) => setCityStatsParam(ps => ({...ps, page: newPage}))
                                }
                                startDate={startDate}
                                endDate={endDate}
                            />
                        </div>{/* end of main-dash*/}
                    </div>
                </section>
            </div>
        </section>
    );
}


export default CustomerInfo;
