import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { authHeader } from '../../_helpers';
import { API_STAGING } from '../../_settings'
import { Switch, Route, Redirect, Link, useLocation } from 'react-router-dom';

function Header() {
    const [appState, setAppState]   = useState({
        loading: false,
        data: null,
        total: null,
        total_not_read: null,
        current_page: null,
        disabled: false,
        showLoading: false,
        message: null
    });

    useEffect(() => {
        const fetchData = async () => {
          const respGlobal = await axios.post(
            `${API_STAGING}notification`, {
                //...data
            },  {
                headers: authHeader()
            })
            setAppState({ loading: false, data: respGlobal.data.data.data, total_not_read: respGlobal.data.data.total_not_read });
        };
        fetchData();
    }, []);
    const handleCheckChieldElement = (e) => {
        setAppState({loading:true, data:appState.data, total_not_read:appState.total_not_read });
        axios.post(`${API_STAGING}notification/read/${e.id}`, {
            //...data
        },  {
            headers: authHeader()
        })
        .then((repos) => {
            setAppState({loading:true});
            const data = repos.data.message;
            if(repos.data.status == true) {
                window.location.assign(`/order/detail/${e.order_id}`)
            } else {
                swal({
                    title: "Error!",
                    text: data,
                    icon: "error",
                    button: true
                })
                window.location.reload()
            }
          });
    
    }

    const token = localStorage.getItem('token')
    const name = localStorage.getItem('name')
    const location = useLocation()

    if (!token) {
        return <Redirect to="/login" />
    }

    const submitLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        localStorage.removeItem('gender');
        localStorage.removeItem('datebirth');
        localStorage.removeItem('user_register');
        localStorage.removeItem('max_days');
        localStorage.removeItem('max_hours');
        localStorage.removeItem('max_minutes');
        window.location.reload(true);
    }

    return ( 
        <div className="top-nav">
            <div className="main-title">
                <Switch>
                    <Route exact path='/'>
                        <Link to="/order">Order List</Link>
                    </Route>
                    <Route exact path='/order'>
                        <Link to="/order">Order List</Link>
                        {location.state?.city_id && (
                            <span>{location.state?.city}</span>
                        )}
                    </Route>
                    <Route path='/order/detail'>
                        <div className="logo-standalone">
                            <Link to="/order"><img src="/img/avo-logo.png" alt="avo-logo" className="img-fluid" /></Link>
                        </div>
                        <Link to="/order">Order</Link> <span>Detail Pesanan</span>
                    </Route>
                    <Route path='/request-awb'>
                        <Link to="/request-awb">Request AWB</Link>
                    </Route>
                    <Route path='/import-resi'>
                        <Link to="/import-resi">Impor Resi</Link>
                    </Route>
                    <Route path='/surat-jalan'>
                        <Link to="/surat-jalan">Surat Jalan</Link>
                    </Route>
                    <Route exact path='/service-performance'>
                        <Link
                            to={{
                                pathname: '/service-performance',
                                search: location.search,
                            }}
                        >
                            Service Performance
                        </Link>
                    </Route>
                    <Route path='/service-performance/setting-target'>
                        <div className="main-title">
                        <Link
                            to={{
                                pathname: '/service-performance',
                                search: location.search,
                            }}
                        >
                            Service Performance
                        </Link> <span>Setting Target</span></div>
                    </Route>
                    <Route path='/service-performance/tidak-terselesaikan'>
                        <div className="main-title">
                        <Link
                            to={{
                                pathname: '/service-performance',
                                search: location.search,
                            }}
                        >
                            Service Performance
                        </Link> <span>Tingkat Pesanan Tidak Terselesaikan</span></div>
                    </Route>
                    <Route path='/service-performance/keterlambatan'>
                        <div className="main-title">
                        <Link
                            to={{
                                pathname: '/service-performance',
                                search: location.search,
                            }}
                        >
                            Service Performance
                        </Link> <span>Tingkat Keterlambatan Pengiriman Pesanan</span></div>
                    </Route>
                    <Route path='/service-performance/pengemasan'>
                        <div className="main-title">
                        <Link
                            to={{
                                pathname: '/service-performance',
                                search: location.search,
                            }}
                        >
                            Service Performance
                        </Link> <span>Masa Pengemasan</span></div>
                    </Route>
                    <Route path='/product-performance'>
                        <Link to="/product-performance">Product Performance</Link>
                    </Route>
                    <Route path='/customer-info'>
                        <Link to="/customer-info">Customer Info</Link>
                    </Route>
                    <Route path='/settings'>
                        <div className="logo-standalone">
                            <Link to="/"><img src="/img/avo-logo.png" alt="avo-logo" className="img-fluid" /></Link>
                        </div>
                        <Link to="/"> Beranda</Link> <span>Pengaturan</span>
                    </Route>
                    <Route path='/notification'>
                        <div className="logo-standalone">
                            <Link to="/"><img src="/img/avo-logo.png" alt="avo-logo" className="img-fluid" /></Link>
                        </div>
                        <Link to="/"> Beranda</Link> <span>Notification</span>
                    </Route>
                    <Route path='/profile-info'>
                        <div className="logo-standalone">
                            <Link to="/"><img src="/img/avo-logo.png" alt="avo-logo" className="img-fluid" /></Link>
                        </div>
                        <Link to="/"> Beranda</Link> <span>Profile Info</span>
                    </Route>
                </Switch>
            </div>
            <div className="top-nav-left">
            {(() => {
                if (appState.data){ 
                    return <div className="panel-notification">
                    <button id="dropdown" className="panel-notification-icon check-btn">
                        <input id="myid" type="checkbox"/>
                        <label for="myid">
                            <img src="/img/ico-notification.svg" alt="notification" />
                        </label>
                        <span className="notif-count">{appState.total_not_read}</span>
                        <div className="panel-notification-dropdown">
                            <div className="panel-notif-title">Notifikasi Dashboard</div>
                            {(() => {
                            if (appState.loading == false){
                            return <div>
                                <div className="panel-notif-wrap">
                                    {appState.data.map((repo,index) => {
                                        return (
                                            <div>
                                                {(() => {
                                                if (repo.is_read == false){ 
                                                return  <button onClick={()=>handleCheckChieldElement({id:repo.id, order_id:repo.order_id})} className="panel-notif-item new" key={index}>
                                                        <div className="panel-notif-time">{repo.date}</div>
                                                        <div className="panel-notif-body">{repo.body}</div>
                                                    </button>
                                                } else {
                                                return   <Link to={`/order/detail/${repo.order_id}`} className="panel-notif-item" key={index}>
                                                        <div className="panel-notif-time">{repo.date}</div>
                                                        <div className="panel-notif-body">{repo.body}</div>
                                                    </Link>
                                                }
                                                })()}
                                            </div>
                                        );
                                    })}
                                </div>
                                <Link to="/notification" className="all-notif-dropdown">Lihat Semua</Link>
                            </div>
                        } else {
                            return <div>
                                <div className="credential-50">
                                    <div className="credential-box-noborder-nowidth">
                                    <div className="load-2">
                                        <p>Loading... Please wait</p>
                                        <div class="line"></div>
                                        <div class="line"></div>
                                        <div class="line"></div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            }
                        })()}
                        </div>
                    </button>
                </div> 
                } else { 
                    return <div className="panel-notification">
                    <button id="dropdown" className="panel-notification-icon">
                        <img src="/img/ico-notification.svg" alt="notification" />
                    </button>
                    </div>
                }
            })()}
                <div class="panel-admin-wrap">
                    <Link className="panel-admin">
                        <img src="/img/ico-user.svg" alt="notification" />
                        <div className="admin-info">
                            <div className="admin-name">{name}</div>
                            <div className="admin-info">Admin</div>
                        </div>
                    </Link>
                    <div className="panel-admin-dropdown">
                        <Link to="/profile-info" className="panel-admin-link">Profile Info</Link>
                        <Link to="/settings" className="panel-admin-link">Settings</Link>
                        <button onClick={submitLogout} className="panel-admin-btn">Logout</button>
                    </div>
                </div>
            </div>
        </div>
    );
    
}

export default Header;
