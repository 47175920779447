import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { authHeader } from '../../../_helpers';
import { API_STAGING } from '../../../_settings'
import Header from '../../../components/Header';
import List from './List';
import Loading from '../../../components/Loading';

function OrderDetail({ match }) {
    const ListLoading              = Loading(List);
    const [appState, setAppState]  = useState({
        loading: false,
        data: null
    });
    const { params: { id }, } = match;
    useEffect(() => {
        setAppState({ loading: true });
        axios.post(API_STAGING + 'order/listing/' + `${id}`, {
            //...data
          },  {
            headers: authHeader()
        })
        .then((repos) => {
            const data = repos.data;
            setAppState({ loading: false, repos: data.data, product: data.data.product.data, history_order: data.data.history_order.data, tracking: data.data.tracking.data });
          });
      }, [setAppState])
    return ( 
        <section id="dashboard-wrapper">
            <Header />
            <ListLoading isLoading={appState.loading} data={appState.repos} product={appState.product} history_order={appState.history_order} tracking={appState.tracking} />
        </section>
    );
}

export default OrderDetail;