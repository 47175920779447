import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Chart from 'react-apexcharts';
import moment from 'moment';
import swal from 'sweetalert';
import axios from 'axios';
import XLSX from 'xlsx';
import { authHeader } from '../../../_helpers';
import { API_STAGING } from '../../../_settings'
import Header from '../../../components/Header';
import Sidebar from '../../../components/Sidebar';
import List from './List';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {useDebouncedCallback} from 'use-debounce/lib';

const getServicePerformanceNotFuliflled = async (startDate, endDate) => {
    const response = await axios.post(
        `${API_STAGING}service-performance/not-fullfilled`,
        null,
        {
            params: {
                start_date: startDate,
                end_date: endDate,
            },
            headers: authHeader(),
        },
    );

    return response.data;
}

/**
 * Get List of Orders
 *
 * @param {Object} params
 * @param {string} params.start_date
 * @param {string} params.end_date
 * @param {number} params.page
 * @param {string} params.sorting - ASC | DESC
 * @param {string} params.expedition - jne | jnt | pos | sicepat | rpx
 * @param {string} params.search
 * @param {string} params.orderby - customer-name | processing-time | total-price
 * @param {number} params.per_page
 */
const getServicePerformanceNotFuliflledOrders = async (params) => {
    const response = await axios.post(
        `${API_STAGING}service-performance/not-fullfilled-order`,
        null,
        {
            params,
            headers: authHeader(),
        },
    );

    return response.data;
}

const orderSortOptions = [
    {
        label: 'Nama User A-Z',
        sorting: 'ASC',
        orderby: 'customer-name'
    },
    {
        label: 'Nama User Z-A',
        sorting: 'DESC',
        orderby: 'customer-name'
    },
    {
        label: 'Waktu Proses Terdekat',
        sorting: 'ASC',
        orderby: 'processing-time'
    },
    {
        label: 'Waktu Proses Terlama',
        sorting: 'DESC',
        orderby: 'processing-time'
    },
    {
        label: 'Jumlah Biaya Pesanan Terbesar',
        sorting: 'DESC',
        orderby: 'total-price'
    },
    {
        label: 'Jumlah Biaya Pesanan Terkecil',
        sorting: 'ASC',
        orderby: 'total-price'
    },
];

const orderExpeditionOptions = [
    {
        label: 'J&T Express',
        value: 'jnt',
        image: '/img/ico-ex-jnt.jpg',
    },
    {
        label: 'JNE Express',
        value: 'jne',
        image: '/img/ico-ex-jne.jpg',
    },
    {
        label: 'Sicepat Express',
        value: 'sicepat',
        image: '/img/ico-ex-sicepat.jpg',
    },
    {
        label: 'RPX One Stop Logistics',
        value: 'rpx',
        image: '/img/rpx.png',
    },
    {
        label: 'POS Indonesia',
        value: 'pos',
        image: '/img/pos.png',
    }
];

const defaultOrderParam = {
    page: 1,
    orderby: undefined,
    sorting: 'DESC',
    expedition: undefined,
    keyword: undefined,
}

function TidakTerselesaikan() {
    const location = useLocation();
    const history = useHistory();

    const dateRangePickerRef = useRef(null);

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [orderParam, setOrderParam] = useState(defaultOrderParam);
    const debouncedChangeKeyword = useDebouncedCallback((keyword) => {
        setOrderParam(ps => ({...ps, page: 1, keyword }))
    }, 500)
    const [orders, setOrders] = useState();
    const [ordersLoading, setOrdersLoading] = useState(false);

    const {startDate, endDate} = useMemo(() => {
        const query = new URLSearchParams(location.search);
        const sd = query.get('sd');
        const ed = query.get('ed');
        if (sd && ed) {
            return {
                startDate: new Date(sd),
                endDate: new Date(ed),
            }
        }
        return {
            startDate: new Date(),
            endDate: new Date(),
        }
    }, [location.search])

    const {page, orderby, sorting, expedition, keyword} = orderParam;

    const exportData = useCallback(async () => {
        if (!Array.isArray(data?.trends) || !orders) return;
        const exportOrders = await getServicePerformanceNotFuliflledOrders({
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            page: 1,
            sorting,
            expedition,
            search: keyword,
            orderby,
            per_page: orders.total,
        });
        const filename = `not-fulfilled-orders-${require('moment')?.().format('YYYY-MM-DD-hh-mm-ss')}.xlsx`;
        const cells = [
            ['Periode', 'Total Order', 'Pesanan dibatalkan', '% Pesanan dibatalkan'],
            ...data.trends.map(item => [
                item.start_date === item.end_date ? item.start_date : `${item.start_date}`,
                item.total_order,
                item.not_fulfilled_order,
                `${item.not_fulfilled_percentage}%`,
            ]),
            [
                'Total',
                { t: 'n', f: `SUM(B2:B${data.trends.length + 1})` },
                { t: 'n', f: `SUM(C2:C${data.trends.length + 1})` },
                { t: 'n', f: `(C${data.trends.length + 2}/B${data.trends.length + 2})`, z: '0.00%' },
            ],
            null,
            null,
            [
                'ID Order',
                'Tanggal Order',
                'Nama Customer',
                'Email (Pembeli)',
                'No. Telepon',
                'Alasan pembatalan',
                'Grand Total',
            ],
            ...exportOrders.data.orders.map(item => [
                item.invoice_number,
                item.order_date,
                item.customer_name,
                item.customer_email, // new
                item.customer_phone, // new
                item.cancel_reason,
                item.total_price_text
            ])
        ];
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(cells)
        XLSX.utils.book_append_sheet(wb, ws, 'Export Data');

        XLSX.writeFile(wb, filename);

    }, [data, orders, endDate, startDate, expedition, keyword, orderby, sorting])

    useEffect(() => {
        setLoading(true);
        setData(undefined);
        getServicePerformanceNotFuliflled(
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
        )
            .then((response) => {
                setData(response.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, [startDate, endDate]);

    useEffect(() => {
        setOrdersLoading(true);
        setOrders(undefined);
        getServicePerformanceNotFuliflledOrders({
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            page,
            sorting,
            expedition,
            search: keyword,
            orderby,
            per_page: 3,
        })
            .then((response) => {
                setOrders(response.data);
                setOrdersLoading(false);
            })
            .catch(() => {
                setOrdersLoading(false);
            });
    }, [page, sorting, expedition, keyword, orderby, startDate, endDate])

    let periodLabel = 'periode';
    let previousPeriodLabel = 'Periode sebelumnya';
    if (moment(startDate).format('YYYY-MM-DD') === moment(endDate).format('YYYY-MM-DD')) {
        periodLabel = 'hari';
        previousPeriodLabel = 'Kemarin';
    }

    return ( 
        <section id="dashboard-wrapper">
            <div className="container-wrapper">
                <Sidebar />
                <section id="main-dashboard">
                    <Header />
                    <div className="content-wrapper">
                        <div className="main-dash">
                            <div className="position-relative">
                                <div className="tool-daterange-dark">
                                    <div className="tool-daterange">
                                        <DateRangePicker
                                            ref={dateRangePickerRef}
                                            initialSettings={{
                                                maxDate: new Date(),
                                                opens: 'center',
                                                locale: {
                                                    format: 'YYYY-MM-DD',
                                                    cancelLabel: 'Clear',
                                                },
                                                startDate,
                                                endDate,
                                            }}
                                            onCallback={(start, end) => {
                                                history.replace({
                                                    search: `?sd=${start.format('YYYY-MM-DD')}&ed=${end.format('YYYY-MM-DD')}`
                                                });
                                            }}
                                            onCancel={() => {
                                                history.replace({
                                                    search: undefined
                                                });
                                                if (dateRangePickerRef.current) {
                                                    const today = new Date();
                                                    dateRangePickerRef.current.setStartDate(today);
                                                    dateRangePickerRef.current.setEndDate(today);
                                                }
                                            }}
                                            startDate={startDate}
                                            endDate={endDate}
                                        >
                                            <button className="daterange" style={{maxWidth: 'unset'}}>
                                                {`${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}`}
                                            </button>
                                        </DateRangePicker>
                                    </div>
                                </div>
                                <div className="tool-export floating">
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => {
                                            exportData()
                                        }}
                                    >
                                        Export
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="white-box">
                                        <h3 className="heading-box">Persentase pengiriman order<br />yang tidak dilakukan</h3>
                                        <div className="d-flex align-items-center my-3">
                                            <div className="heading-percent mr-5">
                                                {data?.not_fulfilled_percentage ? `${data.not_fulfilled_percentage}%` : loading ? '...' : '0%'}
                                            </div>
                                            <Link
                                                to={{
                                                    pathname: '/service-performance/setting-target',
                                                    search: location.search,
                                                }}
                                                className="label-green"
                                            >
                                                Target &lt; {data?.target || '...'}%
                                            </Link>
                                        </div>
                                        <div className="label-box">
                                            {data?.previous_not_fulfilled_percentage ? `${previousPeriodLabel} ${data.previous_not_fulfilled_percentage}%` : loading ? `${previousPeriodLabel} ...` : `${previousPeriodLabel} 0%`}
                                        </div>
                                        <div className="tool-tip-box">
                                            <span className="tool-tip">
                                                <a href="#" className="tool-tip-icon">?</a>
                                                <div className="tool-tip-content right">
                                                    Persentase jumlah pengiriman order yang tidak dilakukan diambil dari jumlah order yang tidak di proses pada hari tersebut dibagi total order pada hari tersebut di kali 100%
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="white-box">
                                        <h3 className="heading-box">Jumlah poin pinalti<br />yang didapatkan {periodLabel} ini</h3>
                                        <div className="d-flex align-items-center my-3">
                                            <div className="heading-percent mr-5">
                                                {data?.penalty_point ? data.penalty_point : loading ? '...' : '0'}
                                            </div>
                                        </div>
                                        <div className="label-box">
                                            {data?.previous_penalty_point ? `${previousPeriodLabel} ${data.previous_penalty_point}` : loading ? `${previousPeriodLabel} ...` : `${previousPeriodLabel} 0`}
                                        </div>
                                        <div className="tool-tip-box">
                                            <span className="tool-tip">
                                                <a href="#" className="tool-tip-icon">?</a>
                                                <div className="tool-tip-content right">
                                                    Jumlah poin pinalti didapatkan dari pesanan yang tidak di proses. Untuk satu order yang tidak di proses dikenakan 1 poin pinalti
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="white-box d-flex">
                                        <div className="white-box-left">
                                            <span className="tool-tip floating">
                                                <a href="#" className="tool-tip-icon">?</a>
                                                <div className="tool-tip-content right">
                                                    Adalah persentase pembatalan pesanan karena order tidak di proses dan jumlah dana yang dikembalikan
                                                </div>
                                            </span>
                                            <div className="text-center">
                                                <h3 className="heading-box" style={{marginRight: 40}}>Penjualan pesanan tidak diproses</h3>
                                                <div className="sub-heading-box" style={{marginRight: 40}}>{(data?.stats.cancelled_automatically ?? 0) + (data?.stats.cancelled_manually ?? 0) || (loading ? '...' : '0')} Pesanan</div>
                                                <div className="d-flex align-items-center my-3">
                                                    <Chart
                                                        options={{
                                                            chart: {
                                                                type: 'pie',
                                                            },
                                                            colors: ['#A0C4F0', '#FDD5EB'],
                                                            legend: {
                                                                show: false,
                                                            },
                                                            plotOptions: {
                                                                pie: {
                                                                    dataLabels: {
                                                                        offset: -14
                                                                    }
                                                                }
                                                            },
                                                            stroke: {
                                                                width: 0,
                                                            },
                                                            dataLabels: {
                                                                formatter: (val) => `${Math.round(val)}%`,
                                                                style: {
                                                                    fontFamily: "'Nunito Sans', sans-serif",
                                                                    fontSize: '14px',
                                                                    colors: ['#2c61a2', '#a80b62'],
                                                                },
                                                                dropShadow: {
                                                                    enabled: false,
                                                                }
                                                            },
                                                            tooltip: {
                                                                enabled: true,
                                                                y: {
                                                                    title: {
                                                                        formatter: () => 'Jumlah:',
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                        type="pie"
                                                        height={160}
                                                        width={145}
                                                        series={[
                                                                    data?.stats.cancelled_automatically ?? 0,
                                                                    data?.stats.cancelled_manually ?? 0
                                                                ]
                                                        }
                                                    />
                                                    <div className="text-center ml-3">
                                                        <div className="label-desc">Total</div>
                                                        <div className="sub-label-box">{data?.stats.total ? data.stats.total : loading ? '...' : '-'}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="legend-wrapper">
                                                <div className="legend pink">Pembatalan / Pengembalian</div>
                                                <div className="legend blue">Dibatalkan Secara Otomatis</div>
                                            </div>
                                        </div>
                                        <div className="white-box-right">
                                            <span className="tool-tip floating">
                                                <a href="#" className="tool-tip-icon">?</a>
                                                <div className="tool-tip-content right">
                                                    Persentase pesanan yang tidak di proses pada periode tertentu
                                                </div>
                                            </span>
                                            <div className="text-center">
                                                <h3 className="heading-box">Tren tingkat pengiriman pesanan tidak dilakukan</h3>
                                            </div>
                                            <div className="text-right pr-5">
                                                <div className="legend-line">Batas</div>
                                            </div>
                                            <div className="box-statistic">
                                            {loading ? (
                                                <div style={{
                                                    height: 320,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    <div className="load-2">
                                                        <p>Loading... Please wait</p>
                                                        <div class="line"></div>
                                                        <div class="line"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            ) : data && Array.isArray(data.trends) && (
                                                <Chart
                                                    type="line"
                                                    height={320}
                                                    options={{
                                                        labels: data.trends.map(item => {
                                                            if (item.start_date === item.end_date) {
                                                                return item.start_date.slice(5)
                                                            }
                                                            return `${item.start_date.slice(5)} - ${item.end_date.slice(5)}`
                                                        }),
                                                        annotations: {
                                                            yaxis: [
                                                                {
                                                                    y: data.target,
                                                                    borderColor: '#982c2c',
                                                                    strokeDashArray: 10,
                                                                }
                                                            ]
                                                        },
                                                        yaxis: {
                                                            min: 0,
                                                            max: Math.ceil(
                                                                data.trends.reduce(
                                                                    (max, item) => item.not_fulfilled_percentage > max ? item.not_fulfilled_percentage : max, 10
                                                                ) / 5
                                                            ) * 5,
                                                            tickAmount: 5,
                                                            labels: {
                                                                formatter: (val) => `${val}%`,
                                                                offsetX: -12,
                                                                minWidth: 42,
                                                                style: {
                                                                    colors: '#626262',
                                                                    fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                    fontSize: 12,
                                                                }
                                                            }
                                                        },
                                                        xaxis: {
                                                            labels: {
                                                                style: {
                                                                    colors: '#626262',
                                                                    fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                    fontSize: 12,
                                                                }
                                                            }
                                                        },
                                                        stroke: {
                                                            width: 2,
                                                            colors: '#e0ead8',
                                                        },
                                                        markers: {
                                                            size: 5,
                                                        },
                                                        tooltip: {
                                                            x: {show: false}
                                                        },
                                                        colors: ['#61805b'],
                                                    }}
                                                    series={[{ name: 'Tingkat tidak terselesaikan', data: data.trends.map(item => item.not_fulfilled_percentage) }]}
                                                />
                                            )}
                                            </div>
                                        </div>
                                    </div>{/* end of white-box */}
                                </div>
                            </div>
                            <div className="toolbar">
                                <div className="toolbar-left" style={{flex: '0 0 22%'}}>
                                    <div className="form-group search-input">
                                        <label htmlFor="inputSearch" className="sr-only">Search</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="inputSearch"
                                            placeholder="Cari Pesanan"
                                            onChange={
                                                ({target: {value}}) => debouncedChangeKeyword(value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="toolbar-right" style={{flex: '0 0 78%'}}>
                                    <div className="toolbar-inner-left" style={{flex: '0 0 auto'}}>
                                        <div className="toolicon-wrapper">
                                            <a href="#show" className="toolicon sort" />
                                            <div id="test" className="tool-inner">
                                                <div className="tool-wrapper">
                                                    <div className="tool-heading">Urutkan</div>
                                                    <ul className="tool-select">
                                                        {orderSortOptions.map(item => (
                                                            <li
                                                                className={`tool-select-item ${sorting === item.sorting && orderby === item.orderby ? 'active' : ''}`}
                                                                onClick={() => {
                                                                    setOrderParam(ps => ({
                                                                        ...ps,
                                                                        page: 1,
                                                                        sorting: item.sorting,
                                                                        orderby: item.orderby,
                                                                    }));
                                                                }}
                                                            >
                                                                {item.label}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="toolicon-wrapper">
                                            <a href="#show" className="toolicon filter" />
                                            <div className="tool-inner">
                                                <div className="tool-wrapper">
                                                    <div className="tool-heading">Filter Expedisi</div>
                                                    <ul className="tool-select">
                                                        {orderExpeditionOptions.map(item => (
                                                            <li
                                                                className={`tool-select-item ${item.value === expedition ? 'active': ''}`}
                                                                onClick={() => {
                                                                    if (expedition === item.value) {
                                                                        setOrderParam(ps => ({
                                                                            ...ps,
                                                                            page: 1,
                                                                            expedition: undefined,
                                                                        }));
                                                                        return;
                                                                    }
                                                                    setOrderParam(ps => ({
                                                                        ...ps,
                                                                        page: 1,
                                                                        expedition: item.value,
                                                                    }));
                                                                }}
                                                            >
                                                                <img src={item.image} alt="logo" /> {item.label}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="toolicon-wrapper">
                                            <div className="tool-clear">
                                                <button className="btn btn-secondary" onClick={() => setOrderParam(defaultOrderParam)}>
                                                    Clear
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>{/* end of toolbar*/}
                            <List
                                loading={ordersLoading}
                                data={orders}
                                onChangePage={
                                    (newPage) => setOrderParam(ps => ({...ps, page: newPage}))
                                }
                            />
                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
}

export default TidakTerselesaikan;
