import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Chart from 'react-apexcharts';
import swal from 'sweetalert';
import axios from 'axios';
import moment from 'moment';
import XLSX from 'xlsx';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { authHeader } from '../../../_helpers';
import { API_STAGING } from '../../../_settings'
import Header from '../../../components/Header';
import Sidebar from '../../../components/Sidebar';
import List from './List';

const getServicePerformanceLatePercentage = async (startDate, endDate) => {
    const response = await axios.post(
        `${API_STAGING}service-performance/late-percentage`,
        null,
        {
            params: {
                start_date: startDate,
                end_date: endDate,
            },
            headers: authHeader(),
        },
    );

    return response.data;
}

/**
 * Get List of Orders
 *
 * @param {Object} params
 * @param {string} params.start_date
 * @param {string} params.end_date
 * @param {number} params.page
 * @param {string} params.sorting - ASC | DESC
 * @param {string} params.expedition - jne | jnt | pos | sicepat | rpx
 * @param {string} params.search
 * @param {string} params.orderby - customer-name | processing-time | total-price
 * @param {number} params.per_page
 */
const getServicePerformanceLatePercentageOrders = async (params) => {
    const response = await axios.post(
        `${API_STAGING}service-performance/late-percentage-order`,
        null,
        {
            params,
            headers: authHeader(),
        },
    );

    return response.data;
}

const orderSortOptions = [
    {
        label: 'Nama User A-Z',
        sorting: 'ASC',
        orderby: 'customer-name'
    },
    {
        label: 'Nama User Z-A',
        sorting: 'DESC',
        orderby: 'customer-name'
    },
    {
        label: 'Waktu Proses Terdekat',
        sorting: 'ASC',
        orderby: 'processing-time'
    },
    {
        label: 'Waktu Proses Terlama',
        sorting: 'DESC',
        orderby: 'processing-time'
    },
    {
        label: 'Jumlah Biaya Pesanan Terbesar',
        sorting: 'DESC',
        orderby: 'total-price'
    },
    {
        label: 'Jumlah Biaya Pesanan Terkecil',
        sorting: 'ASC',
        orderby: 'total-price'
    },
];

const orderExpeditionOptions = [
    {
        label: 'J&T Express',
        value: 'jnt',
        image: '/img/ico-ex-jnt.jpg',
    },
    {
        label: 'JNE Express',
        value: 'jne',
        image: '/img/ico-ex-jne.jpg',
    },
    {
        label: 'Sicepat Express',
        value: 'sicepat',
        image: '/img/ico-ex-sicepat.jpg',
    },
    {
        label: 'RPX One Stop Logistics',
        value: 'rpx',
        image: '/img/rpx.png',
    },
    {
        label: 'POS Indonesia',
        value: 'pos',
        image: '/img/pos.png',
    }
];

const defaultOrderParam = {
    page: 1,
    orderby: undefined,
    sorting: 'DESC',
    expedition: undefined,
    keyword: undefined,
}

function Keterlambatan() {
    const location = useLocation();
    const history = useHistory();

    const dateRangePickerRef = useRef(null);

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [orderParam, setOrderParam] = useState(defaultOrderParam);
    const debouncedChangeKeyword = useDebouncedCallback((keyword) => {
        setOrderParam(ps => ({...ps, page: 1, keyword }))
    }, 500)
    const [orders, setOrders] = useState();
    const [ordersLoading, setOrdersLoading] = useState(false);

    const {startDate, endDate} = useMemo(() => {
        const query = new URLSearchParams(location.search);
        const sd = query.get('sd');
        const ed = query.get('ed');
        if (sd && ed) {
            return {
                startDate: new Date(sd),
                endDate: new Date(ed),
            }
        }
        return {
            startDate: new Date(),
            endDate: new Date(),
        }
    }, [location.search])

    const {page, orderby, sorting, expedition, keyword} = orderParam;

    const exportData = useCallback(async () => {
        if (!Array.isArray(data?.trends) || !orders) return;
        const exportOrders = await getServicePerformanceLatePercentageOrders({
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            page: 1,
            sorting,
            expedition,
            search: keyword,
            orderby,
            per_page: orders.total,
        });
        const filename = `late-orders-${require('moment')?.().format('YYYY-MM-DD-hh-mm-ss')}.xlsx`;
        const cells = [
            ['Periode', 'Total Order', 'Terlambat dikirim', '% Keterlambatan pengiriman'],
            ...data.trends.map(item => [
                item.start_date === item.end_date ? item.start_date : `${item.start_date}`,
                item.total_order,
                item.late_order,
                `${item.late_percentage}%`,
            ]),
            [
                'Total',
                { t: 'n', f: `SUM(B2:B${data.trends.length + 1})` },
                { t: 'n', f: `SUM(C2:C${data.trends.length + 1})` },
                { t: 'n', f: `SUM(C${data.trends.length + 2}/B${data.trends.length + 2})`, z: '0.00%' },
            ],
            null,
            null,
            [
                'ID Order',
                'Tanggal Order',
                'Nama Customer',
                'Email (Pembeli)',
                'No. Telepon',
                'Alamat Pengiriman',
                'Kode Pos',
                'Kota/Kabupaten',
                'Provinsi',
                'AWB',
                'Payment Method',
                'Shipping Method',
                'Pesanan Harus Dikirimkan Sebelum (Menghindari keterlambatan)',
                'Waktu Pengiriman',
                'Jumlah Hari terlambat',
                'Waktu Pesanan Selesai',
                'Voucher Code',
                'Shipping Price',
                'Cashback',
                'Tax',
                'Grand Total',
                'Product ID',
                'Nama Product',
                'Harga Product',
                'Qty',
                'Subtotal',
                'Product Tax',
                'Total with Tax',
            ],
        ];
        const merges = [];
        exportOrders.data.orders.forEach(item => {
            if (!Array.isArray(item.products)) return;
            item.products.forEach((product, i) => {
                if (i === 0) {
                    if (item.products.length > 1) {
                        Array.from({ length: 21 }).forEach((_, idx) => {
                            merges.push({
                                s: { r: cells.length, c: idx },
                                e: { r: cells.length + item.products.length - 1, c: idx }
                            })
                        })
                    }
                    cells.push([
                        item.invoice_number,
                        item.order_date,
                        item.customer_name,
                        item.customer_email,
                        item.customer_phone,
                        item.shipping_address,
                        item.kode_pos,
                        item.kota_kabupaten,
                        item.provinsi,
                        item.awb,
                        item.payment_method,
                        item.shipping_method,
                        item.shipped_date_deadline,
                        item.shipped_date,
                        item.days_late,
                        item.order_completed_date,
                        item.voucher_code,
                        item.shipping_price,
                        item.cashback,
                        item.tax,
                        item.total_price_text,
                        product.product_id,
                        product.product_name,
                        product.product_price_text,
                        product.qty,
                        product.subtotal_text,
                        product.product_tax_text,
                        product.total_with_tax,
                    ])
                } else {
                    cells.push([
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        product.product_id,
                        product.product_name,
                        product.product_price_text,
                        product.qty,
                        product.subtotal_text,
                        product.product_tax_text,
                        product.total_with_tax,
                    ])
                }
            })
        })
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(cells)
        ws['!merges'] = merges;
        XLSX.utils.book_append_sheet(wb, ws, 'Export Data');

        XLSX.writeFile(wb, filename);

    }, [data, orders, endDate, startDate, expedition, keyword, orderby, sorting])

    useEffect(() => {
        setLoading(true);
        setData(undefined);
        getServicePerformanceLatePercentage(
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
        )
            .then((response) => {
                setData(response.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, [startDate, endDate]);

    useEffect(() => {
        setOrdersLoading(true);
        setOrders(undefined);
        getServicePerformanceLatePercentageOrders({
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            page,
            sorting,
            expedition,
            search: keyword,
            orderby,
            per_page: 20,
        })
            .then((response) => {
                setOrders(response.data);
                setOrdersLoading(false);
            })
            .catch(() => {
                setOrdersLoading(false);
            });
    }, [page, sorting, expedition, keyword, orderby, startDate, endDate])

    let periodLabel = 'periode';
    let previousPeriodLabel = 'Periode sebelumnya';
    if (moment(startDate).format('YYYY-MM-DD') === moment(endDate).format('YYYY-MM-DD')) {
        periodLabel = 'hari';
        previousPeriodLabel = 'Kemarin';
    }

    return ( 
        <section id="dashboard-wrapper">
            <div className="container-wrapper">
                <Sidebar />
                <section id="main-dashboard">
                    <Header />
                    <div className="content-wrapper">
                        <div className="main-dash">
                            <div className="position-relative">
                                <div className="tool-daterange-dark">
                                    <div className="tool-daterange">
                                        <DateRangePicker
                                            ref={dateRangePickerRef}
                                            initialSettings={{
                                                maxDate: new Date(),
                                                opens: 'center',
                                                locale: {
                                                    format: 'YYYY-MM-DD',
                                                    cancelLabel: 'Clear',
                                                },
                                                startDate,
                                                endDate,
                                            }}
                                            onCallback={(start, end) => {
                                                history.replace({
                                                    search: `?sd=${start.format('YYYY-MM-DD')}&ed=${end.format('YYYY-MM-DD')}`
                                                });
                                            }}
                                            onCancel={() => {
                                                history.replace({
                                                    search: undefined
                                                });
                                                if (dateRangePickerRef.current) {
                                                    const today = new Date();
                                                    dateRangePickerRef.current.setStartDate(today);
                                                    dateRangePickerRef.current.setEndDate(today);
                                                }
                                            }}
                                            startDate={startDate}
                                            endDate={endDate}
                                        >
                                            <button className="daterange" style={{maxWidth: 'unset'}}>
                                                {`${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}`}
                                            </button>
                                        </DateRangePicker>
                                    </div>
                                </div>
                                <div className="tool-export floating">
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => {
                                            exportData()
                                        }}
                                    >
                                        Export
                                    </button>
                                </div>
                                <table id="table-to-xls" style={{display: 'none'}}>
                                    <thead>
                                        <tr>
                                            <th>Periode</th>
                                            <th>% Keterlambatan</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(data?.trends) && data.trends.map(item => (
                                        <tr key={item.start_date + item.end_date}>
                                            <td>{item.start_date === item.end_date ? item.start_date : `${item.start_date} - ${item.end_date}`}</td>
                                            <td>{item.late_percentage}%</td>
                                        </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="white-box">
                                        <h3 className="heading-box">Persentase keterlambatan<br />pengiriman order</h3>
                                        <div className="d-flex align-items-center my-3">
                                            <div className="heading-percent mr-5">
                                                {data?.late_percentage ? `${data.late_percentage}%` : loading ? '...' : '0%'}
                                            </div>
                                            <Link
                                                to={{
                                                    pathname: '/service-performance/setting-target',
                                                    search: location.search,
                                                }}
                                                className="label-green"
                                            >
                                                Target &lt; {data?.target || '...'}%
                                            </Link>
                                        </div>
                                        <div className="label-box">
                                            {data?.previous_late_percentage ? `${previousPeriodLabel} ${data.previous_late_percentage}%` : loading ? `${previousPeriodLabel} ...` : `${previousPeriodLabel} 0%`}
                                        </div>
                                        <div className="tool-tip-box">
                                            <span className="tool-tip">
                                                <a href="#" className="tool-tip-icon">?</a>
                                                <div className="tool-tip-content right">
                                                    Jumlah pesanan secara akumulatif yang terlambat untuk diproses dalam satu bulan untuk dilakukan pemenuhan (Fulfillment) hingga batas waktu 2 hari (exclude) hari Sabtu, Minggu, hari libur Nasional dan disajikan dalam bentuk persentase. (dihitung setiap akhir bulan).
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="white-box">
                                        <h3 className="heading-box">Jumlah poin penalti<br />yang didapatkan {periodLabel} ini</h3>
                                        <div className="d-flex align-items-center my-3">
                                            <div className="heading-percent mr-5">
                                                {data?.penalty_point ? data.penalty_point : loading ? '...' : '0'}
                                            </div>
                                        </div>
                                        <div className="label-box">
                                            {data?.previous_penalty_point ? `${previousPeriodLabel} ${data.previous_penalty_point}` : loading ? `${previousPeriodLabel} ...` : `${previousPeriodLabel} 0`}
                                        </div>
                                        <div className="tool-tip-box">
                                            <span className="tool-tip">
                                                <a href="#" className="tool-tip-icon">?</a>
                                                <div className="tool-tip-content right">
                                                    Jumlah pesanan secara akumulatif yang terlambat untuk diproses dalam satu bulan untuk dilakukan pemenuhan (Fulfillment) hingga batas waktu 2 hari (exclude) hari Sabtu, Minggu, hari libur Nasional dan disajikan dalam bentuk persentase. (dihitung setiap akhir bulan).
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="white-box" style={{padding: '16px 6px 16px 6px'}}>
                                        <span className="tool-tip floating" style={{top: '16px'}}>
                                            <a href="#" className="tool-tip-icon">?</a>
                                            <div className="tool-tip-content right">
                                                Jumlah pesanan secara akumulatif yang terlambat untuk diproses dalam satu bulan untuk dilakukan pemenuhan (Fulfillment) hingga batas waktu 2 hari (exclude) hari Sabtu, Minggu, hari libur Nasional dan disajikan dalam bentuk persentase. (dihitung setiap akhir bulan).
                                            </div>
                                        </span>
                                        <div className="text-center">
                                            <h3 className="heading-box">Tren Tingkat Keterlambatan Pengiriman Pesanan</h3>
                                        </div>
                                        <div className="text-right pr-5">
                                            <div className="legend-line">Batas</div>
                                        </div>
                                        <div className="box-statistic">
                                            {loading ? (
                                                <div style={{
                                                    height: 320,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    <div className="load-2">
                                                        <p>Loading... Please wait</p>
                                                        <div class="line"></div>
                                                        <div class="line"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            ) : data && Array.isArray(data.trends) && (
                                                <Chart
                                                    type="line"
                                                    height={320}
                                                    options={{
                                                        labels: data.trends.map(item => {
                                                            if (item.start_date === item.end_date) {
                                                                return item.start_date.slice(5)
                                                            }
                                                            return `${item.start_date.slice(5)} - ${item.end_date.slice(5)}`
                                                        }),
                                                        annotations: {
                                                            yaxis: [
                                                                {
                                                                    y: data.target,
                                                                    borderColor: '#982c2c',
                                                                    strokeDashArray: 10,
                                                                }
                                                            ]
                                                        },
                                                        yaxis: {
                                                            min: 0,
                                                            max: Math.ceil(
                                                                data.trends.reduce(
                                                                    (max, item) => item.late_percentage > max ? item.late_percentage : max, 10
                                                                ) / 5
                                                            ) * 5,
                                                            tickAmount: 5,
                                                            labels: {
                                                                formatter: (val) => `${val}%`,
                                                                offsetX: -12,
                                                                minWidth: 42,
                                                                style: {
                                                                    colors: '#626262',
                                                                    fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                    fontSize: 12,
                                                                }
                                                            }
                                                        },
                                                        xaxis: {
                                                            labels: {
                                                                style: {
                                                                    colors: '#626262',
                                                                    fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                                                    fontSize: 12,
                                                                }
                                                            }
                                                        },
                                                        stroke: {
                                                            width: 2,
                                                            colors: '#e0ead8',
                                                        },
                                                        markers: {
                                                            size: 5,
                                                        },
                                                        tooltip: {
                                                            x: {show: false}
                                                        },
                                                        colors: ['#61805b'],
                                                    }}
                                                    series={[{ name: 'Tingkat keterlambatan', data: data.trends.map(item => item.late_percentage) }]}
                                                />
                                            )}
                                        </div>
                                    </div>{/* end of white-box */}
                                </div>
                            </div>
                            <div className="toolbar">
                                <div className="toolbar-left" style={{flex: '0 0 22%'}}>
                                    <div className="form-group search-input">
                                        <label htmlFor="inputSearch" className="sr-only">Search</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="inputSearch"
                                            placeholder="Cari Pesanan"
                                            onChange={
                                                ({target: {value}}) => debouncedChangeKeyword(value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="toolbar-right" style={{flex: '0 0 78%'}}>
                                    <div className="toolbar-inner-left" style={{flex: '0 0 auto'}}>
                                        <div className="toolicon-wrapper">
                                            <a href="#show" className="toolicon sort" />
                                            <div id="test" className="tool-inner">
                                                <div className="tool-wrapper">
                                                    <div className="tool-heading">Urutkan</div>
                                                    <ul className="tool-select">
                                                        {orderSortOptions.map(item => (
                                                            <li
                                                                className={`tool-select-item ${sorting === item.sorting && orderby === item.orderby ? 'active' : ''}`}
                                                                onClick={() => {
                                                                    setOrderParam(ps => ({
                                                                        ...ps,
                                                                        page: 1,
                                                                        sorting: item.sorting,
                                                                        orderby: item.orderby,
                                                                    }));
                                                                }}
                                                            >
                                                                {item.label}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="toolicon-wrapper">
                                            <a href="#show" className="toolicon filter" />
                                            <div className="tool-inner">
                                                <div className="tool-wrapper">
                                                    <div className="tool-heading">Filter Expedisi</div>
                                                    <ul className="tool-select">
                                                        {orderExpeditionOptions.map(item => (
                                                            <li
                                                                className={`tool-select-item ${item.value === expedition ? 'active': ''}`}
                                                                onClick={() => {
                                                                    if (expedition === item.value) {
                                                                        setOrderParam(ps => ({
                                                                            ...ps,
                                                                            page: 1,
                                                                            expedition: undefined,
                                                                        }));
                                                                        return;
                                                                    }
                                                                    setOrderParam(ps => ({
                                                                        ...ps,
                                                                        page: 1,
                                                                        expedition: item.value,
                                                                    }));
                                                                }}
                                                            >
                                                                <img src={item.image} alt="logo" /> {item.label}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="toolicon-wrapper">
                                            <div className="tool-clear">
                                                <button className="btn btn-secondary" onClick={() => setOrderParam(defaultOrderParam)}>
                                                    Clear
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>{/* end of toolbar*/}
                            <List
                                loading={ordersLoading}
                                data={orders}
                                onChangePage={
                                    (newPage) => setOrderParam(ps => ({...ps, page: newPage}))
                                }
                            />
                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
}

export default Keterlambatan;
