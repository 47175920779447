import React, {useEffect, useState} from 'react'
import { Formik, Form, Field } from 'formik';
import axios from 'axios';
import swal from 'sweetalert';
import Header from '../../../components/Header';
import Sidebar from '../../../components/Sidebar';
import {authHeader} from '../../../_helpers';
import {API_STAGING} from '../../../_settings';

const getSettingTarget = async () => {
    const response = await axios.get(
        `${API_STAGING}setting-target`,
        {
            headers: authHeader(),
        },
    );

    return response.data;
}

/**
 * Update Setting Target
 *
 * @param {Object} params
 * @param {number} params.not_fulfilled_percentage
 * @param {number} params.late_percentage
 * @param {number} params.avg_packing_time
 */
const updateSettingTarget = async (params) => {
    const response = await axios.post(
        `${API_STAGING}setting-target/update`,
        null,
        {
            params,
            headers: authHeader(),
        },
    );

    return response.data;
}

const SettingTarget = () => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        getSettingTarget().then(response => {
            if (response?.data?.target) {
                setData(response.data.target)
            }
            setLoading(false)
        }).catch(() => {
            swal({
                title: "Error!",
                text: "Gagal mengambil data!",
                icon: "error",
                timer: 3000,
                button: false
            })
            setLoading(false)
        })
    }, [])

    return (
        <section id="dashboard-wrapper">
            <div className="container-wrapper">
                <Sidebar />
                <section id="main-dashboard">
                    <Header />
                    <Formik
                        enableReinitialize
                        initialValues={data}
                        onSubmit={(values) => {
                            setUpdateLoading(true)
                            updateSettingTarget(values)
                                .then(() => {
                                    swal({
                                        title: "Done!",
                                        text: 'Berhasil disimpan!',
                                        icon: "success",
                                        timer: 3000,
                                        button: false
                                    })
                                    setUpdateLoading(false);
                                })
                                .catch(() => {
                                    swal({
                                        title: "Error!",
                                        text: 'Gagal menyimpan!',
                                        icon: "error",
                                        timer: 3000,
                                        button: false
                                    })
                                    setUpdateLoading(false)
                                })
                        }}
                    >
                        {() => (
                            <Form>
                                <div className="content-wrapper">
                                    <div className="main-dash">
                                        <div className="form-group">
                                            <label htmlFor="not_fulfilled_percentage">Tingkat pesanan tidak terselesaikan</label>
                                            <div className="row">
                                                <div className="col-2">
                                                    {loading ? (
                                                        <input type="text" readOnly value="Loading..." className="form-control"/>
                                                    ) : (
                                                        <Field type="number" className="form-control" id="not_fulfilled_percentage" name="not_fulfilled_percentage"/>
                                                    )}
                                                </div>
                                                <div className="col-10 d-flex align-items-center">
                                                    %
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="late_percentage">Tingkat keterlambatan pengiriman pesanan</label>
                                            <div className="row">
                                                <div className="col-2">
                                                    {loading ? (
                                                        <input type="text" readOnly value="Loading..." className="form-control"/>
                                                    ) : (
                                                        <Field type="number" className="form-control" id="late_percentage" name="late_percentage"/>
                                                    )}
                                                </div>
                                                <div className="col-10 d-flex align-items-center">
                                                    %
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="avg_packing_time">Masa Pengemasan</label>
                                            <div className="row">
                                                <div className="col-2">
                                                    {loading ? (
                                                        <input type="text" readOnly value="Loading..." className="form-control"/>
                                                    ) : (
                                                        <Field type="number" className="form-control" id="avg_packing_time" name="avg_packing_time"/>
                                                    )}
                                                </div>
                                                <div className="col-10 d-flex align-items-center">
                                                    hari
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <button className="btn btn-primary" disabled={updateLoading || loading}>
                                                {updateLoading ? 'Menyimpan...' : 'Simpan'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </section>
            </div>
        </section>
    );
}

export default SettingTarget;
