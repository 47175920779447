import React from 'react';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import moment from 'moment';

const List = ({ data, onChangePage, loading, startDate, endDate }) => {
    return (
        <div>        
            <div className="result">Total <strong>{loading ? '...' : data?.total ?? '0'} Data</strong> Kota / Kab</div>
            <div className="table-data" style={{minHeight: 320}}>
                <div className="table-head table-ci">
                    <div className="tb-ci"></div>
                    <div className="tb-ci">Kota / Kab</div>
                    <div className="tb-ci">Jumlah Pembeli</div>
                    <div className="tb-ci">Jml. Pesanan</div>
                    <div className="tb-ci">Produk Terjual</div>
                    <div className="tb-ci">Total Pesanan</div>
                    <div className="tb-ci">Nilai Pesanan</div>
                </div>
                {loading ? (
            <div style={{
              height: 320,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <div className="load-2">
                <p>Loading... Please wait</p>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
              </div>
            </div>
                ) : data?.city && data.city.length > 0 ? data.city.map(item => (
                <div className="table-body table-ci">
                    <div className="tb-ci"></div>
                    <div className="tb-ci">{item.city}</div>
                    <div className="tb-ci">{item.total_customer}</div>
                    <div className="tb-ci">{item.total_order}</div>
                    <div className="tb-ci">{item.sold_products}</div>
                    <div className="tb-ci">{item.total_value_text}</div>
                    <div className="tb-ci">{item.value_percentage}%</div>
                    <div className="tb-ci">
                        <div className="order-detail">
                            <Link
                                to={{
                                    pathname: "/order",
                                    state: {
                                        city_id: item.city_id,
                                        city: item.city,
                                        start_date: moment(startDate).format('YYYY-MM-DD'),
                                        end_date: moment(endDate).format('YYYY-MM-DD'),
                                    }
                                }}
                            >
                                Selengkapnya
                            </Link>
                        </div>
                    </div>
                </div>
                )) : (
            <p style={{textAlign: 'center', marginTop: 120, marginBottom: 120}}>
              No data, sorry
            </p>
                )}
                <div className="pagination">
                    <ReactPaginate
                        pageCount={data?.last_page ?? 1}
                        pageRangeDisplayed={4}
                        marginPagesDisplayed={1}
                        forcePage={(data?.current_page ?? 0) - 1}
                        activeLinkClassName='active'
                        containerClassName='paging'
                        previousLabel='&laquo;'
                        nextLabel='&raquo;'
                        onPageChange={(page) => onChangePage(page.selected + 1)}
                    />
                </div>
            </div>
        </div>
    );
};
export default List;
