import React,{ useState } from 'react';
import ReactPaginate from 'react-paginate';
const List = (props) => {
  const { data,total,save,set_page,current_page,to } = props;
  const [appState, setAppState]  = useState({
    dataState:data
    });
    const changePage = (e) => {
        const selectedPage = e.selected + 1;
        set_page(selectedPage)
        console.log(`active page is ${selectedPage}`);
    }

    const handleAllChecked = (event) => {
        appState.dataState.forEach((result,index) =>{
            appState.dataState[index].isChecked= event.target.checked
            console.log(appState.dataState[index].isChecked)
        })
        setAppState({dataState:appState.dataState });
    }

    const handleCheckChieldElement = (e) => {
        console.log(appState.dataState[e].id)

        appState.dataState[e].isChecked = !appState.dataState[e].isChecked
        console.log(appState.dataState)
        setAppState({dataState:appState.dataState });

    }
  if (!data || data.length === 0) return <p style={{textAlign: 'center', marginTop: 120, marginBottom: 120}}>No data order, sorry</p>;
  return (
    <div>        
        <div className="result">Total <strong>{total} Data</strong> Pesanan</div>
        <table id="table-to-xls" className="hide-table">
            <thead>
            <tr>
                <th>ID Order</th>
                <th>Referral</th>
                <th>Tanggal Order</th>
                <th>Nama Customer</th>
                <th>AWB</th>
                <th>Payment Method</th>
                <th>Shipping Method</th>
                <th>Voucher Code</th>
                <th>Shipping Price</th>
                <th>Cashback</th>
                <th>Tax</th>
                <th>Grand Total</th>
                <th>Product ID</th>
                <th>Nama Product</th>
                <th>Harga Product</th>
                <th>Qty</th>
                <th>Subtotal</th>
                <th>Product Tax</th>
                <th>Total with Tax</th>
            </tr>
            </thead>
            <tbody>
            {appState.dataState.map(item => (
                <tr>
                    <td>{item.invoice_number}</td>
                    <td>{item.export_data.referral}</td>
                    <td>{item.created_at}</td>
                    <td>{item.customer_name}</td>
                    <td>{item.export_data.awb}</td>
                    <td>{item.payment_method}</td>
                    <td>{item.export_data.shipping_method}</td>
                    <td>{item.export_data.voucher_code}</td>
                    <td>{item.export_data.shipping_price}</td>
                    <td>{item.export_data.cashback}</td>
                    <td>{item.export_data.tax}</td>
                    <td>{item.total_price_text}</td>
                    {/* {item.product.data.map((repo) => {
                        return (
                            <div>
                                <td>{repo.product_id}</td>
                                <td>{repo.name}</td>
                                <td>{repo.total}</td>
                                <td>{repo.quantity}</td>
                                <td>{repo.subtotal}</td>
                                <td>{repo.total_tax}</td>
                                <td>{repo.total}</td>
                            </div>
                        );
                    })} */}
                </tr>
            ))}
            </tbody>
        </table>
        <div className="table-data">
            <div className="table-head">
                <div className="th-sj-01">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="customCheck1" onClick={handleAllChecked} value="checkedall" />
                        <label className="custom-control-label" htmlFor="customCheck1" />
                    </div>
                </div>
                <div className="th-sj-02">Kode Pesanan</div>
                <div className="th-sj-03">Tanggal Pesanan</div>
                <div className="th-sj-04">Pelanggan</div>
                <div className="th-sj-05">Ekspedisi</div>
                <div className="th-sj-06">Tipe Ekspedisi</div>
                <div className="th-sj-07">Kode Ekspedisi</div>
            </div>
            {appState.dataState.map((repo,index) => {
                return (
                    <div className="table-body d-flex align-items-center">
                        <div className="tb-sj-01">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id={index} checked={repo.isChecked} onChange={(e)=>handleCheckChieldElement(index)} />
                                <label className="custom-control-label" htmlFor={index} />
                            </div>
                        </div>
                        <div className="tb-sj-02">
                            <div className="label-desc green">{repo.invoice_number}</div>
                        </div>
                        <div className="tb-sj-03">
                            <div className="label-desc">{repo.date_order}</div>
                        </div>
                        <div className="tb-sj-04">
                            <span><img src="/img/ico-user.svg" alt="user" className="image-order" /></span>
                            <span>{repo.customer_name}</span>
                        </div>
                        <div className="tb-sj-05">
                        <div className="courier-item align-items-center">
                            <div className="courier-logo sm"><img src={repo.ekspedisi.image} alt={repo.ekspedisi.name} className="img-fluid" /></div>
                            <div className="courier-info mr-2">
                                <div className="courier-city">{repo.ekspedisi.origin} - {repo.ekspedisi.destination}</div>
                            </div>
                        </div>
                        </div>
                        <div className="tb-sj-06">
                            <div className="courier-service">{repo.ekspedisi.name}</div>
                            <div className="courier-price">{repo.ekspedisi.price},-</div>
                        </div>
                        {(() => {
                            if(repo.ekspedisi_code != '') {
                                return <div className="tb-sj-06">{repo.ekspedisi_code}</div>;
                            } else {
                                return <div className="tb-sj-06">Belum tersedia</div>
                            } 
                        })()}
                    </div>/* end of table-body */
                );
            })}
            <div class="pagination">
                <ReactPaginate
                    pageCount={to??1}
                    pageRangeDisplayed={4}
                    marginPagesDisplayed={1}
                    forcePage={current_page - 1}
                    activeLinkClassName='active'
                    containerClassName='paging'
                    previousLabel='&laquo;'
                    nextLabel='&raquo;'
                    onPageChange={changePage}
                />
            </div>
            <div className="text-center">
                <div className="data-result">Total <strong>{total} Data</strong> Pesanan</div>
            </div>
        </div>
    </div>
  );
};
export default List;